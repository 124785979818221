import React from 'react';
import AdtopiaLoadRoot from '../../../../Utility/AdtopiaLoadRoot';

const HomePage = () => {
  const renderSplitName = () => {
  }
  return (
    <>
      <AdtopiaLoadRoot pageType="home" renderSplitName={renderSplitName} splitName="/Split_1" />
    </>
  )
}

export default HomePage;