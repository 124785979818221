import { useContext } from "react";
import { useBudsGetAccessToken } from "../Buds/useBudsGetAccessToken";
import { useBudsCreateCustomer } from "../Buds/useBudsCreateCustomer";
import { useBudsAuthorisationGatewayUrl } from "../Buds/useBudsAuthorisationGatewayUrl";
import { AppDomain } from "../../Constants/EnvConstants";
import { storeApiResponse } from "../Buds/useSaveBudsApiResponse";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import * as EnvConstants from "../../Constants/EnvConstants";

export const useBudsInit = () => {
  const { budsGetAccessTokenResponse } = useBudsGetAccessToken();
  const { budsCreateCustomerResponse } = useBudsCreateCustomer();
  const { budsAuthGatewayUrlResponse } = useBudsAuthorisationGatewayUrl();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const getBudsApi = async (uuid, source, visitorId, is_test, customer_details) => {
    let redirectUrl = AppDomain + "/" + EnvConstants.AppBudsRedirectUrl + "?uuid=" + uuid;
    let customerID = '';
    let gatewayUrl = '';
    let payload = '';
    const getAccessToken = await budsGetAccessTokenResponse(uuid, is_test, source, visitorId);
    if (getAccessToken.data.status == "success") {
      payload = { "milestone_key": "access_token", "source": source, "milestone_stats":null };
      await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
      const customerResponse = await budsCreateCustomerResponse(uuid, is_test, source, visitorId, customer_details);
      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        customerID = customerResponse.data.customer_ID;
        payload = { "milestone_key": "customer_create", "customer_id": customerID, 'source': source };
        await storeApiResponse(visitorParameters.visitor_parameters, "op_customer_store", uuid, payload);
        gatewayUrl = await budsAuthGatewayUrlResponse(uuid, customerID, redirectUrl, is_test, source, visitorId);
        if (gatewayUrl.data.status == "success") {
          payload = { "milestone_key": "lender_redirection", 'source': source, "milestone_stats":null };
          await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
        }
      }
    }
    return gatewayUrl;
  };
  return { getBudsApi }
}

