import React, { useContext, useEffect, useState } from "react";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { useBudsCallbackGateway } from "../../Hooks/Buds/useBudsCallbackGateway";
import { UUID } from "../../Utility/UUID";
import {
  source,
  status,
  task_id,
  queryString,
  is_check,
} from "../../Utility/QueryString";
import { userInfo } from "../../Hooks/userInfo";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useBudsCustomerConsents } from "../../Hooks/Buds/useBudsCustomerConsents";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { storeApiResponse } from "../../Hooks/Buds/useSaveBudsApiResponse";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import Loader from "../Includes/Layouts/Common/Loader";
import { useWriteLogApi } from "../../Hooks/useWriteLogApi";

const BudsRedirectPage = () => {
  const [show, setShow] = useState("d-block");
  const { budsCallbackGatewayResponse } = useBudsCallbackGateway();
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { getUserInfo } = userInfo();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { budsCustomerConsentsResponse } = useBudsCustomerConsents();
  const { writeLogApi } = useWriteLogApi();
  let payload = "";
  let bank_connection_payload = "";
  let is_test = "";
  let customer_id = "";
  let visitor_id = "";
  const history = useHistory();
  const showThankYou = (uuid) => {
    history.push("/thankyou?uuid=" + uuid);  
  };
  useEffect(() => {
    (async () => {
      const response = await getUserInfo(uuid);
      let dataLength = response.data.response.length;
      if (dataLength > 0) {
        const record_status = response.data.response[0].record_status
          ? response.data.response[0].record_status
          : "";
        is_test = record_status == "LIVE" ? false : true;
        customer_id = response.data.response[0].op_customer_id
          ? response.data.response[0].op_customer_id
          : "";
        visitor_id = response.data.response[0].op_visitor_id
          ? response.data.response[0].op_visitor_id
          : "";
      } else {
        const record_status = localStorage.getItem("record_status");
        const visitorId = localStorage.getItem("visitorId");
        const customerId = localStorage.getItem("customer_id");
        if (record_status !== undefined || record_status !== null || record_status != "") {
          is_test = record_status == "LIVE" ? false : true;
        }
        if (visitorId !== undefined || visitorId !== null || visitorId != "") {
          visitor_id = visitorId;
        }
        if (customerId !== undefined || customerId !== null || customerId != "") {
          customer_id = customerId;
        }
      }
      const logDetails = {
        userUuid : uuid,
        urlString : queryString,
        customerId : customer_id,
        isTest : is_test,
        status : status,
        taskId : task_id,
        isCheck : is_check
      };
      const logApi = await writeLogApi('-buds-redirect-page',logDetails);
      if (customer_id != "" && is_check != "success") {
        const callBackResponse = await budsCallbackGatewayResponse(
          uuid,
          source,
          status,
          customer_id,
          task_id,
          is_test,
          visitor_id
        );
        if (callBackResponse.data.status == "success") {
          payload = {
            milestone_key: "callback_url",
            source: source,
            milestone_stats: null,
          };
          await storeApiResponse(
            visitorParameters.visitor_parameters,
            "op_milestone_update",
            uuid,
            payload
          );
          bank_connection_payload = {
            milestone_key: "bank_connection",
            source: source,
            value: status == "success" ? 1 : 2,
            milestone_stats: null,
          };
          await storeApiResponse(
            visitorParameters.visitor_parameters,
            "op_milestone_update",
            uuid,
            bank_connection_payload
          );
        }else{
          if(status != "success"){
            showThankYou(uuid);
          }
        }
        if(status == "success") {
          const customerConsentsResponse = await budsCustomerConsentsResponse(
              uuid,
              is_test,
              visitor_id,
              source
            );
            if (customerConsentsResponse.data.status == "success") {
              window.history.pushState({}, '', '?' + queryString + '&is_check=success');
              payload = {
                milestone_key: "customer_consent",
                source: source,
                customer_consents: customerConsentsResponse.data.consents,
                milestone_stats: "milestone_save",
              };
              await storeApiResponse(
                visitorParameters.visitor_parameters,
                "op_customer_consents",
                uuid,
                payload
              );
              setShow("d-none");
              showThankYou(uuid);
            }else{
              showThankYou(uuid);
            }
        }else{
          showThankYou(uuid);
        }
      } else {
        showThankYou(uuid);
      }
    })();
  }, []);

  return (
    <>
      <GetVisitorsParams />

      <div className="FinanceCheck">
        <div className="openbank  top-main-section pb-5">
          <div
            className="container pb-lg-5 pb-md-5 pb-sm-5 pb-2"
            style={{ position: "relative", zIndex: 5 }}
          >
            <Loader show={show} 
            button="d-none"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BudsRedirectPage;
