import React from 'react'
import PrivacyHeader from '../Common/PrivacyHeader';
import TyFooter from '../Common/TyFooter';
import { Accordion } from "react-bootstrap";


const TermsAndConditions = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <div className="privacy">

                <div className="top-main-section">
                    <PrivacyHeader />
                </div>
                <section className="pt-5 pb-2">
                    <div className="container">
                        <h1> Terms and Conditions </h1>
                    </div>
                </section>
                <section>

                    <div className="container">
                        <div className="inner_text py-2 py-md-5">
                            <p>
                                The terms and conditions of use set out below apply to the website
                                owned, operated and controlled by My Debt Plan Ltd, at
                                <a href="www.mydebt-plan.co.uk">www.mydebt-plan.co.uk</a>
                            </p>
                            <p>
                                The Website is intended for use by residents of the United Kingdom of
                                Great Britain and Northern Ireland only.
                            </p>
                            <p>
                                By using the Website, users are deemed to have full knowledge of the
                                following terms and conditions and to accept them.
                            </p>
                        </div>
                        <Accordion className="mb-3">

                            <Accordion.Item className="accordion-item" eventKey="0">
                                <Accordion.Header className="accordion-header" id="headingOne">
                                    Accuracy
                                </Accordion.Header>
                                <Accordion.Collapse
                                    // className="accordion-button collapsed"
                                    className="accordion-collapse collapse"   
                                    id="collapseOne"
                                    eventKey="0"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <Accordion.Body className="accordion-body">
                                        <p>
                                            The Site has been prepared to provide information. Whilst we
                                            make every effort to ensure the content is accurate, it is not
                                            a comprehensive statement of the law, or of the issues raised
                                            and should not be relied upon for that purpose.
                                        </p>
                                        <p>
                                            You are responsible for the accuracy of any information you
                                            provide to us via this website or in any dealings with us. We
                                            are not liable for any loss incurred by you as a result of
                                            actions we take based on inaccurate information.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <Accordion.Item className="accordion-item" eventKey="1">
                                <Accordion.Header className="accordion-header" id="headingTwo">
                                    Liability
                                </Accordion.Header>
                                <Accordion.Collapse
                                    className="accordion-collapse collapse"
                                    id="collapseTwo"
                                    eventKey="1"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <Accordion.Body className="accordion-body">
                                    <p>
                                        In no event will employees be liable for any decisions made or
                                        action taken through relying on the information in this site.
                                    </p>
                                    </Accordion.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <Accordion.Item className="accordion-item" eventKey="3">
                                <Accordion.Header className="accordion-header" id="headingThree">
                                Authority
                                </Accordion.Header>
                                <Accordion.Collapse
                                    className="accordion-collapse collapse"
                                    id="collapseThree"
                                    eventKey="3"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <Accordion.Body className="accordion-body">
                                    <p>
                                        You authorise us to accept and act on your instructions. You
                                        acknowledge that the information you provide will form the
                                        basis upon which we will advise you.
                                    </p>
                                    </Accordion.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <Accordion.Item className="accordion-item" eventKey="4">
                                <Accordion.Header className="accordion-header" id="headingFour">
                                Data Protection
                                </Accordion.Header>
                                <Accordion.Collapse
                                     className="accordion-collapse collapse"
                                     id="collapseFour"
                                     eventKey="4"
                                     aria-labelledby="headingFour"
                                     data-bs-parent="#accordionExample"
                                >
                                    <Accordion.Body className="accordion-body">
                                    <p>
                                        My Debt Plan Ltd does not collect any personal data through
                                        its Website without users’ consent. Users alone decide whether
                                        to provide My Debt Plan Ltd with such data or not.
                                    </p>
                                    <p>
                                        When users visit the Website, information may be saved on
                                        users’ computers as a “cookie” which is then automatically
                                        recognised on the next visit to the Website.
                                    </p>
                                    <p>For further details please see our privacy policy.</p>
                                    </Accordion.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <Accordion.Item className="accordion-item" eventKey="5">
                                <Accordion.Header className="accordion-header" id="headingFive">
                                Monitoring
                                </Accordion.Header>
                                <Accordion.Collapse
                                    className="accordion-collapse collapse"
                                    id="collapseFive"
                                    eventKey="5"
                                    aria-labelledby="headingFive"
                                    data-bs-parent="#accordionExample"
                                >
                                    <Accordion.Body className="accordion-body">
                                    <p>
                                        
                                        Telephone calls and ‘online chats’ may be monitored and
                                        recorded for quality and training purposes.
                                    </p>
                                    </Accordion.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <Accordion.Item className="accordion-item" eventKey="6">
                                <Accordion.Header className="accordion-header" id="headingSix">
                                General
                                </Accordion.Header>
                                <Accordion.Collapse
                                    className="accordion-collapse collapse"
                                    id="collapseSix"
                                    eventKey="6"
                                    aria-labelledby="headingSix"
                                    data-bs-parent="#accordionExample"
                                >
                                    <Accordion.Body className="accordion-body">
                                    <p>
                                        These Terms and Conditions are governed by and shall be
                                        construed in accordance with English law. Disputes in
                                        connection with the Website shall be subject to the
                                        jurisdiction of English Courts.
                                    </p>
                                    </Accordion.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                </section >
                <TyFooter />
            </div >
        </>
    )
}

export default TermsAndConditions