import axios from "axios";
import { AppBudsApiUrl,AppAdtopiaToken } from "../Constants/EnvConstants";

export const BudsApi = axios.create({
  baseURL: AppBudsApiUrl,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

