import React, { useReducer, useState } from "react";
 import AddressTextField from "../Components/UI/AddressTextField";
 import AddressSelectBox from "../Components/UI/AddressSelectBox";
import Anchor from "../Components/UI/Anchor";
 import HiddenField from "../Components/UI/HiddenField";
import { usePostCodeAddress } from "../Hooks/usePostCodeAddress";
import InputButton from "../Components/UI/InputButton";

const POST_CODE_REGEX =
  /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i;

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "d-none",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "d-none",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  trigger,
  className,
  splitForm,
  getValues,
  showButton,
  showButtonAdditional,
  setIsSlide6,
  setShowNext,
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            if (setShowNext) setShowNext(true);
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "d-none",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumber",
        "txtAddress3",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "d-none" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress3").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
      <HiddenField
        name="txtCounty"
        inputId="txtCounty"
        inputValue={
          state.getDetails.county !== "#" ? state.getDetails.county : ""
        }
      />
      <HiddenField
        name="txtTown"
        inputId="txtTown"
        inputValue={state.getDetails.town !== "#" ? state.getDetails.town : ""}
      />
      <HiddenField
        name="txtCountry"
        inputId="txtCountry"
        inputValue={
          state.getDetails.country !== "#" ? state.getDetails.country : ""
        }
      />
    </>
  );

  return (
    <div id="slide-6" className={`slidetop_adjust ${className}`}>
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <div className="input-section">
        <div className="mb-3">
          <div className="post">
            <AddressTextField
              type="text"
              placeholder="Postcode*"
              name="txtPostCode"
              id="txtPostCode"
              className={`form-control col-xl-4 col-lg-4 col-md-4 ${
                setIsSlide6 ? "me-3 mb-3 " : ""
              }`}
              dataId="txtPostCode"
              onBlur={checkValidation}
              autoComplete="off"
              validation={validation({
                required: "Please Enter Postcode",
                pattern: {
                  value: POST_CODE_REGEX,
                  message: "Please Enter a Valid Postcode",
                },
                minLength: {
                  value: 5,
                  message: "Please Enter a Valid Postcode",
                },
              })}
              onkeypress={(e) => /[A-Z0-9]/i.test(e.key) || e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) =>
                /[^A-Z0-9]/i.test(e.clipboardData.getData("Text"))
                  ? e.preventDefault()
                  : null
              }
            />
            {validationMsg.txtPostCode && (
              <span className="address1_error error">
                Please Enter a Valid Postcode
              </span>
            )}
          </div>
        </div>
        <div
          className="col-lg-12 col-12  p-0 text-right slide8_lkup"
          style={state.lookUp ? { display: "block" } : { display: "none" }}
        >
          <div style={{ width: "100%" }} className="mb-3">
            <InputButton
              style={{ float: "none" }}
              name="next"
              className={`button-submit btn-commn-two efct_filed ${showButton}`}
              value="Lookup Address"
              btnType="button"
              onClick={lookupValidation}
            />
          </div>
        </div>
        <div
          className={`form-group col-lg-12 col-12 p-0 adj_width`}
          id="currentAddressCollapse"
          style={state.checkSelect ? { display: "block" } : { display: "none" }}
        >
          <AddressSelectBox
            className="form-control animated-effect  mb-3 efct_filed"
            OptionValue={state.getAddress}
            name="address1"
            id="address1"
            onChange={getValue}
            myRef={validation({ required: "Please select address" })}
            validationMsg={
              validationMsg.address1 && validationMsg.address1.message
            }
          />
        </div>
        <div
          className="col-12 text-center"
          style={
            state.getDetails.length === 0
              ? { display: "block" }
              : { display: "none" }
          }
        ></div>
        <div className={`slide8_add row ${state.showManualAddress}`}>
          <div className={`col-lg-12 form-group required-field adj_width`}>
            <AddressTextField
              type="text"
              placeholder="Address Line 1 *"
              name="txtHouseNumber"
              id="txtHouseNumber"
              className="form-control  mb-3  hero-input"
              dataId="txtHouseNumber"
              autoComplete="off"
              readonly={true}
              validation={validation({
                required: "Please Enter Address Line 1",
              })}
              validationMsg={
                validationMsg.txtHouseNumber &&
                validationMsg.txtHouseNumber.message
              }
              value={state.getDetails.line_1}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>

          {state.getDetails.line_2 && (
            <div className={`col-lg-12 form-group adj_width`}>
              <AddressTextField
                type="text"
                placeholder="Address Line 2"
                name="txtAddress2"
                id="txtAddress2"
                className="form-control  mb-3  hero-input"
                dataId="txtAddress3"
                autoComplete="off"
                readonly={true}
                validation={validation()}
                validationMsg=""
                value={state.getDetails.line_2}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            </div>
          )}
          <div className={`col-lg-12 form-group adj_width}`}>
            <AddressTextField
              type="hidden"
              placeholder="Street *"
              name="txtAddress3"
              id="txtAddress3"
              className="form-control mb-3 hero-input"
              dataId="txtAddress3"
              autoComplete="off"
              readonly={true}
              validation={validation()}
              validationMsg={
                validationMsg.txtAddress3 && validationMsg.txtAddress3.message
              }
              value={state.getDetails.line_3}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>
          <div className={`col-lg-12 form-group adj_width`}>
            <AddressTextField
              type="hidden"
              placeholder="County"
              name="txtCounty"
              id="txtCounty"
              className="form-control mb-3  hero-input"
              dataId="txtCounty"
              autoComplete="off"
              readonly={true}
              validation={validation()}
              validationMsg=""
              value={state.getDetails.county}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>

          <div className={`col-lg-12 form-group adj_width`}>
            <AddressTextField
              type="hidden"
              placeholder="Town *"
              name="txtTown"
              id="txtTown"
              className="form-control  mb-3  hero-input"
              dataId="txtTown"
              autoComplete="off"
              readonly={true}
              validation={validation({ required: "Please Enter Town" })}
              validationMsg={
                validationMsg.txtTown && validationMsg.txtTown.message
              }
              value={state.getDetails.town}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>
          <div className={`col-lg-12 form-group adj_width}`}>
            <AddressTextField
              type="hidden"
              placeholder="Country *"
              name="txtCountry"
              id="txtCountry"
              className="form-control mb-3 hero-input"
              dataId="txtCountry"
              autoComplete="off"
              readonly={true}
              validation={validation({ required: "Please Enter Country" })}
              validationMsg={
                validationMsg.txtCountry && validationMsg.txtCountry.message
              }
              value={state.getDetails.country}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>
          {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
        </div>

        <div
          className="col-lg-12 col-12 text-center p-0"
          style={state.nextClick ? { display: "block" } : { display: "none" }}
        ></div>
      </div>
    </div>
  );
};
export default PostCode;
