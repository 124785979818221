import React from 'react'
import { Link } from "react-router-dom";

const TyFooter = () => {
  return (
    <>
    <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <ul className="ft-menu">
                    <li>
                      <a href="privacyPolicy" target="blank"> Privacy Policy</a>
                    </li>
                    <li>
                      <a href="complaintsPolicy" target="blank"> Complaints Policy</a>
                    </li>
                    <li>
                      <a href="TermsAndConditions" target="blank"> T&Cs</a>
                    </li>
                    <li>
                      <a href="CookiePolicy" target="blank"> Cookie Policy</a>
                    </li>
                  </ul>
                  <div className="col-lg-12 ">
                    <p style={{ textAlign: "justify" }}>
                      Revolution Money is a trading style of My Debt Plan Ltd
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      My Debt Plan Ltd provides insolvency solutions to individuals, specialising in IVA’s. Advice and information on alternative debt solutions will be provided following an initial fact find where the individual(s) concerned meets the criteria. All advice given is provided in reasonable contemplation of an insolvency appointment.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Registered address 2nd Floor Blenheim Court, Cheadle, Cheshire, England, SK8 2JY Company Registered in England and Wales Number 10992838 Data Protection ZB284067.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                    To find out more about managing your money and getting free advice, visit<a className='text-decoration-none' href='https://www.moneyhelper.org.uk/en'> Money Helper, </a>
                    an independent service set up to help people manage their money. Or visit our sister site <a className='text-decoration-none' href='https://bailiffhelpline.co.uk/'>Bailiff Helpline </a> for all your bailiff help & advice.
                  </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-footer-style" />
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="mt-3 mb-4 text-white">
                  Copyright <span id="setFooterYear">2023</span> All
                  Rights Reserved.{" "}
                </p>
              </div>
            </div>
          </div>
        </footer>

    
    </>
  )
}

export default TyFooter