import React, { useReducer, useState } from "react";
import AddressTextField from "../Components/UI/AddressTextField";
import AddressSelectBox from "../Components/UI/AddressSelectBox";
import Anchor from "../Components/UI/Anchor";
import HiddenField from "../Components/UI/HiddenField";
import { usePostCodeAddress } from "../Hooks/usePostCodeAddress";
import InputButton from "../Components/UI/InputButton";

const POST_CODE_REGEX =
  /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i;

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "d-none",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      console.log(action.payload.get_details);
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "d-none",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PreviousAddress = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  trigger,
  className,
  splitForm,
  getValues,
  showButton,
  showButtonAdditional,
  setIsSlide6,
  setShowNext,
  setValue,
  hide,
  setHide,
  clickedAddPre
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");

  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPrevPostCode_1 = await trigger("txtPrevPostCode_1");
    if (txtPrevPostCode_1) {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPrevPostCode_1", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            setHide('d-block');
            if (setShowNext) setShowNext(true);
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "d-none",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPrevPostCode_1", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    clearErrors([

      "txtPrevHouseNumber_1",
      "txtPrevAddress3_1",
      "txtPrevCounty_1",
      "txtPrevTown_1",
      "txtPrevCountry_1",
      "txtPrevaddress1_1",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtPrevHouseNumber_1",
        "txtPrevAddress3_1",
        "txtPrevCounty_1",
        "txtPrevTown_1",
        "txtPrevCountry_1",
        "txtPrevaddress1_1",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "d-none" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPrevPostCode_1");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtPrevHouseNumber_1").value;
    const addressLine2 = document.getElementById("txtPrevAddress3_1").value;
    const town = document.getElementById("txtPrevTown_1").value;
    const county = document.getElementById("txtPrevCounty_1").value;
    const country = document.getElementById("txtPrevCountry_1").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtPrevUdprn_1"
        inputId="txtPrevUdprn_1"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtPrevDeliveryPointSuffix_1"
        inputId="txtPrevDeliveryPointSuffix_1"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPrevPz_mailsort_1"
        inputId="txtPrevPz_mailsort_1"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtPrevStreet_1"
        inputId="txtPrevStreet_1"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
      <HiddenField
        name="txtPrevCounty_1"
        inputId="txtPrevCounty_1"
        inputValue={
          state.getDetails.county !== "#" ? state.getDetails.county : ""
        }
      />
      <HiddenField
        name="txtPrevTown_1"
        inputId="txtPrevTown_1"
        inputValue={state.getDetails.town !== "#" ? state.getDetails.town : ""}
      />
      <HiddenField
        name="txtPrevCountry_1"
        inputId="txtPrevCountry_1"
        inputValue={
          state.getDetails.country !== "#" ? state.getDetails.country : ""
        }
      />
    </>
  );
  return (
    <div id="slide-6" className={`slidetop_adjust ${className}`}>
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <div className="input-section">
        <div className="mb-3">
          <div className="post">
            <AddressTextField
              type="text"
              placeholder="Past Postcode*"
              name="txtPrevPostCode_1"
              id="txtPrevPostCode_1"
              className={`form-control col-xl-4 col-lg-4 col-md-4 ${setIsSlide6 ? "me-3 mb-3 " : ""
                }`}
              //="txtPrevPostCode_1"
              onChange={checkValidation}
              // onBlur={checkValidation}
              autoComplete="off"
              validation={validation({
                required: "Please Enter Postcode",
                pattern: {
                  value: POST_CODE_REGEX,
                  message: "Please Enter a Valid Postcode",
                },
                minLength: {
                  value: 5,
                  message: "Please Enter a Valid Postcode",
                },
              })}
              onkeypress={(e) => /[A-Z0-9]/i.test(e.key) || e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) =>
                /[^A-Z0-9]/i.test(e.clipboardData.getData("Text"))
                  ? e.preventDefault()
                  : null
              }
            />
            {validationMsg.txtPrevPostCode_1 && (
              <span className="address1_error error">
                Please Enter a Valid Postcode
              </span>
            )}
          </div>
        </div>

        <div
          className="col-lg-12 col-12  p-0 text-right slide8_lkup"
          style={(state.lookUp || hide === "d-none") ? { display: "block" } : { display: "none" }}
        >
          <div style={{ width: "100%" }} className="mb-3">
            <InputButton
              style={{ float: "none" }}
              name="next"
              className={`button-submit btn-commn-two efct_filed ${showButton}`}
              value="Lookup Address"
              btnType="button"
              onClick={lookupValidation}
            />
          </div>
        </div>
        {hide === "d-none" ? (
          <>
          </>
        ) : (
          <>
            <div
              className={`form-group col-lg-12 col-12 p-0 adj_width `}
              id="currentAddressCollapse"
              style={state.checkSelect ? { display: "block" } : { display: "none" }}
            >
              <AddressSelectBox
                className={`form-control animated-effect  mb-3 efct_filed `}
                OptionValue={state.getAddress}
                name="txtPrevaddress1_1"
                id="txtPrevaddress1_1"
                onChange={getValue}
                myRef={validation({ required: "Please select address" })}
                validationMsg={
                  validationMsg.txtPrevaddress1_1 && validationMsg.txtPrevaddress1_1.message
                }
              />
            </div>
            <div className={`slide8_add row ${state.showManualAddress}`}>
              {state.getDetails.line_1 && (

                <div className={`col-lg-12 form-group required-field adj_width`}>
                  <AddressTextField
                    type="text"
                    placeholder="Address Line 1 *"
                    name="txtPrevHouseNumber_1"
                    id="txtPrevHouseNumber_1"
                    className="form-control  mb-3  hero-input"
                    //="txtPrevAddress3_1"

                    autoComplete="off"
                    readonly={true}
                    value={state.getDetails.line_1}
                    validation={validation({
                      required: "Please Enter Address Line 1",
                    })}
                    validationMsg={
                      validationMsg.txtPrevHouseNumber_1 &&
                      validationMsg.txtPrevHouseNumber_1.message
                    }
                  />
                  <i
                    className="validate validate_success"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  ></i>
                </div>
              )}
              {state.getDetails.line_2 && (
                <div className={`col-lg-12 form-group adj_width`}>
                  <AddressTextField
                    type="text"
                    placeholder="Address Line 2"
                    name="txtPrevAddress2_1"
                    id="txtPrevAddress2_1"
                    className="form-control  mb-3  hero-input"
                    //="txtPrevAddress3_1"
                    autoComplete="off"
                    readonly={true}
                    validation={validation()}
                    validationMsg=""
                    value={state.getDetails.line_2}
                  />
                  <i
                    className="validate validate_success"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  ></i>
                </div>
              )}
              <div className={`col-lg-12 form-group adj_width}`}>
                <AddressTextField
                  type="hidden"
                  placeholder="Street *"
                  name="txtPrevAddress3_1"
                  id="txtPrevAddress3_1"
                  className="form-control mb-3 hero-input"
                  //="txtPrevAddress3_1"
                  autoComplete="off"
                  readonly={true}
                  validation={validation()}
                  validationMsg={
                    validationMsg.txtPrevAddress3_1 && validationMsg.txtPrevAddress3_1.message
                  }
                  value={state.getDetails.line_3}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`col-lg-12 form-group adj_width`}>
                <AddressTextField
                  type="hidden"
                  placeholder="County"
                  name="txtPrevcounty_1"
                  id="txtPrevcounty_1"
                  className="form-control mb-3  hero-input"
                  //="txtPrevcounty_1"
                  autoComplete="off"
                  readonly={true}
                  validation={validation()}
                  validationMsg=""
                  value={state.getDetails.county}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>

              <div className={`col-lg-12 form-group adj_width`}>
                <AddressTextField
                  type="hidden"
                  placeholder="Town *"
                  name="txtPrevTown_1"
                  id="txtPrevTown_1"
                  className="form-control  mb-3  hero-input"
                  //="txtPrevTown_1"
                  autoComplete="off"
                  readonly={true}
                  validation={validation({ required: "Please Enter Town" })}
                  validationMsg={
                    validationMsg.txtPrevTown_1 && validationMsg.txtPrevTown_1.message
                  }
                  value={state.getDetails.town}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`col-lg-12 form-group adj_width}`}>
                <AddressTextField
                  type="hidden"
                  placeholder="Country *"
                  name="txtPrevCountry_1"
                  id="txtPrevCountry_1"
                  className="form-control mb-3 hero-input"
                  //="txtPrevCountry_1"
                  autoComplete="off"
                  readonly={true}
                  validation={validation({ required: "Please Enter Country" })}
                  validationMsg={
                    validationMsg.txtPrevCountry_1 && validationMsg.txtPrevCountry_1.message
                  }
                  value={state.getDetails.country}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default PreviousAddress;
