import React from 'react'
import loader from "../../../../assets/img/loader.png";

const Loader = (props) => {
  return (
    <>
     <div className={`overlay text-center d-flex align-items-center ${props.show}`}>
        <div className="mt-5">
          <div className="loader-box">
            <img className="loader" src={loader} alt="" />
          </div>
          Loading...
        </div>
      </div>
      <div className={`overlay text-center d-flex align-items-center ${props.button}`}>
        <div className="mt-5">
        <input type="button" id="slNext" className="btn-commn-two" onClick={props.click}  value="Refresh" />
        </div>
      </div>
    </>
  )
}

export default Loader