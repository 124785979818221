export const UrlParams = () => {
    const getUrlParams = () => {
      let customUuid = "";
      let customSource = "";
      let token = "";
      const query = new URLSearchParams(window.location.search);
      console.log(query);
      const query_uuid = query.get("uuid");
      const query_source = query.get("source");
      const query_token = query.get("atp_sub2");
      const local_uuid = localStorage.getItem("uuid");
      const local_storage_source = localStorage.getItem("atp_sub6");
      const local_storage_user_token = localStorage.getItem("user_token");
      customUuid = query_uuid ? query_uuid : local_uuid;
      customSource = query_source ? query_source : local_storage_source;
      token = query_token ? query_token : local_storage_user_token;
      return { customUuid, customSource, token };
    };
    return { getUrlParams };
  };
  