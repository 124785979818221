import React from "react";

const ProgressBar = (props) => {
	const list = [];
	for (var i = 1; i <= props.count; i++) {
		let li = '';
		if (i <= props.stage) {
			li = <li className='active'></li>;
			list.push(li);
		} else {
			li = <li></li>;
			list.push(li);
		}
	}
	const listTag = list.map((item, index) => {
		return (
			item
		);
	});
	return (
		<>
			<div className="progress-body mb-4">
				<div className="progress" role="progressbar" aria-label="Basic example" ariaValuenow="9" ariaValuemin="0" ariaValuemax="100">
					<div className="progress-bar" style={{ width: props.width + "%" }}></div>
				</div>
				<ul className="form-progress d-flex  justify-content-between  align-items-center ">
						{listTag}
				</ul>
			</div>
			<p className="text-center counter-text mb-5">Step {props.stage} of {props.count}</p>
		</>
	)
}
export default ProgressBar;