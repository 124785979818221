import React from "react";
import PrivacyHeader from "../Common/PrivacyHeader";

import TyFooter from "../Common/TyFooter";
const Complaints_policyV3=()=>{
window.scrollTo(0,0);
    return(
        <>
         <div className="privacy">
        <div className="top-main-section">
   <PrivacyHeader/>
  
    </div>
 
  <section className="pt-5 pb-2">
    <div className="container">
      <h1>Complaints Policy</h1>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="inner_text py-2 py-md-5">
        <h4>Complaints Procedure </h4>
        <p>
          {" "}
          This notice sets out details of the complaints procedure which we
          operate to try to resolve any complaints which you may have in
          relation to the services that we provide. This procedure is aimed at
          resolving complaints quickly and satisfactorily and further improving
          the quality of the service we provide.
        </p>
        <h4>How do you make a complaint?</h4>
        <p>
          {" "}
          If you are unhappy with the service we have provided to you, you
          should feel free to raise this immediately during any interaction with
          a My Debt Plan member of staff. We will always endeavour to resolve
          any issue immediately if it is possible to do so.
        </p>
        <p>
          {" "}
          Should this not be possible, or if you remain dissatisfied, then you
          should make it known to us that you wish the matter to be taken
          forward as a complaint. This can be done verbally by telephone (0161
          359 4708), by email to{" "}
          <a href="Complaints@mydebt-plan.co.uk">
            Complaints@mydebt-plan.co.uk
          </a>{" "}
          , by sending a message via the client portal or in writing to
          “Complaints Officer, My Debt Plan Ltd, Unit 1 and 2 Blenheim Court,
          Cheadle SK8 2JY”.
        </p>
        <h4>How will we handle your complaint?</h4>
        <p>
          {" "}
          Your complaint will be acknowledged within 5 days of receipt and then
          taken forward by a manager, who may need to contact you initially for
          further information in order to better understand the issues at hand.
          We will always seek to find a satisfactory resolution quickly (within
          7 days) if possible.{" "}
        </p>
        <p>
          If circumstances mean that a rapid resolution is not possible then a
          full investigation into all the circumstances of the matter will take
          place. A Final Response letter will be sent to you within28 working
          days confirming whether your complaint has been upheld, in full or in
          part and the reasons for the decision. Where applicable, you will be
          advised of what steps the company will take to put things right and to
          ensure the same problem does not re-occur.
        </p>
        <p>
          It is the company’s aim to resolve any complaint satisfactorily within
          28 working days, although occasionally where more detailed
          investigation is required the process may take longer. If this is the
          case then you will be notified of this extended timescale.
        </p>
        <h4>
          What if you are unhappy about how your complaint has been handled?
        </h4>
        <p>
          If you are not satisfied with our final response, or indeed at any
          point in the process, you can raise a complaint with the Insolvency
          Practitioner’s professional body via the Insolvency Complaints
          Gateway. This can be done by visiting the Insolvency Service website
          at{" "}
          <a href="https://www.gov.uk/complain-about-insolvency-practitioner">
            https://www.gov.uk/complain-about-insolvency-practitioner
          </a>{" "}
          and completing and submitting their online form.
        </p>
        <p>
          Complaints can also be by post to: IP Complaints, Insolvency Service,
          3rd Floor, 1 City Walk, Leeds, LS11 9DA or by calling the Insolvency
          Service Enquiry Line on 0300 678 0015 (Monday to Friday 8am to 5pm). A
          summary of the IPA’s complaint procedure can be found at:
          <br />{" "}
          <a href="http://www.insolvency-practitioners.org.uk/complaints-procedure/complaint-procedure">
            http://www.insolvency-practitioners.org.uk/complaints-procedure/complaint-procedure
          </a>
          .
        </p>
      </div>
    </div>
  </section>


    <TyFooter />
    </div>
        </>
    )
}
export default Complaints_policyV3;
