import { Api } from "../../api/Api";

export const storeApiResponse = async (visitor_parameters, message_type, uuid, open_banking_data) => {
    const response = await Api.post("/v1/data-ingestion-pipeline", {
      visitor_parameters,
      message_type,
      uuid,
      open_banking_data
    });
    return response;
  };