import React from "react";
import Personals from "./Personals";
 import GTMDataLayer from "../Common/GTMDataLayer";

const PersonalDetails = (props) => {

    const question_id = props.questionnaire.questionId;
    const radioName = 'question_'+question_id;
    const handleSubmitChk = async () =>{
        let firstName = await props.trigger('txtFName');
        let lastName  = await props.trigger('txtLName');
        const values  = props.getValues();
        const firstValue = values['txtFName'];
        const lastValue = values['txtLName'];
        if((firstValue.length >= 3 && lastValue.length >= 3) ){
            console.log(123);
            props.FormSubmit();
            
        }
        GTMDataLayer({
            question: "Name",
            answer:""
          });
    }

    return (
        <div id="slide9" className={`slider8 fade-in ${props.showQuestionSlide}`}>
            <p className="text-center counter-text mb-5">   </p>
            <div className="forms shadow mt-3">
                <div className="down-arrow shake-vertical">
                    <img src="dist/img/down-arrow-circle.png" alt="" />
                </div>
                <div className="col-lg-12 row custom m-0 forminnercontent text-center">
                    <h1 className="title">{props.questionnaire.question}</h1>
                    <div className="mt-3">
                    <Personals
                        validation={props.validation}
                        validationMsg={props.validationMsg}
                        trigger={props.trigger}
                        clearErrors={props.clearErrors}
                        setError={props.setError}
                    />
                    </div>
                </div>
            </div>
            {/* Back */}
            <div className="container">
                <div className="row">
                <div className="col-lg-6 col-sm-6 col-12 order-sm-first order-last btn-more  back-btn  mt-4"
                    id={`back${question_id}`}
                    name={`back${question_id}`}
                    onClick={(e)=>{props.previousSlide(e)}}
                    >
                    Back
                </div>
                <div className="col-lg-6 col-sm-6 col-12 btn-more next-btn mt-lg-4 mt-md-4 mt-sm-4 mt-4 next006"  id={`next${question_id}`} name = {`next${question_id}`} onClick={props.handleSubmit(handleSubmitChk)}  >
                    <span> Next</span>
                </div>
            </div>
            </div>
        </div>

    )
}
export default PersonalDetails;