import React, { useState } from "react";
import downArrow from "../../../../assets/img/down-arrow-circle.png";

const QuestionRadioSlide = (props) => {
  const subText = props.questionnaire.questionSubText;
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const length = props.questionnaire.answers.length;
  const answerBlock = props.questionnaire.answers.map((item, index) => {
    return (

      <div className={((length % 2 != 0) && (length - 1 == index)) ? "last-col" : ""} key={index}>
        <input type="radio"
          id={'question_' + question_id + '_' + item.value}
          name={'question_' + question_id}
          value={item.value} data-answertext={item.label}
          data-question_id={question_id}
          ref={props.validation({ required: "Please Select" })}
          onClick={(e) => { props.slideChange(e, radioName) }}
          className="next001 radiobtn"
        />
        <label className="com-button" for={'question_' + question_id + '_' + item.value}>

          <span>{item.label}</span>
        </label>
      </div>

    )
  })

  return (
    <>
      <div id={'slide' + question_id} className={`slider${question_id} ${props.showQuestionSlide}`}>
        <div className="forms shadow mt-3">
          <div className="down-arrow shake-vertical">
            <img src={downArrow} alt="" />
          </div>
          <div className="col-lg-12">
            <div className="col-lg-12 row custom m-0 forminnercontent text-center">
              {((props.questionnaire.questionSubText) && (props.questionnaire.subTextPos == "top")) ? <><div dangerouslySetInnerHTML={{ __html: subText }} /> </> : ""}
              <h1 className="title">{props.questionnaire.question}</h1>
              {((props.questionnaire.questionSubText) && (props.questionnaire.subTextPos == "bottom")) ? <><div dangerouslySetInnerHTML={{ __html: subText }} /> </> : ""}
              {((props.questionnaire.questionMaintext)) ? <><div dangerouslySetInnerHTML={{ __html: props.questionnaire.questionMaintext }} /> </> : ""}
              <div className="button-group-grid mt-3">
                {answerBlock}
              </div>
              {question_id == '2' &&
              <div className="btn_sec1">
                <label className="btn_rating">May not be suitable in all circumstances,
                  fees apply, your credit rating may be
                  affected.</label>
              </div>}
            </div>
          </div>
        </div>

        <p className="pinktext">
            An Individual Voluntary Arrangement (IVA) may not be suitable in all circumstances, <a href="https://revolutionmoney.co.uk/fees/" style={{ color: 'white' }} target="blank"><u>fees</u>
            </a> may apply, your <a href="https://revolutionmoney.co.uk/fees/" style={{ color: 'white' }}target="blank"><u>credit rating</u></a> may be affected. For more information, please see below or contact one of our debt specialists today

          </p>
        {props.backBtn &&
          <div className="text-lg-start text-md-start text-sm-center text-center" >
            <div className="btn-more back-btn  mt-4 " id={`back${question_id}`} name={`back${question_id}`} onClick={props.previousSlide}>
              <span> Back </span>
            </div>
          </div>}

     
     
     
      </div>
    </>
  )
};

export default QuestionRadioSlide;
