import React from "react";
import PrivacyHeader from "../Common/PrivacyHeader";
import Footer from "../Common/Footer";
const Complaints_policy=()=>{
window.scrollTo(0,0);
    return(
        <>
         <div className="privacy">
        <div className="top-main-section">
   <PrivacyHeader/>
  
    </div>

    <section className="pt-5 pb-2">
      <div className="container">
        <h1>Revolution Money’s Complaints Policy</h1>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="inner_text py-5">
          <p>Revolution Money endeavours to provide each of our clients and stakeholders with the highest standards of service. However, if you are unhappy for any reason, we would welcome your feedback and the opportunity to put things right.</p>
          <h4>How To Make A Complaint </h4>
          <p>If you wish to make a complaint about the service you have received from Revolution Money, please contact us using one of the following methods: </p>
          <p className="mb-0">
            <strong> by phone on:</strong>
            <a href="tel:01614136076">0161 413 6076</a>
          </p>
          <p className="mb-0">
            <strong> by email to:</strong>
            <a href="mailto:info@arkinsolvency.co.uk">info@arkinsolvency.co.uk</a>
          </p>
          <p>
            <strong> by post to:</strong> Complaints Team, Universal Square, Devonshire Street North, Manchester, M12 6JH.
          </p>
          <p>In order to help us understand your complaint and address any concerns, please provide us with the following information:</p>
          <ul>
            <li>Your full name, address and case reference number.</li>
            <li>The name of the person(s) to whom you have spoken to at ARK Insolvency Ltd.</li>
            <li>Details of your complaint.</li>
            <li>Copies of any correspondence or documents relating to your complaint.</li>
            <li>How you believe we can resolve your complaint.</li>
          </ul>
          <h4>What Happens Next?</h4>
          <p>Initially, a member of our complaints team will contact you to resolve your complaint over the telephone. If you are satisfied with the outcome following this, no further action will be taken.</p>
          <p>If we are unable to resolve your complaint at first contact, we will request for your complaint to be submitted in writing. A written acknowledgement will be issued to you no later than five working days from receiving your complaint. Your concerns will be fully investigated by a Complaints Officer along with the Insolvency Practitioner.</p>
          <p>A final response will be issued to you within eight weeks of the initial receipt of your complaint or we will explain why we are not able to issue a response within that timescale.</p>
          <p>During this time the Complaints Officer may need to contact you to seek additional information or clarification regarding your complaint.</p>
          <p>If you are not satisfied with our response to your complaint, you have the right to make a formal complaint to our regulator.</p>
          <p>Kevin Duffy is licensed to act as an Insolvency Practitioner in the UK by the Insolvency Practitioners Association. Their aim is to promote and maintain a high standard throughout the insolvency industry and can be contacted on:</p>
          <h4>Insolvency Practitioners Association</h4>
          <p>
            <strong>32 Blackfriars Road, <br/> London, <br/> SE1 8PB </strong>
          </p>
          <p>The Insolvency Practitioners Association will investigate the conduct of the Insolvency Practitioner and cannot provide you with any financial redress or make decisions particular to your IVA. To complain about your Insolvency Practitioner, you can refer your complaint to the Insolvency Complaints Gateway at any time, or if you remain dissatisfied by our response by:</p>
          <p>https://www.gov.uk/complain-about-insolvency-practitioner</p>
          <p>Calling the Insolvency Service Enquiry Line on 0300 678 0015 Monday – Friday 9am – 5pm</p>
          <p>Emailing the Insolvency Service on – insolvency.enquiryline@insolvency.gov.uk</p>
          <p>Completing the online complaints form and posting it to: IP Complaints, Insolvency Service, 3rd Floor, 1 City Walk, Leeds, LS11 9DA</p>
          <h2>Financial Ombudsman Service</h2>
          <p>Alternatively, if the complaint is in relation to the debt advice provided, you can escalate the complaint to the Financial Ombudsman Service (FOS).</p>
          <p>You can contact the FOS using the following methods:</p>
          <p className="mb-0">
            <strong> Post:</strong> Financial Ombudsman Service, Exchange Tower, London E14 9SR
          </p>
          <p className="mb-0">
            <strong> Online: </strong>www.financial-ombudsman.org.uk
          </p>
          <p>
            <strong> By freephone:</strong> 0800 023 567 *
          </p>
          <p>
            <i> * calls are charged at the same rates as 01/02 number on mobile tariffs. </i>
          </p>
        </div>
      </div>
    </section>
  <Footer/>
    </div>
        </>
    )
}
export default Complaints_policy;
