import React, { useEffect, useReducer, useState } from "react";
import V3Header from "../../Includes/Layouts/Common/V3Header";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FormSplitMRD_V3 from "../../Forms/FormSplitMRD_V3";
import pointing from "../../../assets/img/pointing.webp";
import img8556 from "../../../assets/img/img8556.png";
import img85666 from "../../../assets/img/img85666.png";
import img8566 from "../../../assets/img/img8566.png";
import supporimg8889 from "../../../assets/img/supporimg8889.png";
import feefo_logo_largeb from "../../../assets/img/feefo_logo_largeb.webp";
import star87 from "../../../assets/img/star-87.png";
import debt from "../../../assets/img/debt.png";
import { Accordion } from "react-bootstrap";
import TyFooter from "../../Includes/Layouts/Common/TyFooter";
import FeefoDept from "../FeefoDept";

const MRD_V3 = () => {

  const initialState = {
    showModal1: "d-none",
    showDiv: "d-none",
    showModal2: "d-none",
    showModal3: "d-none",
    showModal4: "d-none",
    showModal5: "d-none",
    showModal6: "d-none",
    showModal7: "d-none",
  }
  const modalReducer = (state, action) => {
    switch (action.type) {
      case "displayModal": {
        if (action.payload == "modal_1") {
          return {
            ...state,
            showModal1: "d-block",
            showDiv: "modal-backdrop fade show",
          }
        }
        else
          if (action.payload == "modal_2") {
            return {
              ...state,
              showModal2: "d-block",
              showDiv: "modal-backdrop fade show",
            }
          }
          else if (action.payload == "modal_3") {
            return {
              ...state,
              showModal3: "d-block",
              showDiv: "modal-backdrop fade show",
            }
          }
          else if (action.payload == "modal_4") {
            return {
              ...state,
              showModal4: "d-block",
              showDiv: "modal-backdrop fade show",
            }
          }
          else if (action.payload == "modal_5") {
            return {
              ...state,
              showModal5: "d-block",
              showDiv: "modal-backdrop fade show",
            }
          }
          else if (action.payload == "modal_6") {
            return {
              ...state,
              showModal6: "d-block",
              showDiv: "modal-backdrop fade show",
            }
          }
          else if (action.payload == "modal_7") {
            return {
              ...state,
              showModal7: "d-block",
              showDiv: "modal-backdrop fade show",
            }
          }
          else {
            return {
              ...state,
            }
          }

      }
      case "closeModal": {
        if (action.payload) {
          return {
            ...state,
            showModal1: "d-none",
            showDiv: "d-none",
            showModal2: "d-none",
            showModal3: "d-none",
            showModal4: "d-none",
            showModal5: "d-none",
            showModal6: "d-none",
            showModal7: "d-none",
          }
        }
        else {
          return {
            ...state,
          }
        }
      }
    }
  }

  const [clickModal, setclickModal] = useState();
  const [closeModal, setCloseModal] = useState();
  const [state, dispatch] = useReducer(modalReducer, initialState);

  useEffect(() => {
    if (clickModal) {
      dispatch({ type: "displayModal", payload: clickModal })
    }
  }, [clickModal]);

  useEffect(() => {
    if (closeModal) {
      dispatch({ type: "closeModal", payload: closeModal })
    }
  }, [closeModal])

  const ModalSubmit = (e, modalName) => {
    setclickModal(modalName);
  }

  const ModalClose = (modalName) => {
    setclickModal("");
    setCloseModal(modalName)
  }

  return (
    <>
      <div className="MRD_V3">
        <AdtopiaLoadLP pageType="LP" splitName="MRD_V3" />
    
        <div className="top-main-section">
          <V3Header />
          {/* ========= form-part Area start ========= */}
          <FormSplitMRD_V3 />
          {/* ========= form-part Area end ========= */}
          <div className="man-img">
            <img src={pointing} alt="" />
          </div>
        </div>
        {/* =========End Main Section ========= */}
        <section className=" middle-sec-wrap  mt-0 clearfix">
          <div className="container">
            <div className="middle-sec">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 text-center text-white">
                  <div className="box my-5">
                    <div className="mb-3">
                      <img src={img8556} alt="" />
                    </div>
                    <h1>Complete the simple debt assessment.</h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center text-white">
                  <div className="box my-5">
                    <div className="mb-3">
                      <img src={img85666} alt="" />
                    </div>
                    <h1>
                      {/* Discuss your options with one of our friendly debt experts. */}
                      Discuss your options with one of our friendly debt advisors.
                    </h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center text-white">
                  <div className="box my-5">
                    <div className="mb-3">
                      <img src={img8566} alt="" />
                    </div>
                    <h1>Start your journey to making new happy memories.</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="assessment-wrap my-5">
          <div className="container">
            <div className="inner pb-lg-0 pb-md-0 pb-sm-5">
              <div className="row">
                <div className="col-lg-5 text-lg-end text-md-center text-sm-center  mt-3">
                  <img src={supporimg8889} alt="" />
                </div>
                <div className="col-lg-6 text-center text-lg-start text-md-start text-sm-center">
                  <h2 className="mb-4">
                    Contact us today for an assessment of your financial
                    circumstances.
                  </h2>
                  <p>
                    If you decide to proceed with an Individual Voluntary Arrangement
                    (IVA), there are fees which will be taken out (not on top of) of
                    your monthly contributions.
                  </p>
                  <div className="mb-lg-5 mb-md-5 mb-sm-4 mb-4 mt-4">
                    <a href="" className="btn-gent-help w-100">
                      Get Debt Help!
                    </a>
                  </div>
                </div>
                <div >
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="review-section mt-5">
          {/* <div className="container">
            <div className="row mb-lg-5 mb-md-5 mb-sm-3 ">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className="feefo-review d-flex justify-content-between">
                  <img
                    width=" "
                    className="img-fluid me-2"
                    src={feefo_logo_largeb}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h1 className="mt-4 mt-md-2 mt-sm-2 fs-1">
                  Customer <br /> Experience Rating
                </h1>
              </div>
            </div>
            <div className="swiper InitSlider ">
              <div className="swiper-wrapper  ">
                <div className="swiper-slide">
                  <div className="review-box  mb-2 bg-body-tertiary">
                    <h3 className="mb-2">'Amazing supportive company'</h3>
                    <img className="starimg mb-2" src={star87} alt="" />
                    <p>
                      Honestly so helpful and a true caring and understanding side. I have always been nervous about asking for help but revolution money have been more than helpful. Supporting me with everything and every question. Can’t thank them enough for their help.
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="review-box  mb-2 bg-body-tertiary  ">
                    <h3 className="mb-2">' Great service '</h3>
                    <img className="starimg mb-2" src={star87} alt="" />
                    <p>Very friendly and professional would recommend</p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="review-box  mb-2 bg-body-tertiary ">
                    <h3 className="mb-2">
                      ' Initial process was smooth and helpful. '
                    </h3>
                    <img className="starimg mb-2" src={star87} alt="" />
                    <p>
                      Initial process was fine and great help In sourcing information
                      on debts etc. Once with the actual IVA provider service is not
                      too great.
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="review-box  mb-2 bg-body-tertiary  ">
                    <h3 className="mb-2">' Great service '</h3>
                    <img className="starimg mb-2" src={star87} alt="" />
                    <p>
                      Great service provided. It was quick any easy. They helped me
                      through every step and made me feel comfortable at all times
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <FeefoDept />
        </section>


        {/* Debt Solutions */}
        <section className="review-section debt-section mt-0 pt-0">
          <div className="container">
            <h2 className="text-center mb-4 fs-1"> Debt Solutions </h2>
            <p className="text-center px-5">
              If you are struggling to repay your debts, please don’t worry, we’re
              here for you. No matter you situation there will be a solution for you,
              here we feature the most popular debt solutions, but in order for us to
              correctly identify the correct solution for your particular set of
              circumstances, we will need to talk to you.
            </p>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 order-lg-1 pt-3 pt-lg-0 pt-md-0 pt-sm-3">
                <ul className="list-points ">
                  <li>
                    {" "}
                    <a data-bs-toggle="modal" data-bs-target="#pop1" onClick={(e) => ModalSubmit(e, "modal_1")}>
                      Bankruptcy
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a data-bs-toggle="modal" data-bs-target="#pop2" onClick={(e) => ModalSubmit(e, "modal_2")}>
                      Breathing space scheme
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a data-bs-toggle="modal" data-bs-target="#pop3" onClick={(e) => ModalSubmit(e, "modal_3")}>
                      Consolidation loans
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a data-bs-toggle="modal" data-bs-target="#pop4" onClick={(e) => ModalSubmit(e, "modal_4")}>
                      Debt management plan
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 order-lg-3">
                <ul className="list-points text-lg-end text-md-end text-sm-start">
                  <li>
                    {" "}
                    <a data-bs-toggle="modal" data-bs-target="#pop5" onClick={(e) => ModalSubmit(e, "modal_5")}>
                      {" "}
                      Debt relief order
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a data-bs-toggle="modal" data-bs-target="#pop6" onClick={(e) => ModalSubmit(e, "modal_6")}>
                      Individual voluntary arrangement
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a data-bs-toggle="modal" data-bs-target="#pop7" onClick={(e) => ModalSubmit(e, "modal_7")}>
                      Remortgage
                    </a>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 order-lg-2 text-center  Debt-img">
                <div>
                  <img height={400} src={debt} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Debt Solutions */}
        <Accordion className="quastion-area  clearfix">
          <div className="container mb-5">
            <h2 className="text-center mb-4 fs-1"> Any Questions </h2>
            <div className="accordion" id="accordionExample">
              <Accordion.Item className="accordion-item mb-3" eventKey="0">
                <Accordion.Header className="accordion-header" id="headingOne">

                  {" "}
                  What Is An IVA?{" "}

                </Accordion.Header>
                <Accordion.Collapse
                  id="collapseOne"
                  eventKey="0"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <Accordion.Body className="accordion-body">
                    <p>
                      An Individual Voluntary Arrangement (IVA) is a formal, legally
                      binding agreement between you and your unsecured creditors. If
                      you’re struggling with your unsecured debt, unable to manage
                      both your household bills and your credit commitments, an IVA
                      could be the debt relief solution for you.{" "}
                    </p>
                    <p>
                      {" "}
                      An IVA allows you to set up an affordable repayment plan with
                      the creditors included in the arrangement over a set period of
                      time (typically between five – six years).{" "}
                    </p>
                    <p>
                      {" "}
                      During the term of the IVA your interest and charges are frozen,
                      and your unsecured creditors are unable to take any further
                      legal action against you or chase you for payments. If you own
                      your own property, you won’t be asked to sell your house. Whilst
                      in the IVA, your circumstances will be reviewed on an Annual
                      basis to ensure that the repayment plan is still affordable
                      using nationally recognised spending guidelines.{" "}
                    </p>
                    <p>
                      {" "}
                      Upon the successful completion of the IVA, any remaining debt
                      and the accrued interest and charges are written off. As with
                      any formal debt solution, your
                      credit rating will be affected for six years from the start date
                      and your name will be added to a Public Register (the Individual
                      Insolvency Register). If you come into any money during the term
                      of the IVA e.g. an inheritance, lottery win, pay rise or bonus,
                      you may have to pay a proportion of the funds received towards
                      your debt.{" "}
                    </p>
                    <p>
                      {" "}
                      If you own your own property, you may be required to free up any
                      equity in your property by way of a remortgage to pay towards
                      your debts; – remortgaging whilst on an IVA is likely to be on
                      less favourable terms than your current mortgage. However, if
                      you can’t remortgage, the IVA could be extended by up to 12
                      months.{" "}
                    </p>
                    <p>
                      {" "}
                      It is important that you keep up with your repayment plan. If
                      your IVA fails, you could be at risk of bankruptcy.
                    </p>
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              <Accordion.Item className="accordion-item mb-3" eventKey="1">
                <Accordion.Header className="accordion-header" id="headingTwo">

                  {" "}
                  What Type of Debt Can We Help With?{" "}

                </Accordion.Header>
                <Accordion.Collapse
                  id="collapseTwo"
                  eventKey="1"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <Accordion.Body className="accordion-body">
                    <p>
                      We can help with pretty much any unsecured debt such as credit
                      cards, loans, store cards, catalogues, buy now pay later,
                      overdrafts, payday loans, doorstep loans, council tax, HMRC,
                      overpayments of benefits, UC advance payments, rent arrears
                    </p>
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              <Accordion.Item className="accordion-item mb-3" eventKey="2">
                <Accordion.Header className="accordion-header" id="headingThree">
                  {" "}
                  How Long Does It Take?{" "}
                </Accordion.Header>
                <Accordion.Collapse
                  id="collapseThree"
                  eventKey="2"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <Accordion.Body className="accordion-body">
                    <p>
                      Our friendly debt experts work quickly to get an idea of your
                      situation and will go through all options available based on
                      your circumstances. If you are happy with the solution and wish
                      to proceed, then we can work on stopping contact from your
                      creditors.
                    </p>
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              <Accordion.Item className="accordion-item mb-3" eventKey="3">
                <Accordion.Header className="accordion-header" id="four">
                  {" "}
                  Do I Have to Talk To Someone?{" "}
                </Accordion.Header>
                <Accordion.Collapse
                  id="collapseFour"
                  eventKey="3"
                  className="accordion-collapse collapse"
                  aria-labelledby="four"
                  data-bs-parent="#accordionExample"
                >
                  <Accordion.Body className="accordion-body">
                    <p>
                      We can collate information online such as the short initial
                      assessment we have, we can also initially discuss via WhatsApp,
                      SMS, email. However, it is mandatory and part of our regulatory
                      obligations that we speak to you over the phone when discussing
                      options to ensure you properly understand everything about them
                      allowing you to make an informed decision. All our conversations
                      are completely confidential and our team are extremely
                      friendly. In most circumstances we can
                      start the IVA application process the same day.
                    </p>
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
            </div>
          </div>
        </Accordion>

        <TyFooter />

        {/* jQuery */}
        {/* MODAL  */}
        {/* MODAL 1 */}
        <div
          className={`modal fade ${state.showModal1} show`}
          id="pop1"
        >
          <div className="modal-dialog  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => ModalClose(e, "modal_1")}
                />
              </div>
              <div className="modal-body ">
                <div className="pop-text-contents">
                  <h2>What Is Bankruptcy</h2>
                  <p>
                    Bankruptcy is a formal insolvency procedure that may suitable if
                    you are unable to pay your debts.
                  </p>
                  <p>
                    You can either make an application yourself or alternatively a
                    creditor can apply to make you bankrupt. You must owe at least
                    £5,000 for a creditor to make you bankrupt.
                  </p>
                  <h2>What Are The Benefits Of Bankruptcy?</h2>
                  <p>
                    {" "}
                    <strong> Duration:</strong> Bankruptcy often lasts for only 12
                    months in total. <br />
                    <strong> Legal Protection:</strong> Once approved, creditors can
                    no longer proceed with legal action against you.
                    <br />
                    <strong> Peace of Mind:</strong> Creditors are no longer able to
                    contact you regarding your debts. <br />
                    <strong> Interest &amp; Charges: </strong>Interest and charges are
                    frozen once the bankruptcy application has been approved.
                  </p>
                  <h2>What Are The Considerations Of Bankruptcy?</h2>
                  <p>
                    <strong>Assets:</strong> If you own a home or possessions of
                    significant value, these items could be sold to help repay your
                    debts. <br />
                    <strong>Fees: </strong> There is an application fee of £680,
                    although this can be paid in instalments. <br />
                    <strong>Payments:</strong> You could be asked to make payments
                    towards your debts for a period of up to three years. <br />
                    <strong>Credit Rating:</strong> Bankruptcy will be recorded and
                    have a negative impact on your credit file for a period of six
                    years. <br />
                    <strong>Public Register:</strong> Your personal details will be
                    recorded on the Insolvency Register which is a public record.{" "}
                    <br />
                    <strong>Employment:</strong> Bankruptcy may impact upon your
                    employment; therefore, you should always check the terms of your
                    employment contract before making an application. You are also
                    unable to be a company director unless you have permission of the
                    court.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL 2 */}
        <div
          className={`modal fade ${state.showModal2} show`}
          id="pop2"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => ModalClose(e, "modal_2")}
                />
              </div>
              <div className="modal-body ">
                <div className="pop-text-contents">
                  <h2>What Is The Breathing Space Scheme?</h2>
                  <p>
                    The <i>Debt Respite Scheme</i> (Breathing Space) can assist in
                    providing you with some much-needed breathing space from your
                    creditors.
                  </p>
                  <p>
                    {" "}
                    If you are struggling with interest, charges and pending legal
                    action, Breathing Space can give you legal protection for up to 60
                    days to allow you to get back on your feet, or to get a
                    longer-term solution in place.
                  </p>
                  <h2>What Are The Benefits Of The Breathing Space Scheme?</h2>
                  <p>
                    {" "}
                    <strong>Interest &amp; Charges: </strong> No additional interest
                    or charges to be added during the moratorium period.
                    <br />
                    <strong>Legal Action:</strong> No legal action can be taken
                    against you whilst the Breathing Space is in place.
                    <br />
                    <strong>Peace of Mind: </strong> Creditors are unable to contact
                    you giving you peace of mind.
                  </p>
                  <h2>What Are The Considerations Of The Breathing Space Scheme?</h2>
                  <p>
                    {" "}
                    <strong>Payments: </strong> You are still required to make
                    payments to your creditors during the 60-day period if you can
                    afford to do so.
                    <br />
                    <strong>Short Term: </strong>The Breathing Space Scheme is
                    designed to relieve financial pressure but is not a long-term
                    solution to financial difficulties.
                    <br />
                    <strong>Insolvency: </strong> You cannot be subject to a formal
                    insolvency procedure such as a Debt Relief Order, Bankruptcy or an
                    Individual Voluntary Arrangement. <br />
                    <strong>Previous Applications: </strong> You must not have been in
                    a Breathing Space Scheme in the previous 12 months. <br />
                    <strong> Qualifying Debts: </strong> Some debts are not eligible
                    for the scheme such as child maintenance payments, student loans
                    and court fines.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL 3 */}
        <div
          className={`modal fade ${state.showModal3} show`}
          id="pop3"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => ModalClose(e, "modal_3")}
                />
              </div>
              <div className="modal-body ">
                <div className="pop-text-contents">
                  <h2> What Is A Consolidation Loan?</h2>
                  <p>
                    {" "}
                    A consolidated loan is one which combines all your unsecured debts
                    into a single monthly repayment. The loan is used to pay off your
                    debts, leaving one monthly repayment, rather than making multiple
                    payments to creditors.
                  </p>
                  <h2>What Are The Benefits Of A Consolidation Loan?</h2>
                  <p>
                    <strong>Manageable:</strong> Consolidating your debts into one
                    affordable monthly repayment can make your finances much easier to
                    manage. <br />
                    <strong>Reduced Interest: </strong> The interest rate may be lower
                    than what was being paid to the debts that are due to be repaid
                    with the loan. <br />
                    <strong>Affordable: </strong> The loan could result in lowering
                    your monthly repayments.
                  </p>
                  <h2>What Are The Considerations Of A Consolidation Loan?</h2>
                  <p>
                    <strong>Approval: </strong> There is no guarantee that the
                    application will be approved and in some cases a guarantor may be
                    needed. <br />
                    <strong>Missed Payments: </strong>If you fall behind with your
                    repayments, a lender can take legal action against you to recover
                    the debt, which will have a negative impact on your credit rating.{" "}
                    <br />
                    <strong> Timescale: </strong> The repayments can be spread over a
                    much longer term, which may mean you pay back more in total.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL 4*/}
        <div
          className={`modal fade ${state.showModal4} show`}
          id="pop4"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => ModalClose(e, "modal_4")}
                />
              </div>
              <div className="modal-body ">
                <div className="pop-text-contents">
                  <h2> What Is A Debt Management Plan?</h2>
                  <p>
                    {" "}
                    A debt management plan is an informal agreement that can be used
                    to help people pay back their debts at an affordable rate.
                  </p>
                  <p>
                    Suitable for people who are struggling to meet the monthly
                    repayments that were originally agreed with their creditors.
                  </p>
                  <h2> What Are The Benefits Of A Debt Management Plan?</h2>
                  <p>
                    <strong>Affordable:</strong> One affordable monthly repayment
                    which is then distributed between each of your creditors. <br />
                    <strong>Peace of mind: </strong> Most contact from creditors will
                    be dealt with by the debt management company on your behalf.{" "}
                    <br />
                    <strong>Interest &amp; Charges:</strong> Creditors may agree to
                    reduce or freeze interest and charges, although this is not
                    guaranteed.
                    <strong> Free Service: </strong> Debt charities offer a Debt
                    Management Plan free of charge. Find out more.
                  </p>
                  <h2>What Are The Considerations Of A Debt Management Plan?</h2>
                  <p>
                    <strong>Timescales: </strong> Paying less each month means it may
                    take longer to clear your debts in full. <br />
                    <strong>Fees:</strong> A debt management company may charge fees
                    for their services.
                    <br />
                    <strong> Priority Bills: </strong> Such as rent, council tax and
                    utility bills are unlikely to be included in the plan. <br />
                    <strong>Credit rating:</strong> A Debt Management Plan is likely
                    to have a negative impact on your credit rating, making it more
                    difficult to obtain credit in the future. <br />
                    <strong> No Legal Protection:</strong> Creditor can choose to
                    continue with their debt collection process, which can lead to
                    further action such as a default or County Court Judgment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL 5*/}
        <div
          className={`modal fade ${state.showModal5} show`}
          id="pop5"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => ModalClose(e, "modal_5")}
                />
              </div>
              <div className="modal-body ">
                <div className="pop-text-contents">
                  <h2> What Is A Debt Relief Order (DRO)?</h2>
                  <p>
                    {" "}
                    A Debt Relief Order is a formal insolvency procedure, which is
                    only available to people who meet the following criteria:
                  </p>
                  <ul className="ps-3">
                    <li>You reside in England, Wales or Northern Ireland.</li>
                    <li>
                      Your debts must not exceed £30,000 (£20,000 for residents of
                      Northern Ireland).
                    </li>
                    <li>
                      You have a disposable income of £75 or less each month (£50 or
                      less for residents of Northern Ireland).
                    </li>
                    <li>You are not a homeowner.</li>
                    <li>
                      Your assets are worth less than £2,000 in total (£1,000 in
                      Northern Ireland).
                    </li>
                    <li>
                      Your vehicle is worth less than £2,000 (£1,000 in Northern
                      Ireland).
                    </li>
                    <li>
                      It has been at least six years since your last application for a
                      Debt Relief Order.
                    </li>
                    <li>
                      You are not subject to another insolvency procedure such as an
                      <span>&nbsp;</span>Individual Voluntary Arrangement
                      <span>&nbsp;</span>or<span>&nbsp;</span>Bankruptcy.
                    </li>
                  </ul>
                  <h2>What Are The Benefits Of A Debt Relief Order?</h2>
                  <p>
                    <strong>Duration: </strong> Lasts for 12 months in total at the
                    end of which your debts are written-off. <br />
                    <strong>Legal Protection: </strong> Creditors can no longer
                    proceed with legal action once the Debt Relief Order has been
                    approved. <br />
                    <strong>Payments: </strong> You are not required to make any
                    monthly repayments. <br />
                    <strong>Interest &amp; Charges:</strong> Interest and charges are
                    frozen once the Debt Relief Order is approved.
                  </p>
                  <h2>What Are The Considerations Of A Debt Relief Order?</h2>
                  <p>
                    <strong>Fees:</strong>There is a one-off fee of £90 to process
                    your application through an Approved Intermediary.
                    <br />
                    <strong> Credit Rating: </strong> The Debt Relief Order will be
                    recorded and have a negative impact on your credit file for a
                    period of six years. <br />
                    <strong>Public Register: </strong> Your personal details will be
                    recorded on the Insolvency Register which is a public record.{" "}
                    <br />
                    <strong>Change in Circumstances: </strong> If your circumstances
                    change during the 12-month period and you no longer meet the
                    eligibility criteria, it is likely that the application will be
                    cancelled, and you will need to make alternative arrangements to
                    pay your debts. <br />
                    <strong>Employment:</strong> A Debt Relief Order may impact upon
                    your employment; therefore, you should always check the terms of
                    your employment contract before making an application. You are
                    also unable to be a company director unless you have permission of
                    the court.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL 6*/}
        <div
          className={`modal fade ${state.showModal6} show`}
          id="pop6"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => ModalClose(e, "modal_6")}
                />
              </div>
              <div className="modal-body ">
                <div className="pop-text-contents">
                  <h2> What Is An Individual Voluntary Arrangement (IVA)?</h2>
                  <p>
                    {" "}
                    A formal insolvency procedure in which a legally binding agreement
                    between you and your creditors is drawn up to pay back your debts
                    at an affordable rate.
                  </p>
                  <p>
                    The Arrangement will typically last for five or six years, with
                    any remaining debt at the end of this period being written off.
                  </p>
                  <h2>What Are The Benefits Of An IVA?</h2>
                  <p>
                    <strong>Assets Protected: </strong> If you are a homeowner, your
                    home will be protected and not put at risk of being sold. <br />
                    <strong> Affordable Payment:</strong> One affordable, monthly
                    repayment which makes your finances much easier to manage. <br />
                    <strong>Timeframe:</strong> A set time period (usually five or six
                    years), giving you a countdown to becoming debt free.
                    <br />
                    <strong>Legal Protection:</strong> Once approved, creditors can no
                    longer proceed with legal action against you.
                    <br />
                    <strong>Peace of Mind:</strong> Creditors are no longer able to
                    contact you regarding your debts.
                    <strong>Interest &amp; Charges: </strong>Interest and charges are
                    frozen once your proposal has been approved.
                  </p>
                  <h2>What Are The Considerations Of An IVA?</h2>
                  <p>
                    <strong>Fees:</strong> There are fees payable for the work
                    completed in setting up and managing your IVA, however these fees
                    are taken out of your monthly repayments and are NOT in addition
                    to the payments made to your creditors.
                    <br />
                    <strong> Credit Rating: </strong> An IVA will be recorded and have
                    a negative impact on your credit file for a period of six years.{" "}
                    <br />
                    <strong>Public Register: </strong> Your personal details will be
                    recorded on the Insolvency Register which is a public record.{" "}
                    <br />
                    <strong>Equity Release:</strong> If you are a homeowner, you may
                    be required to release equity from your home in order to help
                    repay your debts. If you are unable to remortgage, the IVA may be
                    extended for 12 months instead. <br />
                    <strong>Windfalls:</strong> If you receive a windfall such as
                    inheritance or a lottery win, you are required to pay a proportion
                    of this into the IVA. <br />
                    <strong> Approval:</strong> Creditors may decide not to approve
                    the proposed Arrangement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* MODAL 7*/}
        <div
          className={`modal fade ${state.showModal7} show`}
          id="pop7"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => ModalClose(e, "modal_7")}
                />
              </div>
              <div className="modal-body ">
                <div className="pop-text-contents">
                  <h2> What Is A Remortgage?</h2>
                  <p>
                    {" "}
                    A remortgage consists of taking out a loan secured against your
                    property.
                  </p>
                  <p>
                    The loan is used to consolidate your existing debts and save on
                    high interest rates.
                  </p>
                  <p>
                    The secured loan can usually be spread over a much longer term,
                    which can help to reduce your monthly repayments.
                  </p>
                  <h2>What Are The Benefits Of Remortgage?</h2>
                  <p>
                    <strong>Affordable Payments: </strong> One affordable, monthly
                    repayment making your finances much more manageable. <br />
                    <strong> Reduced Interest:</strong> Interest rates on a secured
                    loan are typically much lower than those of an unsecured loan,
                    although the debt may be spread over a much longer period meaning
                    that you pay back more overall. <br />
                    <strong>Debts Repaid: </strong> If you can release sufficient
                    equity from your property, the loan could allow you to repay your
                    unsecured debts in full.
                    <br />
                  </p>
                  <h2>What Are The Considerations Of Remortgage?</h2>
                  <p>
                    <strong> Advice: </strong> You should always seek independent
                    mortgage advice before pursuing this solution.
                    <br />
                    <strong> Failure:</strong> If you to keep up with your monthly
                    repayments, the lender could take legal action against you which
                    could result in your home being repossessed. <br />
                    <strong>Fees:</strong> There can be significant fees associated
                    with remortgaging such as valuations, product fees, legal costs
                    and broker fees.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END MODAL */}
      <div className={state.showDiv}></div>
    </>

  )
}
export default MRD_V3;