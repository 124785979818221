import { useState } from "react";
import { BudsApi } from "../../api/BudsApi";
import * as EnvConstants from "../../Constants/EnvConstants";

export const useBudsAuthorisationGatewayUrl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const budsAuthGatewayUrlResponse = async (
    uuid,
    customerId,
    redirectUrl,
    is_test,
    source,
    visitorId,
  ) => {
    setIsLoading(true);
    const response = await BudsApi.post("v1/lender-authorisation-gateway-url", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "UUID":uuid,
      "Environment": EnvConstants.AppEnv,
      "customer_id":customerId,
      "redirect_url":redirectUrl,
      "providers":[],
      "is_test":is_test,
      "source":source,
      "visitor_id":visitorId,
    })
    setIsLoading(false);
    return response;
  };
  return { budsAuthGatewayUrlResponse, isLoading };
}	