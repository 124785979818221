import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import Telephone from "../../../UI/Telephone";
import Email from "../../../UI/Email";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";

const ContactDetails = ({
  validation,
  validationMsg,
  phoneValidCheck
}) => {
  return (
    <>
      {" "}
      <div className="form-group mb-3">
        <Email
          className="form-control secured-field"
          type="text"
          placeholder="Email Address"
          name="txtEmail"
          id="txtEmail"
          validation={validation({
            required: "Please Enter Email Address",
            minLength: {
              value: 3,
              message: "Please Enter Valid Email Address",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid Email Address",
            },
          })}
        />
        {validationMsg.txtEmail && (
          <div className="w-100 bg-danger text-white"> 
            <span>
              {validationMsg.txtEmail.message}
            </span>
          </div>
        )}

      </div>
      <div className="form-group mb-3">
        <Telephone
          className="form-control secured-field"
          name="txtPhone"
          id="txtPhone"
          placeholder="Telephone"
          describedby="basic-addon2"
          defaultValue=""
          // onKeyUp = {checkPhoneValid}
          onBlur={phoneValidCheck}
          onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
          maxlength="11"
          validation={validation({
            pattern: {
              value: /^[0-9]*$/i,
              message: "Please Enter Valid Phone Number",
            },
            required: "Please Enter Phone Number",
          })}
        />
        {validationMsg.txtPhone && (
          <div className="w-100 bg-danger text-white"> 
            <span>
              {validationMsg.txtPhone.message}
            </span>
          </div>
        )}

      </div>

    </>
  );
};
export default ContactDetails;
