import React from "react";
import loader from "../../../../assets/img/loader.png";
import RevolutionMoneyLogo from "../../../../assets/img/Revolution-Money-Logo.png";
const FollowupLoader = (props) =>{
    return(
        <>
     <div className={`openbank top-main-section ld-bg overlay  ${props.show}`}>    
    {/* ========= Header Area Start ========= */}
    <header className="headbl">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 logo text-center">
            <a data-id="SITE_lOGO">
              <img
                title=""
                alt="Logo Image"
                src={RevolutionMoneyLogo}
              />
            </a>
          </div>
        </div>
      </div>
    </header>
    {/* ========= Header Area End ========= */}
    <div className="container    text-center ">
      <div className="analys">
        <div>
        <div className="mb-3">
          <div className="loader-box  ">
            <img className="loader" src={loader} alt="" />
          </div>
          <h4>Analysing your Application.... </h4>
        </div>
        </div>
      </div>
    </div>
  </div>
    </>
    )
}
export default FollowupLoader;