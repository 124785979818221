import React from 'react'
import TextField from '../Components/UI/TextField'
import { NumberValidation } from './NumberValidation';

const DateOfBirthText = ({ validation, validationMsg, trigger }) => {
    const handleDayChange = (e) => {
        trigger("DobDay");
    };
    const handleMonthChange = (e) => {
        trigger("DobMonth");
      };
    
      const handleYearChange = (e) => {
        trigger("DobYear");
      };
    return (
        <>
            <div className="row">
                <div className="col-sm-4 pt-2 text-lg-end text-md-end text-sm-end text-start">
                    Date of Birth
                </div>
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="mb-3">
                                <TextField
                                    type="tel"
                                    className="m-0 form-control"
                                    placeholder="DD*"
                                    name="DobDay"
                                    id="DobDay"
                                    onKeyPress={NumberValidation}
                                    onBlur={handleDayChange}
                                    onPaste={(e) =>
                                        /[^0-9]/i.test(e.clipboardData.getData("Text")) &&
                                        e.preventDefault()
                                    }
                                    maxlength="2"
                                    validation={validation({
                                        required: "Please Enter a Valid Day",
                                        maxLength: {
                                            value: 2,
                                            message: "Please Enter a Valid Day",
                                        },
                                        pattern: {
                                            value: /^[0-9]*$/i,
                                            message: "Please Enter a Valid Day",
                                        },
                                        min: {
                                            value: 1,
                                            message: "Please Enter a Valid Day",
                                        },
                                        max: {
                                            value: 31,
                                            message: "Please Enter a Valid Day",
                                        },
                                    })}
                                />
                                {validationMsg.DobDay && (
                                    <span className="error">{validationMsg.DobDay.message}</span>
                                )}
                                {/* <input type="text" className="form-control" /> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mb-3">
                                <TextField
                                    type="tel"
                                    className="form-control dob"
                                    placeholder="MM*"
                                    name="DobMonth"
                                    id="DobMonth"
                                    onKeyPress={NumberValidation}
                                    onBlur={handleMonthChange}
                                    onPaste={(e) =>
                                        /[^0-9]/i.test(e.clipboardData.getData("Text")) &&
                                        e.preventDefault()
                                    }
                                    maxlength="2"
                                    validation={validation({
                                        required: "Please Enter a Valid Month",
                                        maxLength: {
                                            value: 2,
                                            message: "Please Enter a Valid Month",
                                        },
                                        pattern: {
                                            value: /^[0-9]*$/i,
                                            message: "Please Enter a Valid Month",
                                        },
                                        min: {
                                            value: 1,
                                            message: "Please Enter a Valid Month",
                                        },
                                        max: {
                                            value: 12,
                                            message: "Please Enter a Valid Month",
                                        },
                                    })}
                                />
                                {validationMsg.DobMonth && (
                                    <span className="error">{validationMsg.DobMonth.message}</span>
                                )}
                                {/* <input type="text" className="form-control" /> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mb-3">
                            <TextField
              type="tel"
              className="form-control dob"
              placeholder="YYYY*"
              name="DobYear"
              id="DobYear"
              onKeyPress={NumberValidation}
              onBlur={handleYearChange}
              onPaste={(e) =>
                /[^0-9]/i.test(e.clipboardData.getData("Text")) &&
                e.preventDefault()
              }
              maxlength="4"
              validation={validation({
                required: "Please Enter a Valid Year",
                maxLength: {
                  value: 4,
                  message: "Please Enter a Valid Year",
                },
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter a Valid Year",
                },
                min: {
                  value: 1900,
                  message: "Please Enter a Valid Year",
                },
                max: {
                  value: new Date().getFullYear() - 20,
                  message: "Please Enter a Valid Year",
                },
              })}
            />
            {validationMsg.DobYear && (
              <span className="error">{validationMsg.DobYear.message}</span>
            )}
                                {/* <input type="text" className="form-control" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DateOfBirthText