import { useState } from "react";
import { BudsApi } from "../../api/BudsApi";
import * as EnvConstants from "../../Constants/EnvConstants";

export const useBudsVisitors = () => {
  const [isLoading, setIsLoading] = useState(false);
  const visitorsResponse = async (
    uuid,
    source,
    triggerType,
    token,
    pixelId
  ) => {
    setIsLoading(true);
    const response = await BudsApi.post("v1/visitors", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID":uuid,
      "source":source,
      "token": token,
      "pixelId": pixelId,
      "triggerType": triggerType
    })
    setIsLoading(false);
    return response;
  };
  return { visitorsResponse, isLoading };
}
