const questinnare1 = {
        questionId:9,
        question:"What is your outstanding debt?",
      //  questionSubText:" Don't worry. We can help regardless of your debt level.",
        subTextPos:"bottom",
        answers:[
            {
                label:"£0 - £5,999",
                value:"32",
            },
            {
                label:"£6,000 - £10,000",
                value:"33"
            },
            {
                label:"£10,000 - £25,000",
                value:"34",
            },
            {
                label:"£25,000+",
                value:"35"
            }
        ]
}
const questinnare2 = {
    questionId:10,
    question:"How many people do you owe?",
    questionSubText:null,
    subTextPos:null,
    answers:[
        {
            label:"1",
            value:"36",
        },
        {
            label:"2",
            value:"37"
        },
        {
            label:"3",
            value:"38",
        },
        {
            label:"4",
            value:"39"
        },
        {
            label:"5",
            value:"40"
        },
        {
            label:"6+",
            value:"41"
        }
    ]
}
const questinnare3 = {
    questionId:11,
    question:"What is your current employment status?",
    questionSubText:null,
    subTextPos:null,
    answers:[
        {
            label:"Benefits",
            value:"42",
        },
        {
            label:"Employed",
            value:"43"
        },
        {
            label:"Self Employed",
            value:"44",
        },
        {
            label:"Other",
            value:"45"
        }
    ]
}
const questinnare4 = {
    questionId:12,
    question:"What is your current monthly income?",
    questionSubText:null,
    subTextPos:null,
    answers:[
        {
            label:"£0 - £700",
            value:"46",
        },
        {
            label:"£700 - £1,000",
            value:"47"
        },
        {
            label:"£1,000 - £1,500",
            value:"48",
        },
        {
            label:"£1,500+",
            value:"49"
        }
    ]
}
const questinnare5 = {
    questionId:13,
    question:"What is your residential status?",
    questionSubText:null,
    subTextPos:null,
    answers:[
        {
            label:"Homeowner",
            value:"50",
        },
        {
            label:"Live with parents",
            value:"51"
        },
        {
            label:"Renting",
            value:"52",
        },
        {
            label:"Other",
            value:"53"
        }
    ]
}
const questinnare6 =[ {
    questionId:14,
    question1:"For consumers with less than £6,000 of debt, creditors will not offer any debt write-off and options are limited.",
    questionSubText1:"If you add up all your store cards, credit cards, loans, payday loans, old bills like mobile phone - water, gas and electric, HMRC debt or old car finance....",
    subTextPos:"bottom",
    question2:"Based on your debt of question_Content split between OWED_ONES creditors - we have identified 3 plans to tackle your debt"  ,
    questionSubText2:"We DO NOT offer loans or credit cards - we only assist by providing long-term debt solutions NOT further lending.",
    answers:[
       {

       }
    ]
}
]
const questinnare7 = {
    questionId:15,
    question:"What is your email address?",
    questionSubText:"So we can send you some more information to read.",
    subTextPos:"bottom",
    answers:[
       {

       }
    ]
}
const questinnare8 = {
    questionId:16,
    question:"What telephone number can we contact you on?",
    questionSubText:null,
    subTextPos:null,
    answers:[
       {

       }
    ]
}
const questinnare9 = {
    questionId:17,
    question:"And who are we speaking to when we call ?",
    questionSubText:null,
    subTextPos:null,
    answers:[
       {

       }
    ]
}

export {
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4,
    questinnare5,
    questinnare6,
    questinnare7,
    questinnare8,
    questinnare9
}