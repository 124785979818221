import { useState } from "react";
import { BudsApi } from "../../api/BudsApi";
import * as EnvConstants from "../../Constants/EnvConstants";

export const useBudsCreateCustomer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const budsCreateCustomerResponse = async (
    uuid,
    is_test,
    source,
    visitorId,
    customer_details
  ) => {
    setIsLoading(true);
    const response = await BudsApi.post("v1/customer", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "UUID":uuid,
      "Environment": EnvConstants.AppEnv,
      "is_test":is_test,
      "source":source,
      "visitor_id":visitorId,
      "customer_context": {
        "type": "personal",
        "region": "GB",
        "locale": "en-GB"
      },
      "customer_details": {
        "salutation": customer_details.salutation,
        "firstname": customer_details.firstname,
        "lastname": customer_details.lastname,
        "email": customer_details.email,
        "phonenumber": customer_details.phonenumber
      }
    })
    setIsLoading(false);
    return response;
  };
  return { budsCreateCustomerResponse, isLoading };
}
