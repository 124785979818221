import React from "react";
import Telephone from "../../../UI/Telephone";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";

const QuestionnairePhone = (props) => {
  const { validation, validationMsg, getValues } = props;

  const { phoneValidation } = usePhoneValidation();
  const question_id = props.questionnaire.questionId;
  const radioName = "question_" + question_id;

  const phoneValidate = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      if (getPhoneValidation.data.status_code === 0) {
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
  
        return 1;
      }
    }
  };

  const validateSlide = async (e) => {
    
    e.preventDefault();
    const values = props.getValues();
   
    var phoneVal;
    phoneVal = await props.trigger("txtPhone");
  
    
    if( phoneVal){
      if ((await phoneValidate()) === 1) {
        props.slideChange(e, radioName);
      }
    }

  }

  return (
    <div id="slide8" className={`slider8 fade-in ${props.showQuestionSlide}`}>
      <p className="text-center counter-text mb-5"></p>
      <div className="forms shadow mt-3">
        <div className="down-arrow shake-vertical">
          <img src="dist/img/down-arrow-circle.png" alt="" />
        </div>
        <div className="col-lg-12 row custom m-0 forminnercontent text-center">
          <h1 className="title">{props.questionnaire.question}</h1>
          <div className="mt-3">
            <div className="form-group mb-1">
              <Telephone
                className="form-control secured-field"
                name="txtPhone"
                id="txtPhone"
                placeholder="Enter your mobile here eg 0773XXXXXXX"
                // onKeyUp = {checkPhoneValid}
                onBlur={phoneValidate}
                onKeyPress={(e) => !/[0-9]+$/.test(e.key) && e.preventDefault()}
                maxlength="11"
                validation={validation({
                  pattern: {
                    value: /^[0-9]*$/i,
                    message: "Please Enter Valid Phone Number",
                  },
                  required: "Please Enter Phone Number",
                })}
              />
              {validationMsg.txtPhone && (
                <div className="w-100 bg-danger text-white">
                  <span>{validationMsg.txtPhone.message}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Back */}
      <div className="container">
        <div className="row">
        <div
          className="col-lg-6 col-sm-6 col-12 order-sm-first order-last btn-more  back-btn  mt-4 "
          id={`back${question_id}`}
          name={`back${question_id}`}
          onClick={(e) => {
            props.previousSlide(e);
          }}
        >
          Back
        </div>
        <div
          className="col-lg-6 col-sm-6 col-12 btn-more next-btn mt-lg-4 mt-md-4 mt-sm-4 mt-4 next006"
          id={`next${question_id}`}
          name={`next${question_id}`}
          onClick={validateSlide}
        >
          <span> Next</span>
        </div>
      </div>
      </div>
    </div>
  );
};
export default QuestionnairePhone;
