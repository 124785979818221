import { BudsApi } from "../../api/BudsApi";
import * as EnvConstants from "../../Constants/EnvConstants";

export const useBudsCallbackGateway = () => {

    const budsCallbackGatewayResponse = async(uuid,source,status,customer_id,task_id,is_test,visitorId) => {
        const response = await BudsApi.post("v1/callback-gateway-url",{
            "ProjectCode": EnvConstants.AppAdtopiaUPID,
            "Environment": EnvConstants.AppEnv,
            "UUID": uuid,
            "source": source,
            "status": status,
            "is_test": is_test,
            "customer_id": customer_id,
            "connection_task_id": task_id,
            "visitor_id" :visitorId
        });
        return response;
    }

    return { budsCallbackGatewayResponse }
}