import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<>
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<ul className="ft-menu">
									<li>
										<Link to="privacy"> Privacy Policy </Link>
									</li>
									<li>
										<Link to="complaints_policy"> Complaints Policy</Link>
									</li>
								</ul>
								<div className="col-lg-12 ">
									<p style={{ textAlign: "justify" }}> Kevin Duffy is licensed to act as an Insolvency Practitioner in the UK by the Insolvency Practitioners Association. Revolution Money is a trading style of ARK Insolvency. ARK Insolvency Ltd (14399020) is registered in England and Wales. Registered office: Universal Square, Devonshire Street North, Manchester, M12 6JH. Data Protection Registration Number: ZB401693 There is no obligation to proceed with our recommendations. All financial solutions are subject to eligibility and acceptance and depending on your chosen solution, fees may be charged by the service provider – if this is the case Ark Insolvency will make you aware of any fees before you proceed. </p>
								</div>
							</div>
						</div>
					</div>
					<hr className="hr-footer-style" />
					<div className="row">
						<div className="col-md-12 text-center">
							<p className="mt-3 mb-4 text-white">Copyright <span id="setFooterYear">2023</span> @ Revolution Money. All Rights Reserved. </p>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}
export default Footer;