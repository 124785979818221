import React from 'react'
import PrivacyHeader from '../Common/PrivacyHeader'
import TyFooter from '../Common/TyFooter'

const CookiePolicy = () => {
  return (
    <>
                 <div className="privacy">
    <div className="top-main-section">
                <PrivacyHeader />
            </div>
  <section className="pt-5 pb-2">
    <div className="container">
      <h1>Cookie Policy</h1>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="inner_text py-2 py-md-5">
        <p>
          {" "}
          This site uses cookies – small text files that are placed on your
          machine to help the site provide a better user experience. In general,
          cookies are used to retain user preferences, store information for
          things like shopping baskets, and provide anonymised tracking data to
          third party applications like Google Analytics. As a rule, cookies
          will make your browsing experience better. However, you may prefer to
          disable cookies on this site and on others. The most effective way to
          do this is to disable cookies in your browser. We suggest consulting
          the Help section of your browser or taking a look at{" "}
          <a href="https://www.aboutcookies.org/">
            the About Cookies website
          </a>{" "}
          which offers guidance for all modern browsers.
        </p>
      </div>
    </div>
  </section>
  <TyFooter />

    </div>
    </>
  )
}

export default CookiePolicy