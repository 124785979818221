import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Header from "../../Includes/Layouts/Common/Header";
import Footer from "../../Includes/Layouts/Common/Footer";
import pointing from "../../../assets/img/pointing.webp";
import img8556 from "../../../assets/img/img8556.png";
import supporimg8889 from "../../../assets/img/supporimg8889.png";
import feefo_logo_largeb from "../../../assets/img/feefo_logo_largeb.webp";
import debt from "../../../assets/img/debt.png";
import star87 from "../../../assets/img/star-87.png";
import FormSplitMRD_V1 from "../../Forms/FormSplitMRD_V1";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper.min.css";
import Feefo from "../Feefo";

const MRD_V1 = () => {
	const [slideCount, setSlideCount] = useState(3);
	const [showModal1, setShowModal1] = useState('hide');
	const [showModal2, setShowModal2] = useState('hide');
	const [showModal3, setShowModal3] = useState('hide');
	const [showModal4, setShowModal4] = useState('hide');
	const [showModal5, setShowModal5] = useState('hide');
	const [showModal6, setShowModal6] = useState('hide');
	const [showModal7, setShowModal7] = useState('hide');
	let width = window.innerWidth;
	useEffect(() => {
		if (width < 768) {
			setSlideCount(1);
		} else {
			setSlideCount(3);
		}
	}, [width]);
	// const handleshow =()=>{
	// 	console.log(56465);

	// }
	return (
		<div className="MRD_V1">
			<AdtopiaLoadLP pageType="LP" splitName="MRD_V1" />
			<div className="top-main-section">
				<Header />
				<FormSplitMRD_V1 />
				
				<div className="man-img">
					<img src={pointing} alt="" />
				</div>
			</div>
			<section className=" middle-sec-wrap  mt-0 clearfix">
				<div className="container">
					<div className="middle-sec">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-sm-12 text-center text-white">
								<div className="box my-5">
									<div className="mb-3">
										<img src={img8556} alt="" />
									</div>
									<h1>Complete the debt assessment.</h1>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 text-center text-white">
								<div className="box my-5">
									<div className="mb-3">
										<img src={img8556} alt="" />
									</div>
									<h1>Discuss your options with one of our friendly debt experts.</h1>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 text-center text-white">
								<div className="box my-5">
									<div className="mb-3">
										<img src={img8556} alt="" />
									</div>
									<h1>Start your journey to making new happy memories.</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="assessment-wrap my-5">
				<div className="container">
					<div className="inner pb-lg-0 pb-md-0 pb-sm-5">
						<div className="row">
							<div className="col-lg-5 text-lg-end text-md-center text-sm-center  mt-3">
								<img src={supporimg8889} alt="" />
							</div>
							<div className="col-lg-6 text-center text-lg-start text-md-start text-sm-center">
								<h2 className="mb-4">Contact us today for an assessment of your financial circumstances.</h2>
								<div>
									<a href="" className="btn-gent-help">Get Debt help Now!</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container text-center">
					<div className="row">
						<div className="col-lg-8 offset-lg-2">
							<p>If you decide to proceed with an individual voluntary arrangement (IVA), fees will be taken from (not
								on top of) your monthly contributions.</p>
							<p>An individual voluntary arrangement (IVA) may not be suitable in all circumstances, fees apply, your
								credit rating may be affected. For more information, please contact one of our debt specialists or find
								out more below.</p>
						</div>
					</div>
				</div>
			</section>
			<Feefo/>
			{/* <section className="review-section mt-5">
				<div className="container">

					<div className="row mb-lg-5 mb-md-5 mb-sm-3 ">

						<div className="col-lg-5 col-md-6 col-sm-12">

							<div className="feefo-review d-flex justify-content-between">
								<img width=" " className="img-fluid me-2" src={feefo_logo_largeb} alt="" />

							</div>


						</div>
						<div className="col-lg-7 col-md-6 col-sm-12"><h1 className="mt-4 mt-md-2 mt-sm-2 fs-1">Customer <br /> Experience Rating</h1></div>


					</div>

					<Swiper
						spaceBetween={30}
						slidesPerView={slideCount}
						loop={true}
						className="mySwiper"
					>
						<div className="swiper InitSlider ">
							<div className="swiper-wrapper  " >
								<SwiperSlide className="swiper-slide">

									<div className="review-box  mb-2 bg-body-tertiary">
										<h3 className="mb-2">' Very helpful and friendly '</h3>
										<img className="starimg mb-2" src={star87} alt="" />
										<p>Very helpful and friendly. No judgements made by anyone. Communication could have been a bit quicker but overall service was really good.</p>
									</div>
								</SwiperSlide>

								<SwiperSlide className="swiper-slide">

									<div className="review-box  mb-2 bg-body-tertiary  ">
										<h3 className="mb-2">' Great service '</h3>
										<img className="starimg mb-2" src={star87} alt="" />
										<p>Very friendly and professional would recommend</p>
									</div>
								</SwiperSlide>

								<SwiperSlide className="swiper-slide">

									<div className="review-box  mb-2 bg-body-tertiary ">
										<h3 className="mb-2">' Initial process was smooth and helpful. '</h3>
										<img className="starimg mb-2" src={star87} alt="" />
										<p>Initial process was fine and great help In sourcing information on debts etc.

											Once with the actual IVA provider service is not too great.</p>
									</div>
								</SwiperSlide>
								<SwiperSlide className="swiper-slide">

									<div className="review-box  mb-2 bg-body-tertiary  ">
										<h3 className="mb-2">' Great service '</h3>
										<img className="starimg mb-2" src={star87} alt="" />
										<p>Great service provided. It was quick any easy. They helped me through every step and made me feel comfortable at all times</p>
									</div>
								</SwiperSlide>
							</div>
						</div>
					</Swiper>
				</div>
			</section>

			{/* <!-- Debt Solutions --> */}

			{/* <section className="review-section debt-section mt-0 pt-0">
				<div className="container">
					<h2 className="text-center mb-4 fs-1">  Debt Solutions </h2>

					<p className="text-center px-5">If you are struggling to repay your debts, please don’t worry, we’re here for you. No matter you situation there will be a solution for you, here we feature the most popular debt solutions, but in order for us to correctly identify the correct solution for your particular set of circumstances, we will need to talk to you.</p>

					<div className="row">

						<div className="col-lg-4 col-md-6 col-sm-12 order-lg-1 pt-3 pt-lg-0 pt-md-0 pt-sm-3">

							<ul className="list-points ">
								<li onClick={() => setShowModal1('show')}> <a data-bs-toggle="modal" data-bs-target="#pop1">Bankruptcy</a> </li>
								<li onClick={() => setShowModal2('show')}> <a data-bs-toggle="modal" data-bs-target="#pop2">Breathing space scheme</a> </li>
								<li onClick={() => setShowModal3('show')}> <a data-bs-toggle="modal" data-bs-target="#pop3">Consolidation loans</a> </li>
								<li onClick={() => setShowModal4('show')}> <a data-bs-toggle="modal" data-bs-target="#pop4"  >Debt management plan</a> </li>
							</ul>

						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 order-lg-3">
							<ul className="list-points text-lg-end text-md-end text-sm-start">
								<li onClick={() => setShowModal5('show')}> <a data-bs-toggle="modal" data-bs-target="#pop5" > Debt relief order</a> </li>
								<li onClick={() => setShowModal6('show')}> <a data-bs-toggle="modal" data-bs-target="#pop6" >Individual voluntary arrangement</a> </li>
								<li onClick={() => setShowModal7('show')}> <a data-bs-toggle="modal" data-bs-target="#pop7">Remortgage</a> </li>

							</ul>


						</div>
						<div className="col-lg-4 order-lg-2 text-center  Debt-img">
							<div>
								<img height="400" src={debt} alt="" />
							</div>
						</div>


					</div>




				</div>
			</section> */}
			{/* <!-- MODAL  -->


<!-- MODAL 1 --> */}
			<div className={`modal fade ${showModal1}`} id="pop1">
				<div className="modal-dialog  modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel"> </h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal1('hide')} ></button>
						</div>
						<div className="modal-body ">
							<div className="pop-text-contents">
								<h2>What Is Bankruptcy</h2>
								<p>Bankruptcy is a formal insolvency procedure that may suitable if you are unable to pay your debts.</p>
								<p>You can either make an application yourself or alternatively a creditor can apply to make you bankrupt. You must owe at least £5,000 for a creditor to make you bankrupt.</p>

								<h2>What Are The Benefits Of Bankruptcy?</h2>
								<p> <strong> Duration:</strong> Bankruptcy often lasts for only 12 months in total. <br />

									<strong> Legal Protection:</strong> Once approved, creditors can no longer proceed with legal action against you.<br />

									<strong>  Peace of Mind:</strong> Creditors are no longer able to contact you regarding your debts. <br />

									<strong>  Interest {'&'} Charges: </strong>Interest and charges are frozen once the bankruptcy application has been approved.</p>


								<h2>What Are The Considerations Of Bankruptcy?</h2>

								<p>
									<strong>Assets:</strong> If you own a home or possessions of significant value, these items could be sold to help repay your debts.  <br />

									<strong>Fees: </strong> There is an application fee of £680, although this can be paid in instalments. <br />

									<strong>Payments:</strong> You could be asked to make payments towards your debts for a period of up to three years. <br />

									<strong>Credit Rating:</strong> Bankruptcy will be recorded and have a negative impact on your credit file for a period of six years. <br />

									<strong>Public Register:</strong> Your personal details will be recorded on the Insolvency Register which is a public record. <br />

									<strong>Employment:</strong> Bankruptcy may impact upon your employment; therefore, you should always check the terms of your employment contract before making an application. You are also unable to be a company director unless you have permission of the court.
								</p>
							</div>

						</div>

					</div>
				</div>
			</div>
			{/* <!-- MODAL 2 --> */}

			<div className={`modal fade ${showModal2}`} id="pop2">
				<div className="modal-dialog  modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel"> </h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal2('hide')}></button>
						</div>
						<div className="modal-body ">
							<div className="pop-text-contents">
								<h2>What Is The Breathing Space Scheme?</h2>
								<p>The <i>Debt Respite Scheme</i> (Breathing Space) can assist in providing you with some much-needed breathing space from your creditors.</p>
								<p> If you are struggling with interest, charges and pending legal action, Breathing Space can give you legal protection for up to 60 days to allow you to get back on your feet, or to get a longer-term solution in place.</p>

								<h2>What Are The Benefits Of The Breathing Space Scheme?</h2>

								<p> <strong>Interest {'&'} Charges: </strong> No additional interest or charges to be added during the moratorium period.
									<br />
									<strong>Legal Action:</strong> No legal action can be taken against you whilst the Breathing Space is in place.
									<br />
									<strong>Peace of Mind: </strong> Creditors are unable to contact you giving you peace of mind.
								</p>
								<h2>What Are The Considerations Of The Breathing Space Scheme?</h2>
								<p> <strong>Payments: </strong> You are still required to make payments to your creditors during the 60-day period if you can afford to do so.
									<br />
									<strong>Short Term: </strong>The Breathing Space Scheme is designed to relieve financial pressure but is not a long-term solution to financial difficulties.

									<br />

									<strong>Insolvency: </strong>  You cannot be subject to a formal insolvency procedure such as a Debt Relief Order, Bankruptcy or an Individual Voluntary Arrangement.  <br />

									<strong>Previous Applications: </strong> You must not have been in a Breathing Space Scheme in the previous 12 months. <br />

									<strong> Qualifying Debts: </strong> Some debts are not eligible for the scheme such as child maintenance payments, student loans and court fines.
								</p>

							</div>

						</div>

					</div>
				</div>
			</div>
			{/* <!-- MODAL 3 --> */}

			<div className={`modal fade ${showModal3}`} id="pop3">
				<div className="modal-dialog  modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel"> </h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal3('hide')}></button>
						</div>
						<div className="modal-body ">
							<div className="pop-text-contents">
								<h2> What Is A Consolidation Loan?</h2>
								<p> A consolidated loan is one which combines all your unsecured debts into a single monthly repayment. The loan is used to pay off your debts, leaving one monthly repayment, rather than making multiple payments to creditors.</p>

								<h2>What Are The Benefits Of A Consolidation Loan?</h2>
								<p>
									<strong>Manageable:</strong>  Consolidating your debts into one affordable monthly repayment can make your finances much easier to manage.  <br />
									<strong>Reduced Interest: </strong> The interest rate may be lower than what was being paid to the debts that are due to be repaid with the loan. <br />
									<strong>Affordable: </strong> The loan could result in lowering your monthly repayments.
								</p>
								<h2>What Are The Considerations Of A Consolidation Loan?</h2>
								<p>

									<strong>Approval: </strong> There is no guarantee that the application will be approved and in some cases a guarantor may be needed. <br />
									<strong>Missed Payments: </strong>If you fall behind with your repayments, a lender can take legal action against you to recover the debt, which will have a negative impact on your credit rating. <br />
									<strong>            Timescale: </strong> The repayments can be spread over a much longer term, which may mean you pay back more in total.
								</p>


							</div>

						</div>

					</div>
				</div>
			</div>


			{/* <!-- MODAL 4--> */}

			<div className={`modal fade ${showModal4}`} id="pop4">
				<div className="modal-dialog  modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel"> </h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal4('hide')}></button>
						</div>
						<div className="modal-body ">
							<div className="pop-text-contents">
								<h2> What Is A Debt Management Plan?</h2>
								<p> A debt management plan is an informal agreement that can be used to help people pay back their debts at an affordable rate.</p>
								<p>Suitable for people who are struggling to meet the monthly repayments that were originally agreed with their creditors.</p>


								<h2> What Are The Benefits Of A Debt Management Plan?</h2>
								<p>
									<strong>Affordable:</strong>  One affordable monthly repayment which is then distributed between each of your creditors. <br />
									<strong>Peace of mind: </strong>  Most contact from creditors will be dealt with by the debt management company on your behalf. <br />
									<strong>Interest {'&'} Charges:</strong> Creditors may agree to reduce or freeze interest and charges, although this is not guaranteed.
									<strong> Free Service: </strong> Debt charities offer a Debt Management Plan free of charge. Find out more.

								</p>
								<h2>What Are The Considerations Of A Debt Management Plan?</h2>
								<p>

									<strong>Timescales: </strong> Paying less each month means it may take longer to clear your debts in full. <br />
									<strong>Fees:  </strong> A debt management company may charge fees for their services.<br />
									<strong>  Priority Bills: </strong> Such as rent, council tax and utility bills are unlikely to be included in the plan.  <br />
									<strong>Credit rating:</strong>  A Debt Management Plan is likely to have a negative impact on your credit rating, making it more difficult to obtain credit in the future. <br />
									<strong> No Legal Protection:</strong>  Creditor can choose to continue with their debt collection process, which can lead to further action such as a default or County Court Judgment.

								</p>
							</div>

						</div>

					</div>
				</div>
			</div>
			{/* <!-- MODAL 5--> */}

			<div className={`modal fade ${showModal5}`} id="pop5">
				<div className="modal-dialog  modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel"> </h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal5('hide')}></button>
						</div>
						<div className="modal-body ">
							<div className="pop-text-contents">
								<h2> What Is A Debt Relief Order (DRO)?</h2>
								<p>  A Debt Relief Order is a formal insolvency procedure, which is only available to people who meet the following criteria:</p>
								<ul className="ps-3">
									<li>You reside in England, Wales or Northern Ireland.</li>
									<li>Your debts must not exceed £30,000 (£20,000 for residents of Northern Ireland).</li>
									<li>You have a disposable income of £75 or less each month (£50 or less for residents of Northern Ireland).</li>
									<li>You are not a homeowner.</li>
									<li>Your assets are worth less than £2,000 in total (£1,000 in Northern Ireland).</li>
									<li>Your vehicle is worth less than £2,000 (£1,000 in Northern Ireland).</li>
									<li>It has been at least six years since your last application for a Debt Relief Order.</li>
									<li>You are not subject to another insolvency procedure such as an<span>&nbsp;</span>Individual Voluntary Arrangement<span>&nbsp;</span>or<span>&nbsp;</span>Bankruptcy.</li>
								</ul>


								<h2>What Are The Benefits Of A Debt Relief Order?</h2>
								<p>
									<strong>Duration: </strong>  Lasts for 12 months in total at the end of which your debts are written-off. <br />
									<strong>Legal Protection: </strong> Creditors can no longer proceed with legal action once the Debt Relief Order has been approved. <br />
									<strong>Payments: </strong> You are not required to make any monthly repayments. <br />
									<strong>Interest {'&'} Charges:</strong>   Interest and charges are frozen once the Debt Relief Order is approved.

								</p>
								<h2>What Are The Considerations Of A Debt Relief Order?</h2>
								<p>


									<strong>Fees:  </strong>There is a one-off fee of £90 to process your application through an Approved Intermediary.<br />
									<strong>  Credit Rating: </strong>  The Debt Relief Order will be recorded and have a negative impact on your credit file for a period of six years. <br />
									<strong>Public Register: </strong>  Your personal details will be recorded on the Insolvency Register which is a public record. <br />
									<strong>Change in Circumstances: </strong>   If your circumstances change during the 12-month period and you no longer meet the eligibility criteria, it is likely that the application will be cancelled, and you will need to make alternative arrangements to pay your debts.  <br />
									<strong>Employment:</strong>  A Debt Relief Order may impact upon your employment; therefore, you should always check the terms of your employment contract before making an application. You are also unable to be a company director unless you have permission of the court.
								</p>


							</div>

						</div>

					</div>
				</div>
			</div>


			{/* <!-- MODAL 6--> */}

			<div className={`modal fade ${showModal6}`} id="pop6">
				<div className="modal-dialog  modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel"> </h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal6('hide')}></button>
						</div>
						<div className="modal-body ">
							<div className="pop-text-contents">
								<h2> What Is An Individual Voluntary Arrangement (IVA)?</h2>
								<p>   A formal insolvency procedure in which a legally binding agreement between you and your creditors is drawn up to pay back your debts at an affordable rate.</p>
								<p>The Arrangement will typically last for five or six years, with any remaining debt at the end of this period being written off.</p>


								<h2>What Are The Benefits Of An IVA?</h2>
								<p>
									<strong>Assets Protected: </strong> If you are a homeowner, your home will be protected and not put at risk of being sold. <br />
									<strong> Affordable Payment:  </strong> One affordable, monthly repayment which makes your finances much easier to manage. <br />
									<strong>Timeframe:  </strong> A set time period (usually five or six years), giving you a countdown to becoming debt free.<br />
									<strong>Legal Protection:</strong>    Once approved, creditors can no longer proceed with legal action against you.
									<br />
									<strong>Peace of Mind:</strong>  Creditors are no longer able to contact you regarding your debts.
									<strong>Interest {'&'} Charges: </strong>Interest and charges are frozen once your proposal has been approved.
								</p>
								<h2>What Are The Considerations Of An IVA?</h2>
								<p>


									<strong>Fees:  </strong> There are fees payable for the work completed in setting up and managing your IVA, however these fees are taken out of your monthly repayments and are NOT in addition to the payments made to your creditors.<br />
									<strong>  Credit Rating: </strong>   An IVA will be recorded and have a negative impact on your credit file for a period of six years. <br />
									<strong>Public Register: </strong>   Your personal details will be recorded on the Insolvency Register which is a public record. <br />
									<strong>Equity Release:  </strong>   If you are a homeowner, you may be required to release equity from your home in order to help repay your debts. If you are unable to remortgage, the IVA may be extended for 12 months instead. <br />
									<strong>Windfalls:</strong>   If you receive a windfall such as inheritance or a lottery win, you are required to pay a proportion of this into the IVA. <br />
									<strong>  Approval:</strong>   Creditors may decide not to approve the proposed Arrangement.

								</p>


							</div>

						</div>

					</div>
				</div>
			</div>
			{/* <!-- MODAL 7--> */}

			<div className={`modal fade ${showModal7}`} id="pop7">
				<div className="modal-dialog  modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="exampleModalLabel"> </h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal7('hide')}></button>
						</div>
						<div className="modal-body ">
							<div className="pop-text-contents">
								<h2> What Is A Remortgage?</h2>
								<p>    A remortgage consists of taking out a loan secured against your property.</p>
								<p>The loan is used to consolidate your existing debts and save on high interest rates.</p>
								<p>The secured loan can usually be spread over a much longer term, which can help to reduce your monthly repayments.</p>

								<h2>What Are The Benefits Of Remortgage?</h2>
								<p>
									<strong>Affordable Payments: </strong> One affordable, monthly repayment making your finances much more manageable. <br />
									<strong> Reduced Interest:  </strong> Interest rates on a secured loan are typically much lower than those of an unsecured loan, although the debt may be spread over a much longer period meaning that you pay back more overall. <br />
									<strong>Debts Repaid:   </strong> If you can release sufficient equity from your property, the loan could allow you to repay your unsecured debts in full.<br />


								</p>
								<h2>What Are The Considerations Of Remortgage?</h2>
								<p>


									<strong>  Advice:   </strong> You should always seek independent mortgage advice before pursuing this solution.<br />
									<strong>  Failure:  </strong>  If you to keep up with your monthly repayments, the lender could take legal action against you which could result in your home being repossessed. <br />
									<strong>Fees:  </strong>   There can be significant fees associated with remortgaging such as valuations, product fees, legal costs and broker fees.<br />

								</p>


							</div>

						</div>

					</div>
				</div>
			</div>


			{/* <!-- END MODAL -->

   <!-- Debt Solutions --> */}

			<Accordion className="quastion-area  clearfix">
				<div className="container mb-5">
					<h2 className="text-center mb-4 fs-1">  Any Questions </h2>
					<div className="accordion" id="accordionExample">
						<Accordion.Item className="accordion-item mb-3" eventKey="0">
							<Accordion.Header className="accordion-header" id="headingOne">
								What Is An IVA?
							</Accordion.Header>
							<Accordion.Collapse eventKey="0" id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
								<Accordion.Body className="accordion-body">
									<p>An individual voluntary arrangement or an IVA as it is commonly referred to, is a where a legally
										binding agreement is drawn up between you and your creditors to allow you to repay your debts at
										an affordable rate. At least 75% of creditors (by debt value) must accept the proposal for it to be
										approved.</p>
									<p> An IVA typically lasts for 5 or 6 years, with any remaining debt at the end of this period being written
										off. </p>
									<p> <strong>Benefits:</strong>
									<ul>
										<li>Your assets are protected such as your home or vehicle</li> 
										<li> You make one affordable monthly payment</li> 
										<li> Once approved the creditors included can no longer pursue the debts.</li> 
										<li> Interest and Charges are frozen once the arrangement is in place.</li> 
										</ul>
										</p>
									<p> <strong>Considerations:</strong>
									<ul>
										<li> There are fees payable for the work involved in setting up and managing your IVA, however,
										these are included within your monthly payments.</li>
										<li> You will need to conduct an annual review of your finances</li>
										<li>Your credit rating will be affected as an IVA is recorded on there for 6 years.</li> 
										<li>  Your details are recorded on the insolvency register.</li> 
										<li>  If you’re IVA were to fail, you could be made bankrupt </li></ul>
										 </p>
										

								</Accordion.Body>
							</Accordion.Collapse>
						</Accordion.Item>
						<Accordion.Item className="accordion-item mb-3" eventKey="1">
							<Accordion.Header className="accordion-header" id="headingTwo">
								What Type of Debt Can We Help With?
							</Accordion.Header>
							<Accordion.Collapse eventKey="1" id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
								<Accordion.Body className="accordion-body">
								<p>We can help with pretty much any unsecured debt such as credit cards, loans, store cards, catalogues, buy now pay later, overdrafts, payday loans, doorstep loans, council tax, HMRC, overpayments of benefits, UC advance payments.</p>
								</Accordion.Body>
							</Accordion.Collapse>
						</Accordion.Item>
						<Accordion.Item className="accordion-item mb-3" eventKey="2">
							<Accordion.Header className="accordion-header" id="headingThree">
								How Long Does It Take?
							</Accordion.Header>
							<Accordion.Collapse eventKey="2" id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
								<Accordion.Body className="accordion-body">
									<p>Our friendly debt experts work quickly to get an idea of your situation and will go through all options available based on your circumstances. If you are happy with the solution and wish to proceed, then we can work on stopping contact from your creditors.</p>
								</Accordion.Body>
							</Accordion.Collapse>
						</Accordion.Item>
						<Accordion.Item className="accordion-item mb-3" eventKey="3">
							<Accordion.Header className="accordion-header" id="four">
								Do I Have to Talk To Someone?
							</Accordion.Header>
							<Accordion.Collapse eventKey="3" id="collapseFour" className="accordion-collapse collapse" aria-labelledby="four" data-bs-parent="#accordionExample">
								<Accordion.Body className="accordion-body">
									<p>We can collate information online during the initial assessment we have, we can also initially discuss
										your situation via WhatsApp, SMS or Email. However, it is mandatory and part of our regulatory
										obligations that we speak to you over the phone when discussing your options. Discussing your
										options over the phone ensures you have a thorough understanding of the solutions available to you
										and allows you to make an informed decision. All our conversations are completely confidential, and
										our team are extremely friendly!</p>
								</Accordion.Body>
							</Accordion.Collapse>
						</Accordion.Item>
						<Accordion.Item className="accordion-item mb-3" eventKey="4">
							<Accordion.Header className="accordion-header" id="five">
							Alternative Debt Solutions
							</Accordion.Header>
							<Accordion.Collapse eventKey="4" id="collapseFive" className="accordion-collapse collapse" aria-labelledby="five" data-bs-parent="#accordionExample">
								<Accordion.Body className="accordion-body">
									<p>If you are struggling to repay your debts, please don’t worry, we’re here for you. No matter you situation there will be a solution for you,
										 here we feature the most popular debt solutions,
										  but in order for us to correctly identify the correct solution for your particular set of circumstances, 
										  we will need to talk to you.</p>
										  <div className="debt-section">

                
                <ul className="list-points ">
                  <li onClick={() => setShowModal1('show')}> <a data-bs-toggle="modal" data-bs-target="#pop1">Bankruptcy</a> </li>
                  <li onClick={() => setShowModal2('show')}> <a data-bs-toggle="modal" data-bs-target="#pop2">Breathing space scheme</a> </li>
                  <li onClick={() => setShowModal3('show')}> <a data-bs-toggle="modal" data-bs-target="#pop3">Consolidation loans</a> </li>
                  <li onClick={() => setShowModal4('show')}> <a data-bs-toggle="modal" data-bs-target="#pop4">Debt management plan</a> </li>
                  <li onClick={() => setShowModal5('show')}> <a data-bs-toggle="modal" data-bs-target="#pop5"> Debt relief order</a> </li>
                  <li onClick={() => setShowModal6('show')}> <a data-bs-toggle="modal" data-bs-target="#pop6">Individual voluntary arrangement</a> </li>
                  <li onClick={() => setShowModal7('show')}> <a data-bs-toggle="modal" data-bs-target="#pop7">Remortgage</a> </li>
              </ul>
			  </div>
								</Accordion.Body>
							</Accordion.Collapse>
						</Accordion.Item>

					</div>
				</div>
			</Accordion>
			<Footer />
		</div>
	);
}

export default MRD_V1;