import React from "react";
import loader from "../../../../assets/img/loader.png";

const ProceedLoader = (props) =>{

    return(
        <>
            <div className={`container `}>
            <div className={`overlay text-center ${props.proceedPopup} `} >
                  <div className="mt-5">
                      <div className="loader-box">
                        <img className="loader" src={loader} alt=""/>
                      </div>
                    Loading...</div>
            </div>
          
        </div>

        </>
    )
}
export default ProceedLoader;