import React, { useState } from "react";
import downArrow from "../../../../assets/img/down-arrow-circle.png";
import SelectBox from "../../../UI/SelectBox";
import { Salutation } from "../../../../Constants/Constants";
import PersonalDetails from "../../../Includes/Layouts/MRD_V2/PersonalDetails";
import GTMDataLayer from "../Common/GTMDataLayer";

const QuestionPersonalSlide = (props) => {
    const basicDetailsValidation = async (e) => {
      let errorFlag = 0;
      var lstSalutationResult = await props.trigger("lstSalutation");
      var txtFNameResult;
      var txtLNameResult;
      if (lstSalutationResult) {
         txtFNameResult = await props.trigger("txtFName");
      }
      else {
         document.getElementById("lstSalutation").focus();
         return false;
      }
      if (txtFNameResult) {
         txtLNameResult = await props.trigger("txtLName");
      }
      else {
         document.getElementById("txtFName").focus();
         return false;
      }
      if (!txtLNameResult) {
        document.getElementById("txtLName").focus();
        return false;
      }

      if (
         !lstSalutationResult ||
         !txtFNameResult ||
         !txtLNameResult
      ) {
         errorFlag = 1;
      }
      if (errorFlag === 0) {    
            props.slideChange(e,"next009");    
      } else {
         return false;
      }
      GTMDataLayer({
        question: "Personal details",
      });
   };
  return (
    <>
      <div id='slide9' className={`slider9 ${props.showQuestionSlide}`}>
        <div className="forms shadow mt-3">
          <div className="down-arrow shake-vertical">
            <img src={downArrow} alt="" />
          </div>
          <div className="col-lg-12">
            <div className="col-lg-12 row custom m-0 forminnercontent text-center">
              <h1 className="title">Please provide your personal details</h1>
              <div class="col-lg-12 row custom m-0 forminnercontent text-center">
                <div className="mt-3">
                    <div className="form-group mb-3">        
                    <SelectBox
                        OptionValue={Salutation}
                            className="form-control wid_title"
                            id="lstSalutation"
                            name="lstSalutation"
                            onChange={() => props.clearErrors("lstSalutation")}
                            myRef={props.validation({
                            required: "Please Select Title",
                            })}
                        />
                        {props.validationMsg.lstSalutation && (
                            <div className="w-100 bg-danger text-white"> 
                              <span>
                                {props.validationMsg.lstSalutation.message}
                            </span>
                            </div>
                        
                        )}
                    </div>
                    <PersonalDetails
                        validation={props.validation}
                        validationMsg={props.validationMsg}
                        trigger={props.trigger}
                        clearErrors={props.clearErrors}
                        setError={props.setError}
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          {props.backBtn &&
            <div class="btn-more back-btn  mt-4 " id="back9" name="back9" onClick={props.previousSlide}>
            <span> Back </span>
          </div>}
          <div class="btn-more next-btn mt-4 " id="next009" name="next009" onClick={basicDetailsValidation}>
            <span> Next</span>
          </div>
          </div>
      </div>
    </>
  )
};

export default QuestionPersonalSlide;
