import React, { useState } from "react";
import TextField from "../../../UI/TextField";
const PersonalDetails = ({
  validation,
  validationMsg,
  setError,
}) => {
  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };
  return (
    <>
      {" "}
      <div className="form-group mb-3">
        <TextField
          className="form-control secured-field"
          type="text"
          placeholder="First Name"
          name="txtFName"
          id="txtFName"
          onBlur={namevalidate}
          validation={validation({
            required: "Please Enter First Name",
            minLength: {
              value: 3,
              message: "Please Enter Valid First Name",
            },
            pattern: {
              value: /^[a-zA-Z\s]+$/,
              message: "Your Name is Invalid. Please Recheck",
            },
          })}
        />
        {validationMsg.txtFName && (
          <div className="w-100 bg-danger text-white">
            <span>
              {validationMsg.txtFName.message}
            </span>
          </div>
        )}

      </div>
      <div className="form-group mb-3">
        <TextField
          className="form-control secured-field"
          type="text"
          placeholder="Last Name"
          name="txtLName"
          id="txtLName"
          onBlur={namevalidate}
          validation={validation({
            required: "Please Enter Last Name",
            minLength: {
              value: 3,
              message: "Please Enter Valid Last Name",
            },
            pattern: {
              value: /^[a-zA-Z\s]+$/,
              message: "Your Name is Invalid. Please Recheck",
            },
          })}
        />
        {validationMsg.txtLName && (
          <div className="w-100 bg-danger text-white">
            <span>
              {validationMsg.txtLName.message}
            </span>
          </div>
        )}

      </div>

    </>
  );
};
export default PersonalDetails;
