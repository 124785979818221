const questinnare1 = {
    questionId: 1,
    question: "Are you older than 18?",
    questionSubText: '<p class="text2"> Over 87,000 People Solved their Debts in 2022*&nbsp;<br /> Take our 30 Second Quiz and Find Out Now </p>',
    subTextPos : "top",
    answers: [
        {
            label: "Yes",
            value: "1"
        },
        {
            label: "No",
            value: "2"
        }
    ]
};
const questinnare2 = {
    questionId: 2,
    question: "How many debts do you have?",
    questionSubText: '<p class="text2">Take the first step in resolving your debts by answering a few questions and finding out how we could help.</p>',
    questionMaintext: '<p>How many different companies/places do you owe money to? <br />(e.g. Council Tax, Personal Loan, Credit Card, Water Bill etc)</p>',
    subTextPos : "top",
    answers: [
        {
            label: "1",
            value: "3"
        },
        {
            label: "2-5",
            value: "4"
        },
        {
            label: "5+",
            value: "5"
        }
    ]
};
const questinnare3 = {
    questionId: 3,
    question: "How much do you owe in total?",
    questionSubText: "<h5>There are solutions tailored to help with all debt levels.</h5>" ,
    subTextPos : "top",
    answers: [
        {
            label: "£0 -  £1000",
            value: "6"
        },
        {
            label: "£1000 -  £5000",
            value: "7"
        },
        {
            label: "£5000 -  £10000",
            value: "8"
        },
        {
            label: "£10000 -  £15000",
            value: "9"
        },
        {
            label: "£15000 -  £20000",
            value: "10"
        }
    ]
};
const questinnare4 = {
    questionId: 4,
    question: "Which of the following causes you the most worry?",
    questionSubText:null,
    subTextPos : null,
    answers: [
        {
            label: "Loans/Credit Cards",
            value: "11"
        },
        {
            label: "Catalogues/Store Cards",
            value: "12"
        },
        {
            label: "Bailiffs/Debt Collectors",
            value: "13"
        },
        {
            label: "Car Finance",
            value: "14"
        },
        {
            label: "Council Tax",
            value: "15"
        },
        {
            label: "Utility Bills",
            value: "16"
        },
        {
            label: "Other Debt",
            value: "17"
        }
    ]
};
const questinnare5 = {
    questionId: 5,
    question: "Which part of the country do you live in?",
    questionSubText:null,
    subTextPos : null,
    answers: [
        {
            label: "England",
            value: "18"
        },
        {
            label: "Scotland",
            value: "19"
        },
        {
            label: "Wales",
            value: "20"
        },
        {
            label: "Northern Ireland",
            value: "21"
        }
    ]
};
const questinnare6 = {
    questionId: 6,
    question: "What is your residential status?",
    questionSubText:null,
    subTextPos : null,
    answers: [
        {
            label: "Homeowner",
            value: "22"
        },
        {
            label: "Tenant",
            value: "23"
        },
        {
            label: "Living with parents",
            value: "24"
        },
        {
            label: "Other",
            value: "25"
        }
    ]
};

const questinnare7 = {
    questionId: 7,
    question: "What is your employment status?",
    questionSubText:null,
    subTextPos : null,
    answers: [
        {
            label: "Employed",
            value: "26"
        },
        {
            label: "Retired",
            value: "27"
        },
        {
            label: "Unemployed",
            value: "28"
        }
    ]
}
const questinnare8 = {
    questionId: 8,
    question: "What is your total monthly household income?",
    questionSubText: "<h4>(Including your partner's)</h4>",
    subTextPos:"bottom",
    answers: [
        {
            label: "£0 - £700",
            value: "29"
        },
        {
            label: "£701 - £1500",
            value: "30"
        },
        {
            label: "£1,501+",
            value: "31"
        }
    ]
}

const Salutation = [
    {
        label: "Select Title",
        value: ""
    },
    {
        label: "Mr.",
        value: "Mr"
    },
    {
        label: "Mrs.",
        value: "Mrs"
    },
    {
        label: "Ms.",
        value: "Ms"
    },
    {
        label: "Miss.",
        value: "Miss"
    },
    {
        label: "Dr.",
        value: "Dr"
    },
    {
        label: "Rev.",
        value: "Rev"
    },
    {
        label: "Dame.",
        value: "Dame"
    },
    {
        label: "Lady.",
        value: "Lady"
    },
    {
        label: "Sir.",
        value: "Sir"
    },
    {
        label: "Lord.",
        value: "Lord"
    },
];
const questinnare9 = {
    questionId: 14,
    question: "Were you sold any other products in the past where you don't think this bank treated you fairly, which you would like to be reviewed?",
    answers: [
        {
            label: "Yes",
            value: "44"
        },
        {
            label: "No",
            value: "45"
        }
    ]
};

const questinnare10 = {
    questionId: 15,
    question: "Please name the products you would like to be reviewed?"
};


const questinnare11 = {
    questionId: 16,
    question: "Did you expect the advice you were given to be the best for your circumstances?",
    answers: [
        {
            label: "Yes",
            value: "46"
        },
        {
            label: "No",
            value: "47"
        }
    ]
};
const questinnare12 = {
    questionId: 17,
    question: "Does the bank contacting you over the phone cause you anxiety and stress?",
    answers: [
        {
            label: "Yes",
            value: "48"
        },
        {
            label: "No",
            value: "49"
        }
    ]
};
const questinnare7Sub1 = {
    questionId: 17,
    question: "Does the bank contacting you over the phone cause you anxiety and stress?",
    answers: [
        {
            label: "Yes",
            value: "48"
        },
        {
            label: "No",
            value: "49"
        }
    ]
};
const questinnare7Sub2 = {
    questionId: 17,
    question: "Does the bank contacting you over the phone cause you anxiety and stress?",
    answers: [
        {
            label: "Yes",
            value: "48"
        },
        {
            label: "No",
            value: "49"
        }
    ]
};

export {
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4,
    questinnare5,
    questinnare6,
    questinnare7,
    questinnare8,
    Salutation,
    questinnare7Sub1,
    questinnare7Sub2,
    questinnare11,
    questinnare12,
    questinnare9,
    questinnare10
};
