// import { Button } from 'bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet";

const Feefo = () => {


  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="https://api.feefo.com/api/javascript/revolution-money" async></script>
      </Helmet>


      <div className='review-section' >

        <div className='container'>

          <div className='row pb-5'>
            <div className='offset-2 offset-lg-0 col-8 col-md-8 col-lg-5'>
              <a href="https://www.feefo.com/reviews/revolution-money" target="_blank"><img className='w-100' alt="Feefo logo" border="0" src="https://api.feefo.com/api/logo?merchantidentifier=revolution-money" title="See what our customers say about us" /></a>

            </div>
            <div className=' offset-md-3 offset-lg-0 col-md-6 pt-3'>
              <h1 className='text-center text-lg-start'>Customer <br></br> Experience Rating</h1>
            </div>
          </div>

          <div className='container p-0'>
            <div id="feefo-service-review-carousel-widgetId" className="feefo-review-carousel-widget-service" >

            </div>
          </div>


        </div>

      </div>
    </div>
  )
}

export default Feefo;
