import React, { useEffect, useRef, useState, useContext } from 'react'
import Logo from "../../assets/img/Revolution-Money-Logo.png";
import loader from "../../assets/img/loader.png";
import TyFooter from '../Includes/Layouts/Common/TyFooter';
import Loader from '../Includes/Layouts/Common/Loader';
import TyHeader from '../Includes/Layouts/Common/TyHeader';
import Button from '../UI/Button';
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import { UUID } from '../../Utility/UUID';
import { source, queryString } from '../../Utility/QueryString';
import { useBudsVisitors } from '../../Hooks/Buds/useBudsVisitors';
import { useBudsInit } from '../../Hooks/Buds/useBudsInit';
import { userInfo } from "../../Hooks/userInfo";
import { useForm } from "react-hook-form";
import DateOfBirthText from '../../Utility/DateOfBirthText';
import PostCode from '../../Utility/PostCode';
import PreviousAddress from '../../Utility/PreviousAddress';
import { storeApiResponse } from "../../Hooks/Buds/useSaveBudsApiResponse";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { PostcodeValidation } from '../../Utility/PostcodeValidation';
import { useDataIngestion } from '../../Hooks/useDataIngestion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GTMDataLayer from '../Includes/Layouts/Common/GTMDataLayer';

const FinanceCheck = () => {
  const [show, setShow] = useState('d-block');
  const [ManualBtn, setManualBtn] = useState('');
  const [Loader2, setLoader2] = useState('d-none');
  const [show1, setShow1] = useState('d-none');
  const [show2, setShow2] = useState('d-none');
  const [show3, setShow3] = useState('d-block');
  const [showRemove, setShowRemove] = useState('d-none');
  const [hide, setHide] = useState();
  const [buttonShow, setbuttonShow] = useState("d-none");
  const { visitorsResponse } = useBudsVisitors();
  const { getBudsApi } = useBudsInit();
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveDataIngestion } = useDataIngestion();

  const { getUserInfo } = userInfo();
  const { visitorParameters } = useContext(VisitorParamsContext);
  let payload = '';
  let customer_create_data = '';
  let visitorId = '';

  useEffect(() => {
    setTimeout(() => {
      setShow('d-none');
    }, 1500);
  }, []);
  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  function getCustomerDettails(salutation, firstName, lastName, email, phoneNumber) {
    const customer_details = {
      "salutation": salutation,
      "firstname": firstName,
      "lastname": lastName,
      "email": email,
      "phonenumber": phoneNumber
    };
    return customer_details;
  }

  const history = useHistory();

  const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const splitForm = useRef(null);

  const shownxt = (e) => {
    Duration(e);
    setShow1('d-block');
    window.scrollTo({ top: 300, behavior: 'smooth' });
  }
  const past_add = () => {
    setShow3('d-none');
    setShow2('d-block');
    setShowRemove('d-block');
  }
  const Duration = async (e) => {
    let btnvalue = [e.target.value];
    setManualBtn(e.target.value);
    if(btnvalue == 'manual'){
    const formSubmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      btnvalue,
      'financial_check_details',
      '',
      queryString,
      visitorParameters.data
    )
    }
  }

  const getOpenBankingApi = async () => {
    setbuttonShow("d-none");
    setShow('d-block');
    const formdata = JSON.parse(localStorage.getItem('formData'));
    const response = await getUserInfo(
      uuid
    );
    let dataLength = (response.data.response).length;
    if (dataLength > 0) {
      const salutation = (response.data.response[0].title) ? response.data.response[0].title : '';
      const firstName = (response.data.response[0].first_name) ? Capitalize(response.data.response[0].first_name) : '';
      const lastName = (response.data.response[0].last_name) ? Capitalize(response.data.response[0].last_name) : '';
      const email = (response.data.response[0].email) ? response.data.response[0].email : '';
      const phoneNumber = (response.data.response[0].telephone) ? response.data.response[0].telephone : '';
      const record_status = (response.data.response[0].record_status) ? response.data.response[0].record_status : '';
      var is_test = (record_status == 'LIVE') ? false : true;
      localStorage.setItem('record_status', record_status);
      var token = (response.data.response[0].token) ? response.data.response[0].token : '';
      var pixelId = (response.data.response[0].tracker_unique_id) ? response.data.response[0].tracker_unique_id : '';
      customer_create_data = getCustomerDettails(salutation, firstName, lastName, email, phoneNumber,)
    }
    if (formdata) {
      customer_create_data = getCustomerDettails(
        formdata.lstSalutation,
        formdata.txtFName,
        formdata.txtLName,
        formdata.txtEmail,
        formdata.txtPhone
      );
    }
    if (localStorage.getItem('visitorId') === null || localStorage.getItem('visitorId') === '') {
      const triggerType = "new";
      const visitorResponse = await visitorsResponse(uuid, source, triggerType, token, pixelId).catch(function (error) {
        console.log(error);
        setbuttonShow("d-block");
        return false;
      });
      if ((visitorResponse) && visitorResponse.data.status == "success") {
        visitorId = (visitorResponse.data.visitorId) ? visitorResponse.data.visitorId : '';
        payload = { "visitor_id": visitorId, "source": source, "query_string": queryString, "triggerType": triggerType };
        await storeApiResponse(visitorParameters.visitor_parameters, "open_bank_visit_live", uuid, payload);
        localStorage.setItem('visitorId', visitorId);
      }else {
        setbuttonShow("d-block");
        return false;
      }
    } else {
      visitorId = localStorage.getItem('visitorId');
    }
    const budsResponse = await getBudsApi(uuid, source, visitorId, is_test, customer_create_data).catch(function (error) {
      console.log(error);
      setbuttonShow("d-block");
      return false;
    });
    if (budsResponse != '' && budsResponse.data.status == "success") {
      let url = budsResponse.data.gateway_url;
      window.location.href = url;
    }else{
      setTimeout(() => {
        setbuttonShow("d-block");
      }, 1000);
      
    }
  }

  const nxtloader = async () => {

    let errorFlag = 0;
    const form = splitForm.current;

    let DobDayResult = await trigger("DobDay");
    let DobMonthResult;
    let DobYearResult;
    let postCodeResult;
    let Address1Result;
    let txtPrevPostCode_1Result;
    let txtPrevaddress1_1Result;

    if (DobDayResult) {
      DobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (DobMonthResult) {
      DobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (DobYearResult) {
      postCodeResult = await trigger("txtPostCode");
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    if (postCodeResult) {
      Address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (Address1Result) {
      txtPrevPostCode_1Result = await trigger("txtPrevPostCode_1");
      if (form["txtPrevPostCode_1"].value != '') {
        if (!PostcodeValidation(form["txtPrevPostCode_1"].value)) {
          setError("txtPrevPostCode_1", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
          setShow3('d-none');
          setShow2('d-block');
          setShowRemove('d-block');
          document.getElementById("txtPrevPostCode_1").focus();
          return false;
        }
      }

    } else {
      document.getElementById("address1").focus();
      return false;
    }
    if (txtPrevPostCode_1Result) {
      if (txtPrevPostCode_1Result) {
        txtPrevaddress1_1Result = await trigger("txtPrevaddress1_1");
      } else {
        document.getElementById("txtPrevPostCode_1").focus();
        return false;
      }
      if (txtPrevaddress1_1Result) {
      } else {
        document.getElementById("txtPrevaddress1_1").focus();
        return false;
      }
    }
    if (
      !DobDayResult ||
      !DobMonthResult ||
      !DobYearResult ||
      !postCodeResult ||
      !Address1Result
      // !txtPrevPostCode_1Result ||
      // !txtPrevaddress1_1Result 
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {

      const values = getValues();
      const formData = values;
      formData.finace_check = ManualBtn;
      formData.source = source;
      if(ManualBtn == 'open_banking' || ManualBtn == 'manual'){
        const formSUbmitResult = await saveDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          'save-address-details',
          'MRD_V3',
          queryString,
          visitorParameters.data
        );
      }
      if (ManualBtn == "manual") {
        history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid)
      } else {
        getOpenBankingApi();
        setLoader2('d-block');
      }

    }

    GTMDataLayer({
      question: "DOB & Address",
      answer:""
    });

  }
 
  const past_remove = async () => {
    const form = splitForm.current;
    form["txtPrevPostCode_1"].value = "";
    let txtPrevaddress1_1Result = await trigger("txtPrevPostCode_1");
    if (txtPrevaddress1_1Result) {
      form["txtPrevPostCode_1"].value = "";
      form["txtPrevaddress1_1"].value = "";
      form["txtPrevHouseNumber_1"].value = "";
      form["txtPrevAddress2_1"].value = "";
      form["txtPrevTown_1"].value = "";
      form["txtPrevCountry_1"].value = "";
      form["txtPrevcounty_1"].value = "";
    }
    setShow3('d-block');
    setShow2('d-none');
    setShowRemove('d-none');
    setHide('d-none');
  }

  return (
    <>
      <GetVisitorsParams />
      <div className='FinanceCheck'>
        <div className="openbank  top-main-section pb-5">
          <TyHeader />
          <div
            className="container pb-lg-5 pb-md-5 pb-sm-5 pb-2"
            style={{ position: "relative", zIndex: 5 }}
          >
            <Loader
              show={show}
              button={buttonShow}
            />
            {/* SLIDE 1 */}
            <div className="row slide-lp-00">
              <div className="col-lg-10 offset-lg-1">
                <h2 className="title-main">Let's take a closer look</h2>
                <p>
                  We'll run a quick check next for security and automatically populate
                  your budget. We do this by using&nbsp;Bud Financial's&nbsp;Open Banking.
                  This will not impact your credit score.{" "}
                </p>
                <p className="mb-4">
                  {" "}
                  To speed&nbsp;things&nbsp;up, Run Free Debt check will
                  auto&nbsp;fill your details and save&nbsp;you&nbsp;time.
                </p>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="card card-box   shadow-lg">
                      <div className="card-body p-3 ">
                        <h3 className="card-header ps-0 pt-1 ms-1">Open Banking </h3>
                        <div className="p-2">
                          <div className="d-flex justify-content-between mb-3 fw-bold txt-time">
                            <div>Duration (approx)</div>
                            <div>2 minutes</div>
                          </div>
                          <p>
                            Use Bud Financial's safe and secure tool to quickly pull
                            through your income and spending Data
                          </p>

                          <Button
                            type="button"
                            id="runBtn"
                            // className="btn-more next-btn btn-submit2  w-100 fw-bold debtsearch  "
                            buttonText="Run Free Analysis"
                            className={`btn-more next-btn btn-submit2 w-100 fw-bold ${
                              ManualBtn === 'manual' ? 'btn-manually2' : 'debtsearch'
                            }`}
                            onClick={shownxt}
                            value="open_banking"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="card card-box   shadow-lg">
                      <div className="card-body p-3 ">
                        <h3 className="card-header ps-0 pt-1 ms-1"> Manual </h3>
                        <div clastxtPrevAddress2_1sName="p-2">
                          <div className="d-flex justify-content-between mb-3 fw-bold txt-time">
                            <div>Duration (approx)</div>
                            <div>46 minutes</div>
                          </div>
                          <p>
                            Input your&nbsp;financial&nbsp;data manually.&nbsp;We'll
                            give you tips&nbsp;to help&nbsp;you work out your income
                            and spending.
                          </p>
                          <Button
                            type="button"
                            // className="btn-more next-btn   w-100 fw-bold btn-manually "
                            buttonText="Add Manually"
                            className={`btn-more next-btn w-100 fw-bold ${
                              ManualBtn === 'manual' ? 'debtsearch2' : 'btn-manually'
                            }`}
                            value="manual"
                            onClick={shownxt}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`bottom-address p-4 pt-lg-4 pt-md-0 pt-sm-0 pt-0 ${show1}`}

                >
                  <form
                    ref={splitForm}
                    name="split_form"
                    id="user_form"
                    method="POST"
                    autoComplete="off"
                  >
                    <div className={`row  `}>
                      <div className="col-lg-8">
                        <DateOfBirthText
                          validation={register} trigger={trigger} validationMsg={errors}
                        />

                        <div className="row">
                          <div className="col-sm-4  pt-2 text-lg-end text-md-end text-sm-end text-start">
                            Address
                          </div>
                          <div className="col-sm-8">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="mb-3">
                                  <PostCode
                                    txtAddress="Your Address"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                    clearErrors={clearErrors}
                                    setError={setError}
                                    getValues={getValues}
                                    splitForm={splitForm}
                                  // setBlinkEffect1={setBlinkEffect1}
                                  />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`another-address ${show2}`} >

                          <div className="row">
                            <div className="col-sm-4  pt-2 text-lg-end text-md-end text-sm-end text-start">
                              Past address
                            </div>
                            <div className="col-sm-8">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="mb-3">
                                    <PreviousAddress
                                      txtAddress="Your Address"
                                      validation={register}
                                      validationMsg={errors}
                                      trigger={trigger}
                                      clearErrors={clearErrors}
                                      setError={setError}
                                      getValues={getValues}
                                      splitForm={splitForm}
                                      setValue={setValue}
                                      hide={hide}
                                      setHide={setHide}
                                      clickedAddPre={show3}
                                    // setBlinkEffect1={setBlinkEffect1}
                                    />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-sm-4  pt-2" />
                          <div className="col-sm-8">
                            <p style={{ color: "rgb(252, 63, 95)" }}>
                              If you’ve lived at this address for under three years,
                              please enter all other addresses you lived at during this
                              period.
                            </p>
                            <div className="d-flex justify-content-between">
                              <div
                                id="addAddress"
                                className={`fw-bold mb-4 color-text addaddress-btn ${show3}`}
                                onClick={past_add}
                              >
                                + Add another address
                              </div>

                              <div
                                id="removeAddress"
                                className={`fw-bold mb-4 color-text removeaddress-btn ${showRemove}`}
                                onClick={past_remove}
                              >
                                - Remove Address
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4 " />
                          <div className="col-sm-8">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="mb-3 mt-lg-1 mt-md-1 mt-sm-1 mt-1">
                                  {/* <button id="slNext" className=" btn-commn-two" onClick={nxtloader}>
                                    Next
                                  </button> */}
                                  <input id="slNext" className="btn-commn-two" onClick={nxtloader} value="Next" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <TyFooter />
        <Loader
          show={Loader2}
          button={buttonShow}
          click={getOpenBankingApi}
        />
      </div>
    </>

  )
}

export default FinanceCheck