import React from "react";
import TextField from "../../../UI/TextField";
import SelectBox from "../../../UI/SelectBox";
import { Salutation } from "../../../../Constants/Questions";

const Personals = ({ validation, validationMsg, setError, clearErrors }) => {
  const nameValidate = (fieldName, value) => {
    if (!value.trim() || value.trim().length < 3) {
      const name = fieldName === "txtFName" ? "First Name" : "Last Name";
      setError(fieldName, {
        type: "manual",
        message: `Your ${name} is Invalid. Please Recheck`,
      });
    } else {
      clearErrors(fieldName);
    }
  };

  return (
    <>
      <div className="mt-3">
        <div className="form-group mb-3">
          <SelectBox
            OptionValue={Salutation}
            className="form-control wid_title"
            id="lstSalutation"
            name="lstSalutation"
            onChange={() => clearErrors("lstSalutation")}
            myRef={validation({
              required: "Please Select Title",
            })}
          />
          {validationMsg.lstSalutation && (
            <div className="w-100 bg-danger text-white">
              <span>
                {validationMsg.lstSalutation.message}
              </span>
            </div>

          )}
        </div>
        <div className="mt-3">
          <TextField
            className="form-control secured-field"
            type="text"
            placeholder="First Name"
            name="txtFName"
            id="txtFName"
            onBlur={(e) => nameValidate(e.target.name, e.target.value)}
            validation={validation({
              required: "Please Enter First Name",
              minLength: {
                value: 3,
                message: "Please Enter Valid First Name",
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
          />
          {validationMsg.txtFName && (
            <div className="w-100 bg-danger text-white">
              <span>{validationMsg.txtFName.message}</span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3">
        <div className="form-group mb-3">
          <TextField
            className="form-control secured-field"
            type="text"
            placeholder="Last Name"
            name="txtLName"
            id="txtLName"
            onBlur={(e) => nameValidate(e.target.name, e.target.value)}
            validation={validation({
              required: "Please Enter Last Name",
              minLength: {
                value: 3,
                message: "Please Enter Valid Last Name",
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
          />
          {validationMsg.txtLName && (
            <div className="w-100 bg-danger text-white">
              <span>{validationMsg.txtLName.message}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Personals;
