import React, { useContext, useEffect, useState } from "react";
import RevolutionMoneyLogo from "../../assets/img/Revolution-Money-Logo.png";
import successtick from "../../assets/img/success-tick.gif";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import ProceedLoader from "../Includes/Layouts/Common/ProceedLoader";
import Footer from "../Includes/Layouts/Common/Footer";
import * as EnvConstants from "../../Constants/EnvConstants";
import { CreateTockenApi, CreateAssignmentApi } from "../../api/Api";
import { userInfo } from "../../Hooks/userInfo";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import axios from "axios";
const Proceed = () => {

  const [loading, setLoading] = useState(true);
  const [popupLoading, setPopupLoading] = useState('show');
  const { getUserInfo } = userInfo();
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_uuid = localStorage.getItem('uuid');
  const customUuid = (query_uuid) ? query_uuid : local_uuid;
  const [txtFName, setTxtFirstName] = useState();
  const [txtLName, settxtLNameName] = useState();
  const [txtPhone, settxtPhoneName] = useState();
  const [txtEmail, settxtEmailName] = useState();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { visitorParameters } = useContext(VisitorParamsContext);

  useEffect(() => {
    setTimeout(() => {
      setPopupLoading("show");
    }, 2000)
    setTimeout(() => {
      setPopupLoading("hide");
      setLoading(false);
    }, 2000)
  }, []);

  const splitName = localStorage.getItem('split_name');
  useEffect(() => {
    if (splitName == "MRD_V2") {
      (async () => {
        const formdata = JSON.parse(localStorage.getItem('formData'));
        if (formdata != "" && formdata != undefined && formdata != null) {
          const txtFName = formdata.txtFName;
          const txtLName = formdata.txtLName;
          const txtEmail = formdata.txtEmail;
          let txtPhone = formdata.txtPhone;
          txtPhone = txtPhone.replace(/\D/g,'');
          if(txtPhone.substr(0,1) == 0){
               txtPhone = txtPhone.substr(1);
               txtPhone = '+44'+txtPhone;
          }else{
               txtPhone = '+44'+txtPhone;
          }
          setTxtFirstName(txtFName);
          settxtLNameName(txtLName);
          settxtPhoneName(txtPhone);
          settxtEmailName(txtEmail);
        }
        else {
          const response = await getUserInfo(
            customUuid
          );
          const first_name = response.data.response[0].first_name;
          const last_name = response.data.response[0].last_name;
          const email = response.data.response[0].email;
          let telephone = response.data.response[0].telephone;
          telephone = telephone.replace(/\D/g,'');
          if(telephone.substr(0,1) == 0){
            telephone = telephone.substr(1);
            telephone = '+44'+telephone;
          }else{
            telephone = '+44'+telephone;
          }
          setTxtFirstName(first_name);
          settxtLNameName(last_name);
          settxtPhoneName(telephone);
          settxtEmailName(email);
        }
      })();
    }
   
  }, []);

  const HandleClick = async () => {

    if (splitName == "MRD_V2") {
      console.log("inside- "+txtPhone);
      let click_data = {
        uuid:customUuid,
        type:'access_tocken',
        split_name:splitName
      };
      saveClickDetails(click_data);
      const response = await CreateTockenApi.post(EnvConstants.AppCreateTockenApiUrl,
        {
          "grant_type": 'password',
          "username": EnvConstants.AppCreateTockenApiUsername,
          "password": EnvConstants.AppCreateTockenApiPassword
        },     
      );
     
      if (response.data.access_token != "") {

        const header = {
          headers: {
            "Content-Type": "application/json",
            Authorization : "Bearer " + response.data.access_token
          }
        }
        const data =  {
          "RequireOBURL": false,
          "FirstName": txtFName,
          "Surname": txtLName,
          "Phone": txtPhone,
          "Email": txtEmail,
          "PostCode": "",
          "CampaignReference": "lb"
        }
        const createAssignmentResponse = await axios.post(EnvConstants.AppCreateAssignmenturl,data,header
          );
        
        click_data.type = "redirect_url"
        saveClickDetails(click_data);
        console.log(createAssignmentResponse);
        // localStorage.clear();
        window.location.href = createAssignmentResponse.data.Data.SignUpURL
      }
    }
    if (splitName == "MRD_V1") {
      let click_data = {
        uuid:customUuid,
        type:'proceed_click',
        split_name:splitName
      };
      saveClickDetails(click_data);
      // localStorage.clear();
      window.location.href = EnvConstants.AppFirstSplitProceedUrl
    }
  }
  const saveClickDetails = async (click_data)=>{
    await saveDataIngestion(
      visitorParameters.visitor_parameters,
      click_data,
      "user_click_details",
      );
  } 

  return (
    <>
      <div className="Proceed">
        <div className="top-main-section pb-5">
          {/* <!-- ========= Header Area Start ========= --> */}
          <header>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 logo">
                  <a  data-id="SITE_lOGO">
                    <img title="" alt="Logo Image" src={RevolutionMoneyLogo} />
                  </a>
                </div>
              </div>
            </div>
          </header>
          {loading && <ProceedLoader proceedPopup={popupLoading} />}
          <GetVisitorsParams />
          {/* <!-- ========= Header Area End ========= --> */}
          {!loading && <div className="form-part more-sec ">
            <div className="forms p-lg-5 p-md-5 p-sm-3 p-3  fade-in ">
              <div className="row">
                <div className=" inner-sec  text-center">
                  <img width="80" className="img-tick mb-3" src={successtick} alt="" />
                  <h1 className="mb-3 mt-2 text-center fade-in">Congratulations.!!</h1>
                  <p className="mb-0 pb-1 text-center quali-text flip-vertical-fwd">✔ You're Qualified</p>
                  <p className="mb-5 fade-in">Let's get you setup in our system</p>
                  <button className="com-button"  onClick={HandleClick}><span>Proceed</span></button>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
          }
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Proceed;