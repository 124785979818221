import { BudsApi } from "../../api/BudsApi";
import * as EnvConstants from "../../Constants/EnvConstants";

export const useBudsCustomerConsents = () =>{
    const budsCustomerConsentsResponse = async(uuid,is_test,visitor_id, source) => {
        const response = await BudsApi.post("v1/customer-consents",{
            "ProjectCode": EnvConstants.AppAdtopiaUPID,
            "Environment": EnvConstants.AppEnv,
            "UUID": [uuid],
            "is_test": is_test,
            "visitor_id":visitor_id,
            "source" : source
        });
        return response;
    }
    return { budsCustomerConsentsResponse }
}