import React from 'react';
import "../../../../assets/css/NotFound.scss";
const NotFound = () => {
    return (
        <>
            <div className="NotFound">
                <div className="flex-center position-ref full-height">
                    <div className="code">
                        404            </div>

                    <div className="message" style={{ padding: "10px" }}>
                        Not Found
                </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;