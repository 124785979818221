import { Api } from '../api/Api';

export const useWriteLogApi = () => {
    const writeLogApi = async (fileName, logDetails) => {
        const response = await Api.post("log-details", {
            "fileName": fileName,
            "logDetails": logDetails
        });
        return response;
    }
    return { writeLogApi }
}