import React, { useEffect, useReducer, useState, useRef, useContext } from "react";
import {
	questinnare1,
	questinnare2,
	questinnare3,
	questinnare4,
	questinnare5,
	questinnare6,
	questinnare7,
	questinnare8
} from "../../Constants/Questions";
import { useForm } from "react-hook-form";
import secure from "../../assets/img/secure.png";
import QuestionRadioSlide from "../Includes/Layouts/Questionnaire/QuestionRadioSlide";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProgressBar from "../Includes/Layouts/Common/ProgressBar";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import GTMDataLayer from "../Includes/Layouts/Common/GTMDataLayer";
const FormSplitMRD_V1 = () => {
	const { register, errors, clearErrors, setError, trigger, getValues } =
		useForm({
			mode: "onSubmit",
			reValidateMode: "onChange",
			defaultValues: {},
			resolver: undefined,
			context: undefined,
			criteriaMode: "firstError",
			shouldFocusError: true,
			shouldUnregister: true,
		});
	const initialState = {
		// showQuestionSlide1: "d-block",
		showQuestionSlide2: "d-block",
		showQuestionSlide3: "d-none",
		showQuestionSlide4: "d-none",
		showQuestionSlide5: "d-none",
		showQuestionSlide6: "d-none",
		showQuestionSlide7: "d-none",
		showQuestionSlide8: "d-none"
	};
	const QuestionReducer = (state, action) => {
		switch (action.type) {
			case "nextQuestionSlide": {
				if (action.payload.clickedSlide.slide == "question_1") {
					return {
						...state,
						showQuestionSlide1: "d-none",
						showQuestionSlide2: "d-block",
					};
				} else if (action.payload.clickedSlide.slide == "question_2") {
					return {
						...state,
						showQuestionSlide2: "d-none",
						showQuestionSlide3: "d-block",
					};
				} else if (action.payload.clickedSlide.slide == "question_3") {
					return {
						...state,
						showQuestionSlide3: "d-none",
						showQuestionSlide4: "d-block",
					};
				} else if (action.payload.clickedSlide.slide == "question_4") {
					return {
						...state,
						showQuestionSlide4: "d-none",
						showQuestionSlide5: "d-block",
					};
				} else if (action.payload.clickedSlide.slide == "question_5") {
					return {
						...state,
						showQuestionSlide5: "d-none",
						showQuestionSlide6: "d-block",
					};
				} else if (action.payload.clickedSlide.slide == "question_6") {
					return {
						...state,
						showQuestionSlide6: "d-none",
						showQuestionSlide7: "d-block",
					};
				} else if (action.payload.clickedSlide.slide == "question_7") {
					return {
						...state,
						showQuestionSlide7: "d-none",
						showQuestionSlide8: "d-block",
					};
				} else {
					return {
						...state,
					};
				}
			}
			case "backQuestionSlide": {
				if (action.payload.prevSlide.slide == "back2") {
					return {
						...state,
						showQuestionSlide1: "d-block",
						showQuestionSlide2: "d-none",
					};
				} else if (action.payload.prevSlide.slide == "back3") {
					return {
						...state,
						showQuestionSlide3: "d-none",
						showQuestionSlide2: "d-block",
					};
				} else if (action.payload.prevSlide.slide == "back4") {
					return {
						...state,
						showQuestionSlide4: "d-none",
						showQuestionSlide3: "d-block",
					};
				} else if (action.payload.prevSlide.slide == "back5") {
					return {
						...state,
						showQuestionSlide5: "d-none",
						showQuestionSlide4: "d-block",
					};
				} else if (action.payload.prevSlide.slide == "back6") {
					return {
						...state,
						showQuestionSlide6: "d-none",
						showQuestionSlide5: "d-block",
					};
				} else if (action.payload.prevSlide.slide == "back7") {
					return {
						...state,
						showQuestionSlide7: "d-none",
						showQuestionSlide6: "d-block",
					};
				} else if (action.payload.prevSlide.slide == "back8") {
					return {
						...state,
						showQuestionSlide8: "d-none",
						showQuestionSlide7: "d-block",
					};
				} else {
					return {
						...state,
					};
				}
			}
		}
	};

	const [state, dispatch] = useReducer(QuestionReducer, initialState);
	const history = useHistory();
	const { visitorParameters } = useContext(VisitorParamsContext);
	const formdata = JSON.parse(localStorage.getItem("formData"));
	const [clickedSlide, setClickedSlide] = useState();
	const [stage, setStage] = useState(1);
	const [prevSlide, setPrevSlide] = useState();
	const splitForm = useRef(null);
	const { saveDataIngestion, isLoading } = useDataIngestion();

	useEffect(() => {
		if (clickedSlide) {
			dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
		}
	}, [clickedSlide]);

	useEffect(() => {
		if (prevSlide) {
			dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
		}
	}, [prevSlide]);

	const slideChange = (e, radioName) => {
		gtmDataLayer(e.target.name, e.target);
		window.scrollTo(0, 0);
		setStage(stage + 1);
		const form = splitForm.current;
		const values = getValues();
		let question_id = radioName;
		let optionId = form[radioName].value;
		setClickedSlide({ slide: radioName });
		//save questionData in localstorage
		var existingEntries = JSON.parse(localStorage.getItem("questionData"));
		if (existingEntries == null) existingEntries = {};
		var newEntries =
			existingEntries == null ? (existingEntries = {}) : existingEntries;
		Object.assign(newEntries, {
			[question_id]: optionId,
		});
		localStorage.setItem("questionData", JSON.stringify(newEntries));
		//save questionnaire
		let question_data = {
			question_id: question_id,
			option_id: optionId,
			input_answer: "",
		};
		if (question_id == "question_8") {
			const formData = values;
			formData.lstSalutation = '';
			formData.txtFName = '';
			formData.txtLName = '';
			formData.txtEmail = '';
			formData.txtPhone = '';
			formData.page_name = 'MRD_V1'
			formSubmit(formData);
			history.push('/proceed?uuid=' + visitorParameters.visitor_parameters.uuid)
		}
	};

	const gtmDataLayer = (current_question, element = null) => {
		let question = "";
		let answer = "";
		switch (current_question) {
			case "question_2":
				question = "How many debts";
				break;
			case "question_3":
				question = "Owe in total";
				break;
			case "question_4":
				question = "Causes";
				break;
			case "question_5":
				question = "Country";
				break;
			case "question_6":
				question = "Residential status";
				break;
			case "question_7":
				question = "Employment status";
				break;
			case "question_8":
				question = "Income";
				break;
		}
		GTMDataLayer({
			question: question,
			answer: answer,
		});
	};

	const previousSlide = (e) => {
		var previous_slide = e.target.id;
		setStage(stage - 1);
		setPrevSlide({ slide: previous_slide })
	}

	const progress = { "question_1": "9", "question_2": "23", "question_3": "36", "question_4": "50", "question_5": "63", "question_6": "77", "question_7": "90", "question_8": "100" }
	let stageName = "question_" + stage;

	const formSubmit = async (formData) => {
		const formSUbmitResult = await saveDataIngestion(
			visitorParameters.visitor_parameters,
			formData,
			"split_form_submit",
			"MRD_V1",
			queryString,
			visitorParameters.data
		);
	};
	return (
		<>
			<section className="form-part mt-lg-2 mt-md-4 mt-sm-4 mt-4">
				<div className="container">
					<div className="col-lg-12 ">
						<form ref={splitForm}>
							<ProgressBar stage={stage} count="8" width={progress[stageName]}/>
							{/* <QuestionRadioSlide
							<ProgressBar stage={stage} count="8" width={progress[stageName]} />
							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide1}
								questionnaire={questinnare1}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={false}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/> */}

							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide2}
								questionnaire={questinnare2}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={false}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/>
							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide3}
								questionnaire={questinnare3}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={true}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/>
							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide4}
								questionnaire={questinnare4}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={true}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/>
							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide5}
								questionnaire={questinnare5}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={true}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/>
							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide6}
								questionnaire={questinnare6}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={true}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/>
							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide7}
								questionnaire={questinnare7}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={true}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/>
							<QuestionRadioSlide
								showQuestionSlide={state.showQuestionSlide8}
								questionnaire={questinnare8}
								previousSlide={previousSlide}
								slideChange={slideChange}
								backBtn={true}
								validation={register}
								validationMsg={errors}
								trigger={trigger}
							/>
						</form>

					</div>
					
					<div className="col-lg-12 text-center py-5 secure-img">
						<img src={secure} alt="" />
					</div>
				</div>
			</section>
		</>
	)
}
export default FormSplitMRD_V1;