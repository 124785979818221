require("dotenv").config();
const AppDomain = window.location.origin;
const AppTitle = process.env.REACT_APP_TITLE;
const AppEnv = process.env.REACT_APP_ENV;
const AppApiUrl = process.env.REACT_APP_API_URL;
const AppApiAuthToken = process.env.REACT_APP_API_AUTH_TOKEN;
const AppDomainWithoutProtocol = window.location.hostname;
const AppLpDefaultUrl = process.env.REACT_APP_LP_DEFAULT_URL;
const AppAdtopiaUPID = process.env.REACT_APP_ADTOPIA_UPID;
const AppReferrerURL = document.referrer;
const AppLpRedirectDefaultUrl = process.env.REACT_APP_LP_REDIRECT_DEFAULT_URL;
const AppApDefaultUrl = process.env.REACT_APP_AP_DEFAULT_URL;
const AppConversionPixel = process.env.REACT_APP_CONVERSION_PIXEL;
const AppAdtopiaToken = process.env.REACT_APP_ADTOPIA_TOKEN;
const AppPostCodeSingleApi = process.env.REACT_APP_POSTCODE_LOOKUP_API;
const AppEmailValidateSingleApi = process.env.REACT_APP_EMAIL_VALIDATE_API;
const AppSinglePointPhoneValidationURL = process.env.REACT_APP_SINGLE_POINT_PHONE_VALIDATION_API;
const AppCreateTockenApiUrl = process.env.REACT_APP_CREATETOCKEN_DEFAULT_URL;
const AppCreateTockenApiUsername = process.env.REACT_APP_CREATETOCKEN_USERNAME;
const AppCreateTockenApiPassword = process.env.REACT_APP_CREATETOCKEN_PASSWORD;
const AppCreateAssignmenturl = process.env.REACT_APP_CREATEASSIGNMENT_DEFAULT_URL;
const AppFirstSplitProceedUrl = process.env.REACT_APP_MRDV1_SIGNUP_URL;
const AppBudsApiUrl = process.env.REACT_APP_BUD_SINGLE_END_POINT;
const AppBudsRedirectUrl = process.env.REACT_APP_BUD_REDIRECTION_URL;
const AppFollowupBudsRedirectUrl = process.env.REACT_APP_FOLLOWUP_BUD_REDIRECTION_URL

export {
  AppDomain,
  AppTitle,
  AppEnv,
  AppDomainWithoutProtocol,
  AppLpDefaultUrl,
  AppAdtopiaUPID,
  AppReferrerURL,
  AppLpRedirectDefaultUrl,
  AppApDefaultUrl,
  AppConversionPixel,
  AppAdtopiaToken,
  AppPostCodeSingleApi,
  AppEmailValidateSingleApi,
  AppApiUrl,
  AppApiAuthToken,
  AppSinglePointPhoneValidationURL,
  AppCreateTockenApiUrl,
  AppCreateTockenApiUsername,
  AppCreateTockenApiPassword,
  AppCreateAssignmenturl,
  AppFirstSplitProceedUrl,
  AppBudsApiUrl,
  AppBudsRedirectUrl,
  AppFollowupBudsRedirectUrl
};