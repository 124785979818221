import React, { useContext, useEffect, useRef } from "react";
import downarrowcircle from "../../assets/img/down-arrow-circle.png";
import secure from "../../assets/img/secure.png";
import {
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4,
    questinnare5,
    questinnare6,
    questinnare7,
    questinnare8,
    questinnare9
} from "../../Constants/Questions_V3";
import { useReducer } from "react";
import QuestionRadioSlide from "../Includes/Layouts/Questionnaire/QuestionRadioSlide";
import { useForm } from "react-hook-form";
import { useState } from "react";
import TextSlide from "../Includes/Layouts/MRD_V3/TextSlide";
import QuestionnaireEmail from "../Includes/Layouts/MRD_V3/QuestionnaireEmail";
import QuestionnairePhone from "../Includes/Layouts/MRD_V3/QuestionnairePhone";
import PersonalDetails from "../Includes/Layouts/MRD_V3/PersonalDetails";
import ProgressBar from "../Includes/Layouts/Common/ProgressBar";
import { queryString } from "../../Utility/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as EnvConstants from "../../Constants/EnvConstants";
import GTMDataLayer from "../Includes/Layouts/Common/GTMDataLayer";


const FormSplitMRD_V3 = () => {

    const { register, errors, clearErrors, setError, trigger, getValues, validation ,handleSubmit} =
        useForm({
            mode: "all",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    const field = "pid";
    const initialState = {
        showQuestionSlide1: "d-block",
        showQuestionSlide2: "d-none",
        showQuestionSlide3: "d-none",
        showQuestionSlide4: "d-none",
        showQuestionSlide5: "d-none",
        showQuestionSlide6: "d-none",
        showQuestionSlide7: "d-none",
        showQuestionSlide8: "d-none",
        showQuestionSlide9: "d-none",
        progressWidth:"10%"
    }

    const question =JSON.parse(localStorage.getItem("QuestionData"));
    const [prevValue,setPrevvalue] = useState();
    const [ stage , setStage ] = useState(1);
    const progress = { "question_1": "10", "question_2": "24", "question_3": "36", "question_4": "50", "question_5": "64", "question_6": "78", "question_7": "91", "question_8": "100" }
    var stageName = "question_" + stage;


    const QuestionReducer = (state, action) => {
        switch (action.type) {
            case "nextQuestionSlide": {
                // if(question.question_9 != 32 && action.payload.clickedSlide.slide == "question_13"){
                //     setStage(7);
                //     return{
                //         ...state,
                //         showQuestionSlide5: "d-none",
                //         showQuestionSlide7: "d-block",
                //     }
                // }
            //    else if(prevValue && question.question_9 == 32 && action.payload.clickedSlide.slide == "question_9"){
            //     setStage(6);
            //         return{
            //             ...state,
            //             showQuestionSlide1: "d-none",
            //             showQuestionSlide6: "d-block"
            //         }
            //     }

            // else
            //  if(prevValue && action.payload.clickedSlide.slide == "question_9" ){
            //     setStage(7);
            //     return{
            //         ...state,
            //         showQuestionSlide1:"d-none",
            //         showQuestionSlide7:"d-block"
            //     }
            //     }

            //    else
                if (action.payload.clickedSlide.slide == "question_9") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-none",
                        showQuestionSlide2: "d-block",
                        progressWidth: "10%"
                    }
                }
                else if (action.payload.clickedSlide.slide == "question_10") {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide3: "d-block"
                    }
                }
                else if (action.payload.clickedSlide.slide == "question_11") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide4: "d-block"
                    }
                }
                else if (action.payload.clickedSlide.slide == "question_12") {
                    return {
                        ...state,
                        showQuestionSlide4: "d-none",
                        showQuestionSlide5: "d-block"
                    }
                }
                else if (action.payload.clickedSlide.slide == "question_13") {
                    return {
                        ...state,
                        showQuestionSlide5: "d-none",
                        showQuestionSlide6: "d-block"
                    }
                }
                else if (action.payload.clickedSlide.slide == "question_14") {
                    return {
                        ...state,
                        showQuestionSlide6: "d-none",
                        showQuestionSlide7: "d-block"
                    }
                }
                else if (action.payload.clickedSlide.slide == "question_15") {
                   // alert("email")
                 //   setPrevvalue();
                    return {
                        ...state,
                        showQuestionSlide7: "d-none",
                        showQuestionSlide8: "d-block"
                    }
                }
                else if (action.payload.clickedSlide.slide == "question_16") {
                    return {
                        ...state,
                        showQuestionSlide8: "d-none",
                        showQuestionSlide9: "d-block"
                    }
                } else {
                    return {
                        ...state
                    }
                }
            }
            case "previousQuestionSlide": {
              
                if(question.question_9 == 32 && action.payload.previouSlide.slide == "back14"){
                // setPrevvalue(1);
                setStage(5);
                    return{
                        ...state,
                        showQuestionSlide5:"d-block",
                        showQuestionSlide6:"d-none"
                    }
                }

               else
                if (action.payload.previouSlide.slide == "back17") {
                    return {
                        ...state,
                        showQuestionSlide9: "d-none",
                        showQuestionSlide8: "d-block"
                    }
                }
                else if (action.payload.previouSlide.slide == "back16") {
                    return {
                        ...state,
                        showQuestionSlide8: "d-none",
                        showQuestionSlide7: "d-block"
                    }
                }
                else if (action.payload.previouSlide.slide == "back15") {
                    return {
                        ...state,
                        showQuestionSlide7: "d-none",
                        showQuestionSlide6: "d-block"
                    }
                }
                else if (action.payload.previouSlide.slide == "back14") {
                    return {
                        ...state,
                        showQuestionSlide6: "d-none",
                        showQuestionSlide5: "d-block"
                    }
                }
                else if (action.payload.previouSlide.slide == "back13") {
                    return {
                        ...state,
                        showQuestionSlide5: "d-none",
                        showQuestionSlide4: "d-block"
                    }
                }
                else if (action.payload.previouSlide.slide == "back12") {
                    return {
                        ...state,
                        showQuestionSlide4: "d-none",
                        showQuestionSlide3: "d-block"
                    }
                }
                else if (action.payload.previouSlide.slide == "back11") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide2: "d-block"
                    }
                }
                else if (action.payload.previouSlide.slide == "back10") {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide1: "d-block"
                    }
                }
            }
        }
    }
    const [state, dispatch] = useReducer(QuestionReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState();
    const [previouSlide, setpreviousSlide] = useState();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const splitForm = useRef(null);
    const history = useHistory();
    const { saveDataIngestion } = useDataIngestion();

    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } })
        }
    }, [clickedSlide]);

    useEffect(() => {
        if (previouSlide) {
            dispatch({ type: "previousQuestionSlide", payload: { previouSlide } })
        }
    }, [previouSlide])

    const slideChange = (e, radioName,question_14) => {
        //gtmDataLayer(e.target.name, e.target.value);
        gtmDataLayer(radioName, e.target.value);
   
        let existig_entries = JSON.parse(localStorage.getItem("QuestionData"));
        if (existig_entries ==null)  existig_entries = {};
        let new_Entries = (existig_entries == {}) ? existig_entries : existig_entries;
        Object.assign(new_Entries,{
            [radioName]: e.target.value ? e.target.value : question_14
        }); 
        localStorage.setItem("QuestionData",JSON.stringify(new_Entries));
       
        setStage(stage + 1);
        setClickedSlide({ slide: radioName });

    }

    const gtmDataLayer = (current_question, element = null) => {
        const quest_18 = JSON.parse(localStorage.getItem("QuestionData"));
     
        let question = "";
        let answer = "";
    
        switch (current_question) {
            case "question_9":
                question = "Outstanding Debt";
                break;
            case "question_10":
                question = "No of people you owe";
                break;
            case "question_11":
                question = "Employment Status";
                break;
            case "question_12":
                question = "Monthly Income";
                break;
            case "question_13":
                question = "Residential Status";
                break;
            case "question_14":
                if (quest_18.question_9 == "32") {
                    console.log("enter");
                    question = "Customers with <6k";
                } else {
                    question = "Plans to tackle debt";
                }
                // Remove the break statement here if you want to continue executing code
                // in the "question_14" case after the if-else block.
                break;
            case "question_15":
                question = "Email";
                break;
            case "question_16":
                question = "Phone";
                break;
        }
        console.log(question);
        GTMDataLayer({
            question: question,
            answer: answer,
        });
    };
    
    // // Test with current_question = "question_14" and element = "32"
    // gtmDataLayer("question_14", "32");
    
    const FormSubmit = async () =>{

        const quest_14 = JSON.parse(localStorage.getItem("QuestionData"));

        const values = getValues();

        values['question_14'] = quest_14.question_14;
        const formData = values;
        formData.page_name = "MRD_V3";
        localStorage.setItem('formData',JSON.stringify(formData));
        localStorage.setItem('queryString',JSON.stringify(queryString));
        const formSubmitResult = await saveDataIngestion(
            visitorParameters.visitor_parameters,
			formData,
			'split_form_submit',
			'MRD_V3',
			queryString,
			visitorParameters.data
        )
        if(formSubmitResult.data.status == "Success"){
            if (queryString != null) {
                if (queryString.indexOf("&" + field + "=") !== -1) {
                  if (EnvConstants.AppConversionPixel === "true") {
                    history.push("/fbpixel?split_name=" + formData.page_name +"&next_page=finance-check"); // fb fixel firing
                    window.scrollTo(0, 0);
                  } else {
                    window.scrollTo(0, 0);
                    history.push(
                      "/finance-check"+ "?uuid=" +
                      visitorParameters.visitor_parameters.uuid + "&splitName="+ formData.page_name
                    );
                  }
                } else {
                  window.scrollTo(0, 0);
                  history.push(
                    "/finance-check" + "?uuid=" +
                    visitorParameters.visitor_parameters.uuid + "&splitName="+ formData.page_name
                  );
                }
              }
		}
    }
    const previousSlide = (e,radioName,question_14) => {
        
        let existig_entries = JSON.parse(localStorage.getItem("QuestionData"));
        if (existig_entries ==null)  existig_entries = {};
        let new_Entries = (existig_entries == {}) ? existig_entries : existig_entries;
        Object.assign(new_Entries,{
            [radioName]: e.target.value ? e.target.value : question_14
        }); 
        localStorage.setItem("QuestionData",JSON.stringify(new_Entries));

        let prev_slide = e.target.id;
        setStage(stage - 1);
        setpreviousSlide({ slide: prev_slide });
        
    }
    return (
        <>
            <section className="form-part mt-lg-2 mt-md-4 mt-sm-4 mt-4">
                <div className="container">
                    <ProgressBar stage={stage} count="9" width={progress[stageName]} />
                    <form ref={splitForm} >
                        <QuestionRadioSlide
                            showQuestionSlide={state.showQuestionSlide1}
                            questionnaire={questinnare1}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={false}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                        />

                        <QuestionRadioSlide
                            showQuestionSlide={state.showQuestionSlide2}
                            questionnaire={questinnare2}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={true}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                        />
                        <QuestionRadioSlide
                            showQuestionSlide={state.showQuestionSlide3}
                            questionnaire={questinnare3}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={true}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                        />
                        <QuestionRadioSlide
                            showQuestionSlide={state.showQuestionSlide4}
                            questionnaire={questinnare4}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={true}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                        />
                        <QuestionRadioSlide
                            showQuestionSlide={state.showQuestionSlide5}
                            questionnaire={questinnare5}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={true}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                        />
                        <TextSlide
                            showQuestionSlide={state.showQuestionSlide6}
                            questionnaire={questinnare6}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={true}
                            validation={register}
                            getValues={getValues}
                            validationMsg={errors}
                            trigger={trigger}
                            splitForm = {splitForm}
                        />
                        <QuestionnaireEmail
                            showQuestionSlide={state.showQuestionSlide7}
                            questionnaire={questinnare7}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={true}
                            setError={setError}
                            clearErrors={clearErrors}
                            getValues={getValues}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                            splitForm={splitForm}
                        /> 
                        <QuestionnairePhone
                            showQuestionSlide={state.showQuestionSlide8}
                            questionnaire={questinnare8}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            setError={setError}
                            clearErrors={clearErrors}
                            getValues={getValues}
                            validation={register}
                            trigger={trigger}
                            validationMsg={errors}
                        />
                        <PersonalDetails
                            showQuestionSlide={state.showQuestionSlide9}
                            questionnaire={questinnare9}
                            previousSlide={previousSlide}
                            slideChange={slideChange}
                            backBtn={true}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                            getValues={getValues}
                            setError={setError}
                            clearErrors={clearErrors}
                            splitForm={splitForm}
                            FormSubmit={FormSubmit}
                            handleSubmit={handleSubmit}
                        />

                  

                        </form>
                    <div className="col-lg-12 text-center py-5 secure-img">
                        <img src={secure} alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}
export default FormSplitMRD_V3;