import React, { useState } from "react";
import downArrow from "../../../../assets/img/down-arrow-circle.png";
import ContactDetails from "../../../Includes/Layouts/MRD_V2/ContactDetails";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import GTMDataLayer from "../Common/GTMDataLayer";

const QuestionPersonalSlide = (props) => {

  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  
  const validateSlide = async (e) => {
    
    e.preventDefault();
    const values = props.getValues();
    var emailVal = await props.trigger("txtEmail");
    var phoneVal;
    if(emailVal){
      phoneVal = await props.trigger("txtPhone");
    }else{
      document.getElementById("txtEmail").focus();
      return false;
    }
    
    
    if(emailVal && phoneVal){
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        props.formSubmit();
      }
    }

    GTMDataLayer({
      question: "Email,Phone",
    });
  }
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      if (getPhoneValidation.data.status_code === 0) {
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
  
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email)
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    }
  };
  return (
    <>
      <div id='slide10' className={`slider10 ${props.showQuestionSlide}`}>
        <div className="forms shadow mt-3">
          <div className="down-arrow shake-vertical">
            <img src={downArrow} alt="" />
          </div>
          <div className="col-lg-12">
            <div className="col-lg-12 row custom m-0 forminnercontent text-center">

              <h1 className="title">What's the best way to communicate?</h1>

              <div class="col-lg-12 row custom m-0 forminnercontent text-center">

                <div class="mt-3">
                  <div class="form-group mb-1">
                    <ContactDetails
                      validation={props.validation}
                      validationMsg={props.validationMsg}
                      trigger={props.trigger}
                      clearErrors={props.clearErrors}
                      setError={props.setError}
                      getValues={props.getValues}
                      formSubmit={props.formSubmit}
                      phoneValidCheck={phoneValidCheck}

                    />
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          {props.backBtn &&
            <div class="btn-more back-btn  mt-4 " id="back10" onClick={props.previousSlide}>
              <span> Back </span>
            </div>}
          <div class="btn-more next-btn mt-4 " id="next010" onClick={validateSlide}>
            <span> Next</span>
          </div>
        </div>
      </div>
    </>
  )
};

export default QuestionPersonalSlide;
