import React from "react";
import logo from "../../../../assets/img/Revolution-Money-Logo.png";
import logoLarge from "../../../../assets/img/feefo_logo_large.webp";
const V3Header = () =>{
    return(
        <>
					<header>
						<div className="container">
							<div className="row">
								<div className="col-lg-6 logo">
								<a  data-id="SITE_lOGO">
										<img title="" alt="Logo Image" src={logo} />
								</a>
								</div>
								<div className="col-lg-6 text-lg-end text-center d-lg-block d-md-block d-sm-block d-none"> <a href="https://www.feefo.com/en-GB/reviews/my-debt-plan-ltd?displayFeedbackType=SERVICE&timeFrame=YEAR" target="_blank"><img alt="Feefo logo" border="0" src="https://api.feefo.com/api/logo?merchantidentifier=revolution-money" title="See what our customers say about us"/></a> </div>
							</div>
						</div>
					</header>
        </>
    )
}
export default V3Header;