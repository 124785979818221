const full_url = window.location.href;
const query_string_arr = full_url.split("?");
var queryString = "";
if (query_string_arr != null) {
  if(query_string_arr[1] === undefined || query_string_arr[1] === "" || query_string_arr[1] === null){
    queryString = "";
  }else{
    queryString = query_string_arr[1];
  }  
}
const urlParams = new URLSearchParams(window.location.search);
const source = "live";
const query_status = urlParams.get("status");
const status = (query_status) ? query_status : "Failed";
const task_id = (urlParams.get("task_id"))?urlParams.get("task_id"):"";
const is_check = (urlParams.get("is_check"))?urlParams.get("is_check"):'';


export { queryString, urlParams, source, status, task_id, is_check };
