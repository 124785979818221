import React from "react";
import Email from "../../../UI/Email";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";

const QuestionnaireEmail = (props) => {
  const { validation, validationMsg, getValues } = props;

  const question_id = props.questionnaire.questionId;
  const radioName = "question_" + question_id;
  const { emailValidation } = useEmailValidation();
  const validateEmail = async (e) => {
    const values = getValues();
    const email = getValues("txtEmail");
    if(email == ""){
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Your Email",
      });
    }
    if (email != "") {
      const emailValid = await emailValidation(email);
      if (emailValid.data.status_code === 1) {
        props.clearErrors();
        let txtEmail = {};
        localStorage.setItem("userData", JSON.stringify({ txtEmail: email }));
        props.slideChange(e, radioName);
      } else {
        props.setError("txtEmail", {
          type: "manual",
          message: "invalid Email",
        });
      }
    }
  };

  return (
    <div id="slide7" className={`slider10 fade-in ${props.showQuestionSlide}`}>
      <p className="text-center counter-text mb-5"></p>
      <div className="forms shadow mt-3">
        <div className="down-arrow shake-vertical">
          <img src="dist/img/down-arrow-circle.png" alt="" />
        </div>
        <div className="col-lg-12 row custom m-0 forminnercontent text-center">
          <h1 className="title">{props.questionnaire.question}</h1>
          <p> {props.questionnaire.questionSubText}</p>
          <div className="mt-3">
            <div className="form-group mb-1">
              <Email
                type="email"
                className="form-control secured-field"
                name="txtEmail"
                id="txtEmail"
               
                placeholder="Type your email here...."
                validation={props.validation({
                  required: "please enter your Email",
                  pattern: {
                    value: 3,
                    message: "please enter valid Email",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email Address",
                  },
                })}
              />

              {validationMsg.txtEmail && (
                <div className="w-100 bg-danger text-white">
                  <span>{validationMsg.txtEmail.message}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Back */}
      <div className="container">
        <div className="row">
        <div
          className="col-lg-6 col-sm-6 col-12 order-sm-first order-last btn-more back-btn mt-4"
          id={`back${question_id}`}
          name={`back${question_id}`}
          onClick={(e) => {
            props.previousSlide(e);
          }}
        >
          <span> Back </span>
        </div>
        <div
          className="col-lg-6 col-sm-6 col-12 btn-more next-btn mt-lg-4 mt-md-4 mt-sm-4 mt-4 next006"
          id={`next${question_id}`}
          name={`next${question_id}`}
          onClick={(e) => validateEmail(e)}
        >
          <span> Next</span>
        </div>
      </div>
      </div>
    </div>
  );
};
export default QuestionnaireEmail;
