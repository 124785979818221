import React, { useState } from "react";
import { questinnare6 } from "../../../../Constants/Questions_V3";

const TextSlide = (props) => {

    const values = props.getValues();
    var qstn = values.question_10;
    const qs10_value = { "36": "1", "37": "2", "38": "3", "39": "4", "40": "5", "41": "6+" };
    const qs = qs10_value[qstn];
    const textQuestion9 = props.questionnaire.map((q) => {
        if (values.question_9 == 33) {
            var question9 = "£6,000 - £10,000";
        }
        if (values.question_9 == 34) {
            var question9 = "£10,000 - £25,000";
        }
        if (values.question_9 == 35) {
            var question9 = "£25,000+";
        }
        return question9;
    });
    console.log(props.questionnaire);
    const textQuestion10 = props.questionnaire.map((q) => {
        return qs;
    });
    console.log(textQuestion10);
    const question_id = props.questionnaire[0].questionId;
    const radioName = 'question_' + question_id;
    return (
        <div id="slide6" className={`slider6 fade-in ${props.showQuestionSlide}`}>

            <p className="text-center counter-text mb-5"></p>
            <div className="forms shadow mt-3">
                <div className="down-arrow shake-vertical">
                    <img src="dist/img/down-arrow-circle.png" alt="" />
                </div>
                <div className="col-lg-12 row custom m-0 forminnercontent text-center">
                    <h1 className="title">
                        {/* {values.question_9 == 32 ? props.questionnaire[0].question1 : <h1 className="title">Based on your debt of {textQuestion9} split between {textQuestion10} creditors - we have identified 3 plans to tackle your debt.</h1>} */}
                        
                 {values.question_9 == 32 ? props.questionnaire[0].question1 : <h1 className="title">It looks like you could be eligible for debt help.</h1>}
  
                    </h1>
                    <h5 className="mt-4">

                        {values.question_9 == 32 ? props.questionnaire[0].questionSubText1 : <div><span><u>IMPORTANT</u></span><h5 className="mt-4">We DO NOT offer loans or credit cards - we only assist by providing long-term debt solutions NOT further lending.</h5></div>}
                    </h5>
                </div>
                <div />
            </div>
            {/* Back */}
            {values.question_9 == 32 ?
                (<div className="d-lg-flex d-md-flex d-sm-block d-block  text-lg-start text-md-start text-sm-center text-center justify-content-between">
                    <div
                        className="btn-more back-btn  back-btn2    mt-4  w-100 me-1"
                        id={`back${question_id}`}
                        name={`back${question_id}`}
                        onClick={(e) => props.previousSlide(e, radioName, "55")}
                    >
                        Back
                    </div>
                    <div
                        className="btn-more next-btn mt-lg-4 mt-md-4 mt-sm-4 mt-2 w-100 ms-1 next006 text2btn"
                        value="23"
                        onClick={(e) => props.slideChange(e, radioName, "54")}
                    >
                        <span> Proceed </span>
                    </div>
                </div>) :
                (<div className="container">
                    <div className="row">
                    <div
                        className="col-lg-6 col-sm-6 col-12 order-sm-first order-last btn-more  back-btn  mt-4"
                        id={`back${question_id}`}
                        name={`back${question_id}`}
                        onClick={(e) => props.previousSlide(e, radioName)}
                    >
                        Back
                    </div>
                    <div
                        className="col-lg-6 col-sm-6 col-12 btn-more next-btn mt-lg-4 mt-md-4 mt-sm-4 mt-4 next006"
                        value="23"
                        onClick={(e) => props.slideChange(e, radioName)}
                        name="question_14"
                    >
                        <span> Next</span>
                    </div>
                </div>
                </div>)}
        </div>

    )
}
export default TextSlide;