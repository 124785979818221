import React from "react";
import PrivacyHeader from "../Common/PrivacyHeader";
import TyFooter from "../Common/TyFooter";

const PrivacyV3 = () => {
  window.scrollTo(0, 0);
  return (

    <>
      <div className="privacy">
        <div className="top-main-section">

          <PrivacyHeader />

        </div>
  <section className="pt-5 pb-2">
    <div className="container">
      <h1>PRIVACY POLICY</h1>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="inner_text py-2 py-md-5">
        <h4>Who are we? </h4>
        <p>
          {" "}
          We are My Debt Plan Limited (referred to as “we”, “us” and “our”
          within this policy), a company registered in England and Wales
          registered number 10992838 registered office Blenheim Court, Carrs
          Road, Cheadle, SK8 2JY. Our ICO registration number is ZB284067 and
          can be checked at{" "}
          <a href="https://ico.org.uk/ESDWebPages/Entry/ZB284067  ">
            https://ico.org.uk/ESDWebPages/Entry/ZB284067
          </a>
        </p>
        <p>
          It is important that you read this privacy policy so that you are
          fully aware of how and why we use your personal information. This
          website if not intended for use by anyone under the age of 16 and
          people below this age should not use this website nor enter any
          personal information into it.{" "}
        </p>
        <p>
          If you have any questions about our data protection and privacy
          policies and procedures, we can be contacted by writing to the
          registered office detailed above, by email to info@mydebt-plan.co.uk
          or by telephone on 0161 359 4535.{" "}
        </p>
        <p>
          We reserve the right the change, amend or modify this policy as we see
          fit. Once amended the most current version shall be displayed on our
          website.{" "}
        </p>
        <h4> What personal information will we collect and how? </h4>
        <p>
          {" "}
          We will only collect information from you that we need in order to
          provide the service for which you are applying. This includes
          sufficient information to enable us to perform our legal, regulatory
          or contractual obligations as required to provide that service.
        </p>
        <p>This will likely include the collection of: </p>
        <ul>
          <li>
            Personal information in order to identify you such as name, address,
            date of birth and contact details
          </li>
          <li>
            Financial and employment details, including Credit Reference Agency
            data
          </li>
          <li>
            Sensitive personal information (sometimes known as special category
            data), for example health information. We will only collect this
            data where necessary and with your specific consent{" "}
          </li>
          <li>
            Behavioural or technical information such as details of how you
            browse our website and the types of technology you use to access our
            website. This information is collected using cookies, for more
            details on these see below.{" "}
          </li>
          <p>
            We collect information when you use our website, when you speak to
            us over the phone and when you correspond with us via email or post.
            Occasionally we may also collect information about you from third
            parties or publicly available sources.
          </p>
          <p>
            When you contact us by telephone your call may be recorded for
            training and monitoring purposes.
          </p>
        </ul>
        <h4> How we use your information, the grounds for doing so and why </h4>
        <table className="privacy_modal_table1">
          <tbody>
            <tr>
              <td>How we may use your information</td>
              <td>What lawful basis we have to use the information</td>
              <td> Why we use the information in this way</td>
            </tr>
            <tr>
              <td>
                We may gather your information and process it using approved
                partners and third parties prior to you deciding whether to
                engage our services for the solutions we offer.
              </td>
              <td>
                This is only done with your consent or in order to comply with
                legal obligations associated with the advice being provided.
                Where we process special category data we shall only do this
                with your specific consent.
              </td>
              <td>
                This is done in order to provide you with advice and appropriate
                information about the solutions available to you.
              </td>
            </tr>
            <tr>
              <td>
                We may share information about you with third-party data
                providers in order to obtain more information in return, for
                example by utilising open banking or credit reference agency
                systems.
              </td>
              <td>
                {" "}
                This is only done with your consent or in order to comply with
                legal obligations associated with the service being provided.{" "}
              </td>
              <td>
                {" "}
                We have an interest in making our service as user friendly and
                the least time consuming to you as possible.{" "}
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                If you opt in to receive marketing communications from us we may
                process your information for the purpose of providing such
                marketing communications to you.{" "}
              </td>
              <td>
                {" "}
                We will only process your personal information for the purposes
                of providing marketing communications to you with your consent.
              </td>
              <td>
                All of our marketing communications are carefully drafted to
                always be relevant and useful to the intended audience.
              </td>
            </tr>
            <tr>
              <td>
                If you decide to proceed with a solution we will continue to
                process your data and may share it with approved partners, third
                party service providers and other parties (see below). Some of
                this processing activity may be located outside of the European
                Economic Area (EEA)
              </td>
              <td>
                This would be done with your consent under the terms and
                conditions sent to you for your agreement prior to the
                engagement. Certain data may require to be processed and shared
                in order to comply with legal obligations associated with the
                solution being provided.
              </td>
              <td>
                Without processing your information in this way we would not be
                able to provide our service to you{" "}
              </td>
            </tr>
            <tr>
              <td>
                We may supply your information to a partner that can provide you
                with a solution we do not offer.
              </td>
              <td>This would only be done with your consent.</td>
              <td>
                {" "}
                If you decide to proceed with a solution we cannot offer then by
                providing your data to a partner that can assist with this it
                means that you do not have to repeat the full process of
                providing your information again.
              </td>
            </tr>
            <tr>
              <td>
                We may process and share your personal information with
                regulatory authorities
              </td>
              <td>
                This processing of your personal information is necessary so
                that we can comply with our legal obligations.
              </td>
              <td>
                This is done in order to comply with our legal/regulatory
                obligations, for example to adhere to anti-money laundering
                legislation. We are regulated by the Insolvency Practitioners’
                Association and must also meet the rules and requirements that
                they have in place.
              </td>
            </tr>
          </tbody>
        </table>
        <h4  className="mt-2"> Who do we share your information with? </h4>
        <p>We may share your information with: </p>
        <ul>
          <li>Your creditors and their representatives</li>
          <li> Other companies within our group</li>
          <li>
            Third-party service providers, for example
            <ul>
              <li>IT software service providers</li>
              <li> Payment Service companies</li>
              <li> Communication providers</li>
              <li> Printers</li>
              <li> Claims management companies/Solicitors</li>
              <li>Back office support providers</li>
            </ul>
          </li>
          <li>
            {" "}
            Our regulators/professional bodies, including the Insolvency Service
            and the Insolvency Practitioners’ Association
          </li>
          <li> Our bond providers/insurers</li>
          <li> Law enforcement and/or fraud prevention agencies </li>
          <li>
            Any party we may merge with, sell all or part of our business to or
            a party we may acquire.{" "}
          </li>
          <li> Approved partners that offer alternative solutions</li>
        </ul>
        <p>
          We have strict contractual terms governing how third parties handle
          your data and what it may be used for. It should only be used for
          necessary and legitimate purposes, as set out in the table above. We
          will only provide them with the information they need to perform their
          specific services.
        </p>
        <p>
          All third parties are contractually required to keep your personal
          data secure and confidential and only process it in accordance with
          the legal obligations set out in the UKs General Data Protection
          Regulation and the Data Protection Act 2018. This also applies to any
          third party service providers that may be located outside of the
          European Economic Area.
        </p>
        <p>
          If you choose to pursue an alternative solution with one of our chosen
          partners we recommend you read the terms of that partner’s privacy
          policy as well for full details of how they may handle your
          information.
        </p>
        <h4> What communication you might receive from us:</h4>
        <p>
          {" "}
          You may receive 2 types of communication from us if you have given
          your consent:{" "}
        </p>
        <ul>
          <li>
            Marketing communications offering products or services we feel you
            may be interested in. You can easily let us know if you wish to opt
            out from marketing communications at any time by using the contact
            details above.{" "}
          </li>
          <li>
            Service communications or communications we are obliged by our
            regulator to send you from time to time.{" "}
          </li>
        </ul>
        <h4> How long we hold your information for</h4>
        <p>
          If you choose not to proceed with our service after the initial advice
          has been provided your personal information will normally be deleted
          after 12 months, unless we have another reason to keep your personal
          information (for example you have consented to receive marketing
          information from us).
        </p>
        <p>
          If you enter into a solution with us we your data will be stored for 6
          years following the date on which you last used our services. This is
          required in order to comply with our legal and regulatory obligations.
          After this time your data will be deleted.
        </p>
        <h4>Data security</h4>
        <p>
          {" "}
          It is extremely important to us that your information is secure, as
          such we have in place security measures to protect personal
          information being accidentally deleted, lost, or accessed by any
          unauthorised persons.{" "}
        </p>
        <h4> Your rights</h4>
        <ul>
          <li>
            You have a right of access, meaning you have a right to know whether
            we hold your information, if we are processing that information as
            well as a right to request copies of any information we hold on you
            and details of any third parties we may have shared it with.{" "}
          </li>
          <li>
            You have a right of rectification, meaning if you feel we hold any
            information about you which us incomplete or inaccurate you can
            request that we rectify this.{" "}
          </li>
          <li>
            You have a right of erasure, meaning that you can ask us to erase
            your information. This is also known as the ‘right to be forgotten’.
            Upon receipt of such a request we shall remove any information about
            you save for where this is required to comply with a
            legal/regulatory obligation or to establish/defend a legal claim. In
            which case you have the right to restriction of processing meaning
            you can require us to restrict processing where we shall put your
            information beyond normal use restricting it to the purposes for
            which it was retained.{" "}
          </li>
          <li>
            You have a right to data portability, meaning where we process your
            information with your consent or because it was necessary to provide
            our service to you then you may request us to move, copy or transfer
            such information to you or another third party of your choosing in a
            structured and commonly used machine-readable format.{" "}
          </li>
          <li>
            You have a right to object, meaning you can object to our processing
            of your information where we are processing it on legitimate
            grounds. We will stop processing this information save for where
            this is required to comply with a legal/regulatory obligation or to
            establish/defend a legal claim.{" "}
          </li>
          <li>
            You have a right to withdraw consent, meaning that even where we
            process your information with your consent you can withdraw this
            consent at any time. This may mean we are unable to offer our
            service to you, where this is the case we shall notify you of that.{" "}
          </li>
        </ul>
        <h4> Cookies</h4>
        <p>
          When you use our website we may store some information on your
          computer un the form of a commonly used file called a ‘cookie’. These
          cookies are used to improve the online experience of most websites,
          including our own.
        </p>
        <p>
          Some websites set cookies directly (first-party cookies) and some make
          use of services which set their own cookies (known as third-party
          cookies). We may make use of these types of cookies for purposes
          including personalisation of the website for returning customers.
        </p>
        <p>
          Most internet browsers allow you to erase cookies from your computer,
          block cookies or give you a warning before a cookie is stored on your
          device. For further information consult the user guide for your
          particular browser.
        </p>
        <h4> Complaints</h4>
        <p>
          We request that any complaints are made to us to allow us to
          investigate and respond. Complaints should be sent to{" "}
          <a href="complaints@mydebt-plan.co.uk">
            complaints@mydebt-plan.co.uk
          </a>{" "}
          . Complaints can also be sent direct to the Information Commissioners
          Office who can be contacted here{" "}
          <a href="https://ico.org.uk/make-a-complaint/">
            https://ico.org.uk/make-a-complaint/
          </a>
        </p>
      </div>
    </div>
  </section>


        <TyFooter />
      </div>

    </>
  )

}
export default PrivacyV3;