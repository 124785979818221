import React from 'react'
import logo from "../../../../assets/img/Revolution-Money-Logo.png";

const TyHeader = () => {
  return (
    <>
    {/* ========= Header Area Start ========= */}
    <header>
						<div className="container">
							<div className="row">
								<div className="col-lg-6 logo">
								<a href="" data-id="SITE_lOGO">
										<img title="" alt="Logo Image" src={logo} />
								</a>
								</div>
							</div>
						</div>
					</header>
    {/* ========= Header Area End ========= */}
    </>
  )
}

export default TyHeader