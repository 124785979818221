import React, { useState, useEffect, useReducer } from 'react';
import {queryString} from "../../../Utility/QueryString";
import InputButton from '../../UI/InputButton';
import SignatureModal from '../../Includes/Layouts/Followup/SignatureModal';
import { Api } from "../.././../api/Api";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import QuestionsModal from "../../Includes/Layouts/Followup/QuestionsModal";
import { Link, useHistory } from "react-router-dom";
//import { CheckUUID } from "../../../Utility/CheckUUID";
import yBut from "../../../assets/img/y-but.png";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

var complete_arr = [];
const initialState = {
  showButton1: "hide",
  showButton2: "hide",
  showButton3: "hide",
  showTick1: "hide",
  showTick2: "hide",
  showTick3: "hide"
};
const FormReducer = (state, action) => {

  switch (action.type) {
    case "showButton": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "pending_question") {
        return {
          ...state,
          showButton2: "show",
          showTick2: "hide",
        };
      }
   
      else {
        return {
          ...state,
        };
      }
    }
    case "showTick": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showTick1: "show",
          showButton1: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "pending_question") {
        return {
          ...state,
          showTick2: "show",
          showButton2: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
  }
};
const Followup = (props) => {
  // const { isCheck} = CheckUUID();
  // isCheck();
  const [signatureClass, SetSignatureClass] = useState(false);
  const [pendingStatus, setPendingStatus] = useState([]);
  const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
  const [showLoad, setshowLoad] = useState("show");
  const [showUserName, setUserName] = useState("");
  const [showSpouseName, setSpouseName] = useState("");
  const [partnerSignatureClass, SetPartnerSignatureClass] = useState(false);
  const [allFollowupData, setFollowupData] = useState({});
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
  const [questionnaireClass, SetQuestionnaireClass] = useState(false);
  const [joineeSection, setJoineeSection] = useState('hide');
  const [pending, setPending] = useState(true);
  const [isJoint, setIsJoint] = useState('');
  const [followupStatus, setFollowupStatus] = useState('Pending');
  const [arrowStatus, setArrowStatus] = useState('hide');
  const [arrowStatusJoint, setArrowStatusJoint] = useState('hide');
  const [currentUuid, setCurrentUuid] = useState('');
  const [agreeTerms,setAgreeTerms] = useState(0);
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const userToken = query.get("atp_sub2");
  const atpSub6 = (query.get("atp_sub6"))?query.get("atp_sub6"):'FLP';
  localStorage.setItem('user_token', userToken);
  localStorage.setItem('atp_sub6', atpSub6);
  const  {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('followup','followup');
  useEffect(() => {
    history.push('/404');
  }, [history]);
  const setLoader = (e) => {
    setshowLoad("show");
  };
  const hideLoader = (e) => {
    setshowLoad("hide");
  };
  const showSplash = (uuid) => {

      history.push('/' + DynamicRouteNextPage + '?uuid='+uuid + '&user_token=' + userToken + '&source=' + atpSub6);
  
  };
  const showThankYou = (uuid) => {
    history.push('/' + DynamicRouteNextPage + '?uuid='+uuid + '&user_token=' + userToken + '&source=' + atpSub6);
  };
  useEffect(() => {
    (async () => {
      const response = await Api.get("/api/v1/followup/get-pending-details?query_string=" + queryString, {}
      );
      setAgreeTerms(response.data.response.pending_details.agree_terms);
      const followupDetails = response.data.response.followup_data;
      if(userToken == null){
        localStorage.setItem('user_token', followupDetails.atp_sub2);
      }
      const pendingQuestions = response.data.response.pending_details.pending_question;
      const filledQuestions = response.data.response.pending_details.filled_question;
      setUserName(response.data.response.user_info.user_name
      );
      setSpouseName(response.data.response.user_info.spouse_name
      );
      setFollowupData({
        flpData: response.data.response
      });
      setPendingQuestionnaire({ pendingQuestions });
      setIsJoint(response.data.response.pending_details.is_joint);
      localStorage.setItem('user_info', JSON.stringify(response.data.response.user_info));
      localStorage.setItem('account_type', response.data.response.pending_details.is_joint);
      setCurrentUuid(response.data.response.user_info.uuid);
   
      if (response.data.status === "Success") {
        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          "Split_1",
          queryString,
          "v2"
        )
        setTimeout(() => { hideLoader() }, 2000);
        if(response.data.response.user_info.is_buyer_post === 1 && response.data.response.user_info.buyer_id != null )
        {
          history.push('/' + DynamicRouteNextPage + '?uuid=' + response.data.response.user_info.uuid + '&lid='+response.data.response.user_info.buyer_id);
          return false;
        }

        if (response.data.response.pending_details.is_user_sign === 0) {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showButton' });
        }
        else {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showTick' });
        }

       
        if (response.data.response.pending_details.pending_question.length == 0) {
          setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' });
        }
        else {
          setPendingStatus({ status_btn: 'pending_question', status_flag: 'showButton' });
        }
      }
    })();
  }, []);
  useEffect(() => {
    if (pendingStatus) {

      dispatch({ type: pendingStatus.status_flag, payload: { pendingStatus } });
      if (pendingStatus.status_flag == "showTick") {
        complete_arr.push(pendingStatus.status_btn);
     
          if (complete_arr.indexOf("pending_question") > -1 &&
            complete_arr.indexOf("signature") > -1) {
            setPending(false);
            setFollowupStatus('Review Stage');
            if (joineeSection == 'hide') {
              setArrowStatus('show');
            } else {
              setArrowStatusJoint('show');
            }
          }
    
        }
  
    }
  }, [pendingStatus]);
  return (
<div className='Followup'>
    <div className="follow-up">
   
   <section className="followup-inner">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-sm-12 l_bg">
                
                <form>
                
                  <div id="myDiv2" className="slide2" style={{ display: "block"  }} >
                    <div className="blue-bg col-12">
                      <h4 style={{ color: '#fff' }}>Claim Status: {followupStatus}</h4>
                      <p>Almost done! We just need you to review your claim documents and we can begin the process.</p>
                    </div>
                    <div className="col-12 data-div mb20 animated fadeInUp">
                      <div className=" col-12 nopad">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th className="align-middle" width="60%">Account Holder  </th>
                              <th width="40%">{showUserName ? showUserName : null}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Digital Signature</td>
                          
                              <td className={state ? `text-center align-middle blink_me  ${state.showButton1}` : `hide`}>
                                <InputButton
                                  id=""
                                  value="Sign here"
                                  btnType="button"
                                  onClick={() => SetSignatureClass(true)}
                                  className="add-btn signbut"
                                />
                              </td>
                              <td className={state ? `text-center align-middle signyes ${state.showTick1}` : `hide`}>
                                <img
                                  src={yBut}
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr style={{ borderBottom: 'solid 4px #2f3996' }}>
                              <td>Questionnaire</td>
                            
                              <td className={state ? `text-center align-middle blink_me ${state.showButton2}` : `hide`}>
                                <InputButton
                                  id=""
                                  value="Add now"
                                  btnType="button"
                                  onClick={() => SetQuestionnaireClass(true)}
                                  className="add-btn qbut"
                                />
                              </td>
                              <td className={state ? `text-center align-middle signyes ${state.showTick2}` : `hide`}>
                                <img
                                  src={yBut}
                                  alt=""
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>


                    <div id="mybut">
                      <InputButton
                        id=""
                        value="SUBMIT >"
                        btnType="button"
                        onClick={() => showThankYou(currentUuid)}
                        className={`btn-submit button1 pop_but ${pending == true ? '' : 'animated-effect'}`}
                       disabled={pending}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
  
      <SignatureModal
        popDisplay={signatureClass}
        allFollowupData={allFollowupData}
        setPendingStatus={setPendingStatus}
        setLoader={setLoader}
        hideLoader={hideLoader}
        showUserName={showUserName}
      />

      <QuestionsModal
        questionsPop={questionnaireClass}
        allFollowupData={allFollowupData}
        setPendingStatus={setPendingStatus}
        pendingQuestionnaire={pendingQuestionnaire}
        setLoader={setLoader}
        hideLoader={hideLoader}
      />
     
    </div>
    </div>
  )
}

export default Followup;