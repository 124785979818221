import React, { useContext, useEffect, useState } from 'react'
import successtick from "../../assets/img/success-tick.gif";
import thumpsup from "../../assets/img/thumpsup.png";
import Loader from '../Includes/Layouts/Common/Loader';
import TyHeader from '../Includes/Layouts/Common/TyHeader';
import TyFooter from '../Includes/Layouts/Common/TyFooter';
import { queryString } from '../../Utility/QueryString';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import Button from '../UI/Button';
import { useDataIngestion } from '../../Hooks/useDataIngestion';
import GTMDataLayer from '../Includes/Layouts/Common/GTMDataLayer';

const Thankyou = () => {
  const [active1, setActive1] = useState('');
  const [active2, setActive2] = useState('');
  const [active3, setActive3] = useState('');
  const [active4, setActive4] = useState('');
  const [show, setShow] = useState('d-block');
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion } = useDataIngestion();

  useEffect(() => {
    (async () => {
        localStorage.clear();
      }
    )();
    setTimeout(() => {
      setShow('d-none');
    }, 1500);
  }, []);

  const btnVal_save = async (e) => {
    let btnValue = [e.target.value];
    setActive1(e.target.value === 'anytime' ? 'active' : '');
    setActive2(e.target.value === 'morning' ? 'active' : '');
    setActive3(e.target.value === 'afternoon' ? 'active' : '');
    setActive4(e.target.value === 'evening' ? 'active' : '');

    const formSubmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      btnValue,
      'contact_timings_details',
      '',
      queryString,
      visitorParameters.data
    );


    GTMDataLayer({
      question: "Time to Call",
      answer:""
    });

  }

  return (
    <>
      <GetVisitorsParams />
      <div className="Thankyou">
        <div className="top-main-section pb-5">
          <TyHeader />
          <div className="container">
            <Loader
              show={show}
              button="d-none"
            />
            <div className="form-part more-sec ">
              <div className="forms p-lg-5 p-md-5 p-sm-3 p-3  fade-in ">
                <div className="row">
                  <div className=" inner-sec w-100  text-center">
                    <img
                      width={80}
                      className="img-tick mb-3"
                      src={successtick}
                      alt=""
                    />
                    <h1 className="mb-3 mt-2 text-center fade-in">GREAT NEWS!</h1>
                    <p className="mb-0 pb-1 text-center quali-text  mb-3 ">
                      You could potentially qualify to write-off a large % of your
                      debts
                    </p>
                    <h3>Let us know the best time to call you</h3>
                    <ul
                      id="callSchedule"
                      className="scheduled-time mt-lg-5 mt-md-4 mt-sm-3 mt-4  p-0  mb-0"
                    >
                      <li>
                        <Button
                          type="button"
                          id="item1"
                          name="item1"
                          className={`item ${active1}`}
                          onClick={btnVal_save}
                          buttonText="Anytime"
                          value="anytime"
                        />
                      </li>
                      <li>
                        <Button
                          type="button"
                          id="item2"
                          name="item2"
                          className={`item ${active2}`}
                          onClick={btnVal_save}
                          buttonText="Morning"
                          value="morning"
                        />
                      </li>
                      <li>
                        <Button
                          type="button"
                          id="item3"
                          name="item3"
                          className={`item ${active3}`}
                          onClick={btnVal_save}
                          buttonText="Afternoon"
                          value="afternoon"
                        />
                      </li>
                      <li>
                        <Button
                          type="button"
                          id="item4"
                          name="item4"
                          className={`item ${active4}`}
                          onClick={btnVal_save}
                          buttonText="Evening"
                          value="evening"
                        />
                      </li>
                    </ul>
                    <div className="row">
                      <div className="col-lg-10 offset-lg-1 sub-text-panel">
                        <h2 className="title-sub my-lg-5 my-md-4 my-sm-3 my-4 ">
                          What happens next?
                        </h2>
                        <p>
                          You will receive a phone call shortly from one our trusted
                          partners.
                        </p>
                        <p>
                          If you do not have time to talk when they call, please still
                          answer the phone call and let us know when would be better
                          to call you back so we can book an appointment.
                        </p>
                        <h2 className="title-sub my-lg-5 my-md-4 my-sm-3 my-4 ">
                          Remember
                        </h2>
                        <p>
                          The initial call is just to explore what options are
                          available and work out the best solution to your debt
                          problems.
                        </p>
                        <h2 className="title-sub my-lg-5 my-md-4 my-sm-3 my-4 ">
                          Finally....
                        </h2>
                        <p>
                          It is worth remembering to you that no one close to you will
                          ever know about your debt solution (unless you tell them).
                        </p>
                        <p>
                          We understand how difficult it is to live with debt that you
                          cannot afford.
                        </p>
                        <h2 className=" my-lg-5 my-md-5 my-sm-3 my-4 py-2 bestofluck ">
                          {" "}
                          <img height={50} src={thumpsup} alt="" /> Best
                          of luck!
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <TyFooter />
      </div>
    </>
  )
}

export default Thankyou