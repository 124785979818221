import React from "react";
import RevolutionLogo from "../../../../assets/img/Revolution-Money-Logo.png"
const PrivacyHeader=()=>{

    return(

        <>
          <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 logo">
              <a  data-id="SITE_lOGO">
                <img alt="Logo Image" src={RevolutionLogo}/> 
              </a>
            </div>
           
          </div>
        </div>
      </header>
        </>
    )
}
export default PrivacyHeader;