import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Includes/Layouts/Common/HomePage";
import FbPixel from "./Components/Includes/Layouts/Common/FbPixel"
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Followup from "./Components/Pages/Splits/Followup";
import NotFound from "./Components/Includes/Layouts/Common/NotFound"; 
import MRD_V1 from "./Components/Pages/Splits/MRD_V1";
import MRD_V2 from "./Components/Pages/Splits/MRD_V2";
import MRD_V3 from "./Components/Pages/Splits/MRD_V3";
import Proceed from "./Components/Pages/Proceed";
import Privacy from "./Components/Includes/Layouts/Widgets/Privacy";
import Complaints_policy from"./Components/Includes/Layouts/Widgets/Complaints_policy";
import Thankyou from "./Components/Pages/Thankyou";
import FinanceCheck from "./Components/Pages/FinanceCheck";
import BudsRedirectPage from "./Components/Pages/BudsRedirectPage";
import TermsAndConditions from "./Components/Includes/Layouts/Widgets/TermsAndConditions";
import CookiePolicy from "./Components/Includes/Layouts/Widgets/CookiePolicy";
import Complaints_policyV3 from "./Components/Includes/Layouts/Widgets/Complaints_policyV3";
import PrivacyV3 from "./Components/Includes/Layouts/Widgets/PrivacyV3";
import FinanceCheckFollowup from "./Components/Pages/Followup/FinanceCheckFollowup";
import FollowupBudsRedirectPage from "./Components/Pages/Followup/FollowupBudsRedirectPage";
import FollowupThankyou from "./Components/Pages/Followup/FollowupThankyou";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/MRD_V1"]} component={MRD_V1} />
              <Route exact path={["/MRD_V2"]} component={MRD_V2} />
              <Route exact path={["/MRD_V3"]} component={MRD_V3} />
              <Route exact path={"/followup"} component={Followup} />
              <Route exact path={"/followup/finance-check"} component={FinanceCheckFollowup} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/privacy"]} component={Privacy}/>
              <Route exact path={["/privacyPolicy"]} component={PrivacyV3}/>
              <Route exact path={["/complaints_policy"]} component={Complaints_policy}/>
              <Route exact path={["/complaintsPolicy"]} component={Complaints_policyV3}/>
              <Route exact path={["/TermsAndConditions"]} component={TermsAndConditions}/>
              <Route exact path={["/CookiePolicy"]} component={CookiePolicy}/>
              <Route exact path={["/thankyou"]} component={Thankyou}/>
              <Route exact path={["/finance-check"]} component={FinanceCheck}/>
              <Route exact path={['/buds-redirect-page']} component={BudsRedirectPage} />
              <Route exact path={['/followup-buds-redirect-page']} component={FollowupBudsRedirectPage} />
              <Route exact path={['/followup-thankyou']} component={FollowupThankyou} />

            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
